import React, { useEffect, useState, forwardRef } from 'react'
import { Checkbox, Row, Col } from 'antd'

// Services
import { AmenitiesAPI } from '../../Services/amenities'

const Amenities = ({ ...props }, ref) => {
  const { onChange, value } = props
  const [dataSource, setDataSource] = useState([])

  const setValues = (values) => {
    if (onChange) {
      onChange(values)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      let data = await AmenitiesAPI.getAmenities()
      setDataSource(data)
    }
    fetchData()
  }, [])
  return (
    <Checkbox.Group ref={ref} onChange={setValues} value={value}>
      <Row gutter={[48, 0]}>
        {dataSource.map((item, index) => (
          <Col
            xs={{
              span: 12,
            }}
            sm={{
              span: 8,
            }}
            md={{
              span: 4,
            }}
            style={{
              marginBottom: '1em',
            }}
            key={`amenity_${item.id}`}
          >
            <Checkbox value={item.id}>{item.name}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  )
}

export default forwardRef(Amenities)
