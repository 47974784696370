import React, { useEffect, useState } from 'react'
import { Progress } from 'antd'

export default function Pbar({ config }) {
  const { step, total } = config
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    const p = (step * 100) / total
    setPercentage(p)
  }, [step, total])

  return (
    <div>
      <Progress percent={percentage} showInfo={false} />
      <p className="text-blue-800">
        Paso {step} de {total}
      </p>
    </div>
  )
}
