import create from 'zustand'
import { persist } from 'zustand/middleware'

const usePermissions = create(
  persist(
    (set, get) => ({
      permissions: undefined,
      setPermissions: (permissions) => set({ permissions }),
    }),
    {
      name: 'AccountPermissions', // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
)

export default usePermissions
