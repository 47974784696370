import { useEffect, useState } from 'react'
import useUser from '../Hooks/useUser'

export default function useContract() {
  const [expired, setExpired] = useState(false)
  const [contract, setContrat] = useState(true)
  const [differenceDays, setDifferenceDays] = useState(null)
  const [error, setError] = useState(false)
  const client = useUser((state) => state.user)

  useEffect(() => {
    if (!client) return

    if (client) {
      const contractEnd = client?.contract_dates?.end_date
      if (contractEnd) {
        setContrat(client?.contract_dates)
        const expiringDate = new Date(client.contract_dates.end_date)
        const today = new Date()
        if (today > expiringDate) {
          setExpired(true)
        } else {
          setExpired(false)
          var difference = expiringDate.getTime() - today.getTime()
          setDifferenceDays(Math.ceil(difference / (1000 * 3600 * 24)))
        }
      } else {
        setExpired(true)
      }
    } else {
      setError(new Error('not client defined'))
    }
  }, [error, client])

  return { contract, expired, differenceDays, error }
}
