import axios from 'axios'
import { normalizePropertyDataResponse } from './publicApiResponseNormalizer'
import getDocDefinition from './pdfDefinition'
import pdfMake from 'pdfmake/build/pdfmake'
import { AccountAPI } from '../Services/account'
import { PropertyAPI } from '../Services/property'

const { api: API_URL } = process.env.ENV

pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
}
const downloadPropertyPdf = (propertyId) => {
  return new Promise((resolve, reject) => {
    const propertyRequest = axios.get(`${API_URL}public/property/${propertyId}`)
    const userDataRequest = AccountAPI.getAccount()
    Promise.all([propertyRequest, userDataRequest])
      .then(([propertyResponse, userData]) => {
        const normalizedPropertyData = normalizePropertyDataResponse(
          propertyResponse.data
        )
        return { normalizedPropertyData, userData }
      })
      .then(({ normalizedPropertyData, userData }) =>
        getDocDefinition(normalizedPropertyData, userData[0])
      )
      .then((doc) => pdfMake.createPdf(doc).download(`${propertyId}.pdf`))
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

export default downloadPropertyPdf
