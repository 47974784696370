import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { RadioGroup } from '@headlessui/react'
import getAcquisitionToken from '../../Utils/getAcquisitionToken'
import acquisitionClient from '../../Services/acquisitionApiClient'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const options = [
  { title: '1-5', value: '1-5' },
  { title: '6-15', value: '6-15' },
  { title: '16-50', value: '16-50' },
  { title: '51-100', value: '51-100' },
  { title: 'Más de 100', value: 'Más de 100' },
  { title: 'Más de 1000', value: 'Más de 1000' },
]

const SelectPlan = () => {
  const history = useHistory()
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      const token = getAcquisitionToken()
      await acquisitionClient.patch(`/customers/users/${token.uuid}/`, values)
      setSubmitting(false)
      history.push('/unirse/seleccionar-plan')
    } catch (err) {
      setSubmitting(false)
      console.log(err)
      Sentry.captureException(err)
    }
  }

  return (
    <Formik
      initialValues={{
        property_totals: '',
      }}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="w-full relative space-y-3">
          <RadioGroup
            value={values.property_totals}
            onChange={(val) => setFieldValue('property_totals', val)}
          >
            <RadioGroup.Label className="form-title my-10  ">
              ¿Cuántas propiedades planeas publicar en C&T?
            </RadioGroup.Label>
            <div className=" space-y-3 md:space-y-0 md:grid grid-cols-2 gap-2">
              {options.map((option, i) => (
                <RadioGroup.Option
                  key={i}
                  value={option.value}
                  className="md:h-12  relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-center focus:outline-none"
                >
                  {({ checked }) => (
                    <>
                      <div className="flex justify-center items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className="text-base leading-6 font-medium text-gray-900"
                          >
                            {option.title}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          checked ? 'border-yellow-500' : 'border-transparent',
                          'absolute -inset-px rounded-lg border-2 pointer-events-none'
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>

          <div className="mt-3 col-span-2 sticky  bottom-0 w-full  md:absolute md:bottom-20  p-4 bg-white md:block md:p-0">
            <div
              className="hidden mb-10 m-auto  md:flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="grid grid-cols-2">
              <a href="/unirse" className="self-center underline">
                Regresar
              </a>
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Contratar y publicar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SelectPlan
