import React, { useState, useEffect } from 'react'
import axios from 'axios'

const { api: API_URL } = process.env.ENV

function DeactivateCampaignAccount(props) {
  const { params } = props.match
  const { id } = params
  const [submitted, toggleSubmit] = useState(false)

  const submit = async () => {
    try {
      await axios.post(`${API_URL}public/remove_ghost_accounts/`, {
        id,
      })
      toggleSubmit(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    submit()
  }, [])

  return (
    <div>
      {submitted ? (
        <p>
          Tus propiedades no se mostrarán en Casas y Terrenos, ni reibirás
          contactos
        </p>
      ) : (
        <p>Espera...</p>
      )}
    </div>
  )
}

export default DeactivateCampaignAccount
