import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Popover,
  Icon,
  Menu,
  Typography,
  Tooltip,
  message,
} from 'antd'
import moment from 'moment'

// Own components
import ContainerLayout from './../Layouts/ContainerLayout'
import PageHeaderStyled from './../Shared/Common/PageHeader'
import Actions from './../Shared/Common/Actions'
import ModalStyled from './../Shared/Common/Modal'
import ActionsProperties from './Actions/'
import Filters from '../Shared/Filters/'
import FiltersForm from './FiltersForm'
import NoPhoto from '../Shared/Images/no_foto.png'
import { formatNumberPrice } from '../Shared/utils'

// Services
import { PropertyAPI } from './../Services/property'
import { GeneralAPI } from '../Services/general'
import downloadPropertyPdf from '../Utils/downloadPdf'
//Hooks
import { useWindowWidthSize } from '../Shared/customHooks'

const { Paragraph } = Typography

const propertiesStatus = {
  published: 'Publicado',
  pending: 'Pendiente',
  inactive: 'Inactivo',
}

const InventoryCYT = (props) => {
  const [keepValues, setKeepValues] = useState({
    full: true,
    status: 'published',
  })
  const [dataSource, setDataSource] = useState([])
  const [action, setAction] = useState(null)
  const [dataRow, setDataRow] = useState(null)
  const [titleModal, setTitleModal] = useState('')
  const [bodyStyleModal, setBodyStyleModal] = useState({})
  const [visibleModal, setVisibleModal] = useState(false)
  const [enableGhostButton, setEnableGhostButton] = useState(false)
  const [responsiveDesign, setResponsiveDesign] = useState(false)
  const widthDevice = useWindowWidthSize()

  const onErrorImage = (e) => {
    e.target.src =
      'https://cyt-media.s3-us-west-2.amazonaws.com/img_casas/NoFoto.png'
  }

  useEffect(() => {
    if (widthDevice <= 1024) setResponsiveDesign(true)
    else setResponsiveDesign(false)
  }, [widthDevice])

  const columns = [
    {
      width: 2,
      dataIndex: 'image_cover',
      key: 'image_cover',
      render: (text, record) => {
        if (text && record.status !== 'pending') {
          return (
            <Popover
              placement="rightTop"
              arrowPointAtCenter
              content={
                <img
                  className="eye-image-fluid"
                  src={text}
                  alt={record.title}
                  onError={onErrorImage}
                  referrerPolicy="no-referrer-when-downgrade"
                />
              }
            >
              <img
                className="image-table-fluid"
                src={text}
                alt={record.title}
                onError={onErrorImage}
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Popover>
          )
        }
        return (
          <img
            className="image-table-fluid"
            src={NoPhoto}
            alt="No contiene imagenes"
          />
        )
      },
    },
    {
      width: 2,
      key: 'xml_loaded',
      render: (text, record) => {
        if (record.origin !== 'Casas y Terrenos') {
          return (
            <Tooltip
              placement="right"
              title="La propiedad se a cargado mediante XML"
            >
              <Icon type="api" theme="twoTone" twoToneColor="#52c41a" />
            </Tooltip>
          )
        }
        return null
      },
    },
    {
      key: 'featured',
      width: 5,
      render: (text, record) => {
        if (record.is_featured && record.is_my_property) {
          return (
            <Tooltip placement="right" title="Destacado">
              <Icon type="star" theme="twoTone" twoToneColor="#D4AF37" />
            </Tooltip>
          )
        }
        return null
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Inmobiliaria',
      dataIndex: 'name_client',
    },
    {
      title: 'Teléfono',
      dataIndex: 'related_phones',
      render: (record) =>
        record.promotor.whatsapp ||
        record.promotor.mobile ||
        record.promotor.phone_number ||
        record.promotor.extra_phones ||
        record.client.whatsapp ||
        record.client.inmob_old_phones,
    },
    {
      title: 'Tipo de propiedad',
      dataIndex: 'property_type',
      render: (text) => text.name,
    },
    {
      title: 'Dirección',
      render: (text, record) => {
        const { colony, municipality, state } = record
        let renderAddress = []

        if (colony) renderAddress.push(<div key="1">{record.colony.name}</div>)
        if (municipality)
          renderAddress.push(<div key="2">{record.municipality.name}</div>)
        if (state) renderAddress.push(<div key="3">{record.state.name}</div>)
        return renderAddress
      },
    },
    {
      title: 'Promotor',
      dataIndex: 'membership_email',
      render: (text) => {
        if (text)
          return (
            <Paragraph className="mb-0" copyable>
              {text}
            </Paragraph>
          )
      },
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'date_created',
      sorter: (a, b) => (moment(a).isBefore(moment(b)) ? -1 : 1),
    },
    {
      title: 'Precio',
      render: (record) => {
        const { price_rent, price_sale, price_transfer } = record
        let renderPrice = []

        if (price_rent) {
          renderPrice.push(
            <div key="1">Renta: ${formatNumberPrice(price_rent)}</div>
          )
        }
        if (price_sale) {
          renderPrice.push(
            <div key="2">Venta: ${formatNumberPrice(price_sale)}</div>
          )
        }
        if (price_transfer) {
          renderPrice.push(
            <div key="3">Traspaso: ${formatNumberPrice(price_transfer)}</div>
          )
        }
        return renderPrice
      },
    },
    {
      key: 'action',
      width: 20,
      render: (text, record) => {
        return <Actions menu={menuActions(record)} placement="bottomRight" />
      },
    },
  ]

  const history = useHistory()

  const toggleModal = () => setVisibleModal(!visibleModal)

  const fetchData = async (values = { full: true, status: 'published' }) => {
    let response = await PropertyAPI.getProperties(values)
    if (Object.prototype.hasOwnProperty.call(response, 'success')) {
      if (!response.success) message.error(response.message)
    }
    setDataSource(response)
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleActions = async (e) => {
    const { key: action } = e
    const { propertyid } = e.item.props
    let dataProperty
    if (action !== 'statistic')
      dataProperty = await PropertyAPI.getPropertyInventory(propertyid)

    dataProperty.id = propertyid
    setBodyStyleModal({})
    if (action === 'edit') {
      history.push(`/properties/${propertyid}/edit`)
    } else if (action === 'inactive') {
      setTitleModal(`Inactivar propiedad | ID ${propertyid}`)
      setDataRow(dataProperty)
      setAction(action)
      setVisibleModal(true)
    } else if (action === 'details') {
      setBodyStyleModal({ padding: 0 })
      setTitleModal(`Propiedad | ID ${propertyid}`)
      setAction(action)
      setDataRow(dataProperty)
      setVisibleModal(true)
    } else if (action === 'statistic') {
      setTitleModal(`Estadística propiedad | ID ${propertyid}`)
      setDataRow(dataProperty)
      setAction(action)
      setVisibleModal(true)
    } else if (action === 'download') {
      message.loading('Descargando archivo. Puede tardar varios segundos.', 0)
      try {
        const pdfData = await downloadPropertyPdf(propertyid)
        message.destroy()
        message.success('El PDF se descargó éxitosamente.')
      } catch {
        message.destroy()
        message.error(
          'Ocurrió un error descargando el PDF. Intentalo de nuevo más tarde.'
        )
      }
    }
  }

  const menuActions = (record) => {
    const { id: propertyid } = record
    return (
      <Menu onClick={handleActions}>
        <Menu.Item key="details" propertyid={propertyid} data-splitbee-event="inventory-cyt-details-item">
          <Icon type="read" style={{ color: '#AD2AA5' }} /> Más detalles
        </Menu.Item>
        <Menu.Item key="download" propertyid={propertyid} data-splitbee-event="inventory-cyt-download-item">
          <Icon type="file-pdf" style={{ color: '#F00' }} /> Descargar
        </Menu.Item>
      </Menu>
    )
  }

  const handleChangeTable = (pagination) => {
    const page = pagination.current
    const params = Object.assign(keepValues, { page })
    fetchData(params)
  }

  const handleSubmitFilters = (values) => {
    let msg = ''
    if (values) {
      const params = Object.assign(keepValues, values)
      fetchData(params)
      setKeepValues(params)
      msg = 'Se han aplicado los filtros éxitosamente.'
    } else {
      setKeepValues({})
      fetchData()
      msg = 'Los filtros se han limpiado éxitosamente.'
    }
    message.success(msg)
  }

  const handleSubmitOnActions = async (dataForm) => {
    if (action === 'inactive' || action === 'active')
      handleSubmitStatusProperty(dataForm)
  }

  const handleSubmitStatusProperty = async (dataForm) => {
    const { id: propertyID } = dataForm
    let messageTXT
    let response = await PropertyAPI.editProperty(propertyID, dataForm)

    if (Object.prototype.hasOwnProperty.call(response, 'state')) {
      if (action === 'inactive') messageTXT = 'Se ha inactivado la propiedad.'
      if (action === 'active') messageTXT = 'Se ha activado la propiedad.'
    }
    toggleModal()
    message.success(messageTXT)
  }

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <PageHeaderStyled title="Inventario CYT" className="px-0 pt-0" />
      <Row type="flex" className="mb-2">
        <Col xs={24}>
          {responsiveDesign ? (
            <Filters onSubmit={handleSubmitFilters}>
              <FiltersForm hideFields={enableGhostButton} />
            </Filters>
          ) : (
            <FiltersForm
              hideTags
              onSubmit={handleSubmitFilters}
              hideFields={enableGhostButton}
            />
          )}
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24}>
          <Table
            rowKey="id"
            dataSource={dataSource.results}
            columns={columns}
            size="middle"
            onChange={handleChangeTable}
            pagination={{
              defaultCurrent: 1,
              total: dataSource.count,
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <ModalStyled
        visible={visibleModal}
        onCancel={toggleModal}
        title={titleModal}
        centered
        destroyOnClose
        bodyStyle={bodyStyleModal}
        footer={null}
      >
        {action ? (
          <ActionsProperties
            action={action}
            property={dataRow}
            onSubmit={handleSubmitOnActions}
          />
        ) : null}
      </ModalStyled>
    </ContainerLayout>
  )
}

export default InventoryCYT
