import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import acquisitionClient from '../../Services/acquisitionApiClient'
import getAcquisitionToken from '../../Utils/getAcquisitionToken'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const Register = ({ data }) => {
  const history = useHistory()
  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      const token = getAcquisitionToken()
      await acquisitionClient.patch(`/customers/users/${token.uuid}/`, values)
      const { data } = await acquisitionClient.post(
        '/customers/complete-registration/',
        {
          password: values.password,
        }
      )
      localStorage.setItem('cytProperyUrl', data?.slugs?.canonical)
      setSubmitting(false)
      history.push('/unirse/resumen')
    } catch (err) {
      setSubmitting(false)
      Sentry.captureException(err)
    }
  }

  const DataConfirmationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Mínimo 6 caractéres')
      .required('La contraseña es requerida'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Las contraseñas deben coincidir'
    ),
    name: Yup.string().required('Campo requerido'),
    phone: Yup.string()
      .length(10, 'Teléfono a 10 dígitos')
      .required('Campo requerido'),
    email: Yup.string()
      .email('Correo electrónico no válido')
      .required('Campo requerido'),
    client_name: Yup.string(),
  })
  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        password: '',
        passwordConfirmation: '',
        ...data,
      }}
      validationSchema={DataConfirmationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} className="w-full relative space-y-3">
          <p className="form-title mb-8">Completa tu registro</p>
          <div className="md:grid grid-cols-2 gap-6 md:space-y-0 space-y-3">
            <div>
              <label htmlFor="password" className="font-medium">
                Contraseña
              </label>
              <Field
                as="input"
                type="password"
                name="password"
                className="input"
              />
              {errors.password && touched.password && (
                <div className="text-sm text-red-600">{errors.password}</div>
              )}
            </div>
            <div>
              <label htmlFor="passwordConfirmation" className="font-medium">
                Contraseña
              </label>
              <Field
                as="input"
                type="password"
                name="passwordConfirmation"
                className="input"
              />
              {errors.passwordConfirmation && touched.passwordConfirmation && (
                <div className="text-sm text-red-600">
                  {errors.passwordConfirmation}
                </div>
              )}
            </div>
          </div>
          <p className="form-title my-8">
            Confirma la información que ingresaste anteriormente
          </p>
          <div className="md:grid grid-cols-2 md:gap-6 md:space-y-0 space-y-3">
            <div>
              <label htmlFor="name" className=" font-medium">
                Nombre
              </label>
              <Field as="input" type="text" name="name" className="input" />
              {errors.name && touched.name && (
                <div className="text-sm text-red-600">{errors.email}</div>
              )}
            </div>

            <div>
              <label htmlFor="last_name" className=" font-medium">
                Apellido
              </label>
              <Field
                as="input"
                type="text"
                name="last_name"
                className="input"
              />
              {errors.last_name && touched.last_name && (
                <div className="text-sm text-red-600">{errors.last_name}</div>
              )}
            </div>

            <div>
              <label htmlFor="email-address" className=" font-medium">
                Correo electrónico
              </label>
              <Field as="input" type="email" name="email" className="input" />
              {errors.email && touched.email && (
                <div className="text-sm text-red-600">{errors.email}</div>
              )}
            </div>

            <div>
              <label htmlFor="phone_number" className=" font-medium">
                Teléfono
              </label>
              <Field as="input" type="tel" name="phone" className="input" />
              {errors.phone && touched.phone && (
                <div className="text-sm text-red-600">{errors.phone}</div>
              )}
            </div>

            <div>
              <label htmlFor="city" className=" font-medium">
                Ciudad
              </label>
              <Field as="input" name="city" type="text" className="input" />
              {errors.city && touched.city && (
                <div className="text-sm text-red-600">{errors.city}</div>
              )}
            </div>
            <div>
              {values.account_type !== 'direct' && (
                <>
                  <label htmlFor="business_name" className=" font-medium">
                    Nombre de inmobiliaria
                  </label>
                  <input
                    id="client_name"
                    name="client_name"
                    type="text"
                    required
                    onChange={handleChange}
                    value={values.client_name}
                    className="input"
                    placeholder="Inmobiliaria o Nombre comercial"
                  />
                  {errors.client_name && touched.client_name && (
                    <div className="text-sm text-red-600">
                      {errors.client_name}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="mt-3 col-span-2 sticky bottom-0 w-full  md:absolute md:bottom-28  py-4 bg-white md:block md:p-0">
            <div class="hidden mb-10  md:flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className="group relative w-full flex justify-center py-2 md:mx-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-blue-900 group-hover:text-blue-800"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Completar registro
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Register
