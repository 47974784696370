import axios from 'axios'
import { Auth } from 'aws-amplify'

const { api: API_URL } = process.env.ENV

const axiosInstance = axios.create({
  baseURL: API_URL,
})

axiosInstance.CancelToken = axios.CancelToken
axiosInstance.isCancel = axios.isCancel

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const session = await Auth.currentSession()
    config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`
    return config
  } catch (err) {
    return Promise.reject(err)
  }
})

export default axiosInstance
