import React, { useState, forwardRef, useEffect } from 'react'
import {
  Button,
  Divider,
  Icon,
  Row,
  Col,
  Typography,
  Card,
  Upload,
  message,
  Skeleton,
} from 'antd'
import { TrashIcon } from '@heroicons/react/solid'
import axios from 'axios'
import styled from 'styled-components'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

// Services
import { PropertyAPI } from '../../Services/property'

// Utils
import { imageToObject, formatImageList } from '../utils'

const { Dragger } = Upload

const SortableItem = SortableElement(({ value, sortIndex, deleteImage }) => {
  return (
    <li className="relative list-none  cursor-move select-none">
      <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group md:block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
        <img
          src={value.base64 || value.url}
          alt={value.name}
          className="group-hover:opacity-75 object-cover pointer-events-none"
          onMouseDown={(e) => {
            // https://www.redips.net/firefox/disable-image-dragging/
            e.preventDefault()
          }}
        />
      </div>

      <button
        type="button"
        onClick={() => deleteImage(sortIndex)}
        className=" w-full items-center px-4 text-center py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <div className="pointer-events-none">
          <TrashIcon
            className="-ml-1 h-5 w-5 text-gray-400 m-auto"
            aria-hidden="true"
          />
        </div>
      </button>
    </li>
  )
})
const SortableArea = SortableContainer(
  ({ items, openEditor, markFirst, deleteImage }) => {
    return (
      <div className="md:grid md:grid-cols-2 lg:grid-cols-4 gap-3">
        {items.map((item, index) => (
          <SortableItem
            helperclassName={'SortableHelperWithOverride'}
            deleteImage={deleteImage}
            value={item}
            index={index}
            sortIndex={index}
            key={`${index}_${item.name}`}
            data-index={index}
          />
        ))}
      </div>
    )
  }
)

const UploadImgPresigned = ({ ...props }, ref) => {
  const { value, handleChange, propertyID } = props
  const [fileList, setFileList] = useState(value || [])
  const [totalSizeImages, setTotalSizeImages] = useState(0)
  const [defaulDataLoaded, setDefaultDataLoaded] = useState(false)

  const beforeUpload = (file) => {
    const { type: typeFile, size: sizeFile } = file
    const isJpgOrPng =
      typeFile === 'image/jpeg' ||
      typeFile === 'image/png' ||
      typeFile === 'image/jpg'

    if (!isJpgOrPng) {
      message.error('Solo puedes subir imagenes JPG, JPEG o PNG.')
    }

    return isJpgOrPng
  }
  const customRequest = async (event) => {
    const { file, onError, onProgress, onSuccess } = event

    if (propertyID) {
      const data = {
        property: propertyID,
        urls: [
          {
            format: file.format,
          },
        ],
      }

      const { originFile, name } = file
      let formData = new FormData()
      const response = await PropertyAPI.requestPresignedUrl(data)
      const [dataPresigned] = response
      const { url, fields } = dataPresigned
      let urlImage
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key])
        if (key === 'key') urlImage = `${url}${fields[key]}`
      })
      formData.append('file', originFile)

      await axios({
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
        },
        method: 'POST',
        url: `${url}`,
        data: formData,
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onProgress({ percent: percentCompleted })
        },
      }).then(
        async (response) => {
          file.url = urlImage
          const newFileList = fileList
          newFileList.push(file)

          onSuccess(null, file)
          setFileList([...newFileList])
          handleChange([...newFileList])
        },
        (error) => {
          message.error(
            `Oh oh! Algo ha ocurrido con ${name}. Intentalo nuevamente.`
          )
        }
      )
    }
  }

  const deleteImage = async (index) => {
    const image = fileList[index]
    if (image) {
      fileList.splice(index, 1)
      setFileList([...fileList])
    }
  }

  const sortEnd = async ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove(fileList, oldIndex, newIndex)
    setFileList([...newOrder])
    handleChange([...newOrder])
  }

  const downloadImage = async (item) => {
    const timestamp = Date.now()
    const resource = `${item.url}?ts=${timestamp}`
    const fetchFile = await fetch(resource).catch((e) => {
      return false
    })
    if (fetchFile) {
      const fileBlob = await fetchFile.blob()
      const file = new File([fileBlob], '', { type: fileBlob.type })

      const { size: sizeFile } = file
      const transformSizeFile = sizeFile / 1024 / 1024
      setTotalSizeImages(totalSizeImages + transformSizeFile)

      const fileExt = fileBlob.type.split('/')
      const newItem = await formatImageList(item, file, fileExt)
      return newItem
    }
    return false
  }

  const formatImages = async (array) => {
    const promises = []
    for (let i = 0, len = array.length; i < len; i += 1) {
      let image = await downloadImage(array[i])
      if (image) promises.push(image)
    }
    return promises
  }

  useEffect(() => {
    const setImagesData = async () => {
      setDefaultDataLoaded(true)
      const newValue = await formatImages(value)
      setFileList([...newValue])
    }
    if (value && value.length > 0 && !defaulDataLoaded) {
      setImagesData()
    }
  }, [value])

  useEffect(() => {
    if (defaulDataLoaded && handleChange) {
      handleChange(fileList)
    }
  }, [fileList])

  return (
    fileList && (
      <Row type="flex" ref={ref}>
        <Col xs={24}>
          <Dragger
            accept=".jpeg,.jpg,.png"
            name="file"
            multiple={true}
            listType="picture"
            showUploadList={{
              showDownloadIcon: false,
              showRemoveIcon: false,
              showPreviewIcon: true,
            }}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
            transformFile={imageToObject}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="file-image" />
            </p>
            <p className="ant-upload-text mt-4">
              Da click aquí o arrastra las imagenes a esta área para subirlas.
            </p>
          </Dragger>
        </Col>
        {fileList.length > 1 && (
          <Col xs={24}>
            <Divider dashed>
              <p style={{ fontSize: '0.75em' }} className="mb-0">
                Arrastra las imagenes para ordenar
              </p>
            </Divider>
          </Col>
        )}
        {fileList.length > 0 && (
          <Col xs={24} className="mt-4">
            <SortableArea
              markFirst={true}
              axis="xy"
              items={fileList}
              deleteImage={deleteImage}
              onSortEnd={sortEnd}
              pressDelay={10}
            />
          </Col>
        )}
      </Row>
    )
  )
}

export default forwardRef(UploadImgPresigned)
