import React from 'react'
import { Card } from 'antd'
import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'

export default function Lead({ date, selected, name, property, ...rest }) {
  let relativeDate
  try {
    relativeDate = formatRelative(date, new Date(), {
      locale: es,
    })
  } catch {
    relativeDate = ''
  }

  return (
    <div
      {...rest}
      className={`${
        selected && 'md:bg-blue-100'
      } cursor-pointer mb-1 relative rounded-lg border border-gray-300  px-6 py-3 shadow-sm flex items-center space-x-3 hover:bg-blue-50 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`}
    >
      <div className="flex-1 min-w-0  ">
        {/* Extend touch target to entire panel */}
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-sm font-medium text-gray-900">{name}</p>
        <p>{relativeDate}</p>
        <p className="text-sm text-gray-500 truncate">{property}</p>
      </div>
    </div>
  )
}
