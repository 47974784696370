import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Steps, Button, Form, message } from 'antd'

// Own components
import ContainerLayout from '../../Layouts/ContainerLayout'
import PageHeaderStyled from '../../Shared/Common/PageHeader'
import GeneralEditProperty from './GeneralEditProperty'
import LocationCreateForm from '../../Shared/Common/LocationCreateForm'
import MultimediaEditProperty from './MultimediaEditProperty'
import Amenities from '../../Shared/Common/Amenities'

// Services
import { PropertyAPI } from '../../Services/property'

// Utils
import { requiredFields } from '../Utils/'

const { Step } = Steps
const { Item: FormItem } = Form

const PropertiesEdit = (props) => {
  const { params: queryParams } = props.match
  const { getFieldDecorator } = props.form
  const history = useHistory()
  const [loadedData, setLoadedData] = useState(false)
  const [dataSource, setDataSource] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [hiddenForm, setHiddenForm] = useState([false, true, true, true])
  const [loadingProperty, setLoadingProperty] = useState(false)

  const { form } = props
  const { validateFieldsAndScroll } = form

  useEffect(() => {
    const fetchData = async () => {
      const { propertyID } = queryParams
      const response = await PropertyAPI.getProperty(propertyID)
      setDataSource(response)
      setLoadedData(true)
    }
    fetchData()
  }, [])

  const steps = [
    {
      title: 'General',
    },
    {
      title: 'Ubicación',
    },
    {
      title: 'Amenidades',
    },
    {
      title: 'Multimedia',
    },
  ]

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
    hideShowSteps('next')
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
    hideShowSteps('prev')
  }

  const hideShowSteps = (prevOrNext) => {
    const newHiddenForm = hiddenForm.map((item, idx) => {
      let actualStep = prevOrNext === 'prev' ? currentStep - 1 : currentStep + 1
      if (idx === actualStep) return false
      else return true
    })

    setHiddenForm(newHiddenForm)
  }

  const resizeImage = (item) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        let multi
        if (img.naturalWidth > 1920 || img.naturalHeight > 1080) {
          if (img.naturalWidth > img.naturalHeight) {
            multi = 1080 / img.naturalHeight
          } else if (img.naturalHeight > img.naturalWidth) {
            multi = 1920 / img.naturalWidth
          } else {
            multi = 870 / img.naturalWidth
          }
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const tgWidth = img.naturalWidth * multi
          const tgHeight = img.naturalHeight * multi
          canvas.width = tgWidth
          canvas.height = tgHeight
          img.width = tgWidth
          img.height = tgHeight
          img.className = 'd-none'
          ctx.drawImage(img, 0, 0, tgWidth, tgHeight)
          item.base64 = canvas.toDataURL(item.content_type, 1.0)
          document.body.appendChild(img)
        }
        const strImg = item.base64.split(',')
        strImg.shift()
        strImg.join()
        item.base64 = strImg[0]
        delete item.name
        resolve(item)
      }
      img.onerror = () => reject()
      img.src = item.base64
    })
  }

  const formatImages = async (arrayImg) => {
    const promises = []
    const lenArrayImg = arrayImg.length

    for (let i = 0, len = lenArrayImg; i < len; i += 1) {
      promises.push(resizeImage(arrayImg[i]))
    }
    return Promise.all(promises)
  }

  const submitForm = (e) => {
    e.preventDefault()
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        Object.keys(errors).some((field) => {
          let { message: msgError } = errors[field].errors[0]
          if (requiredFields['firstForm'].includes(field)) {
            setHiddenForm([false, true, true, true])
            setCurrentStep(0)
          }
          if (requiredFields['secondForm'].includes(field)) {
            setHiddenForm([true, false, true, true])
            setCurrentStep(1)
          }
          if (requiredFields['thirdForm'].includes(field)) {
            setHiddenForm([true, true, false, true])
            setCurrentStep(2)
          }
          message.error(msgError)
          return true
        })
      }
      if (!errors) {
        const { propertyID } = queryParams
        const [lat, long] = values.latLng
        //values.images = await formatImages(values.images);
        values.latitude = lat.toString()
        values.longitude = long.toString()
        values.state = values.state.key
        values.property_type = values.property_type.key
        values.municipality = values.municipality.key
        values.colony = values.colony.key
        values.images = values['images'].map((item) => {
          return { url: item.url }
        })
        setLoadingProperty(true)
        let response = await PropertyAPI.editProperty(propertyID, values)
        message.success('Se ha modificado la propiedad correctamente.')
        setTimeout(() => {
          history.push('/properties')
          setLoadingProperty(false)
        }, 3000)
      }
    })
  }

  const handleLoading = (isLoading) => {
    setLoadingProperty(isLoading)
  }

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <PageHeaderStyled
        title="Propiedades"
        subTitle="Editar propiedad"
        className="px-0 pt-0"
      />

      {loadedData ? (
        <Fragment>
          <Row type="flex" gutter={[16, 48]} className="mb-3">
            <Col xs={24}>
              <Steps current={currentStep}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
          </Row>
          <Form onSubmit={submitForm} className="login-form" colon={false}>
            <GeneralEditProperty
              initialValues={dataSource}
              form={form}
              hidden={hiddenForm[0]}
            />
            <LocationCreateForm
              initialValues={dataSource}
              form={form}
              hidden={hiddenForm[1]}
            />
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              gutter={[48, 8]}
              className={hiddenForm[2] ? 'hidden' : 'flex'}
            >
              <Col xs={24}>
                <FormItem label="Selecciona las amenidades">
                  {getFieldDecorator('amenities', {
                    initialValue: dataSource.amenities || [],
                  })(<Amenities />)}
                </FormItem>
              </Col>
            </Row>
            <MultimediaEditProperty
              propertyID={queryParams.propertyID}
              initialValues={dataSource}
              isLoading={handleLoading}
              form={form}
              hidden={hiddenForm[3]}
            />

            <Row type="flex" justify="end" gutter={48}>
              <Col>
                {currentStep > 0 && (
                  <Button className="mr-2" onClick={prevStep}>
                    Anterior
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button type="primary" onClick={nextStep}>
                    Siguiente
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingProperty}
                    disabled={loadingProperty}
                  >
                    Guardar
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Fragment>
      ) : null}
    </ContainerLayout>
  )
}

const PropertiesEditWrapper = Form.create()(PropertiesEdit)
export default PropertiesEditWrapper
