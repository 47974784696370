import React, { useState } from 'react'
import { message } from 'antd'
import { Auth } from 'aws-amplify'
import log from '../Utils/logger'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import LogoCYT from '../Shared/Images/logo-blue.svg'

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Ingresa un correo electrónico válido')
    .required('Este campo es requerido'),
  password: Yup.string().required('Este campo es requerido'),
})

export default function Login() {
  const [showPass, toggleShowPass] = useState(false)
  const submit = async (values) => {
    try {
      const { username, password } = values
      await Auth.signIn(username, password)
    } catch (err) {
      log.error(err)
      const { message: response } = err
      message.error(response)
    }
  }

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img className=" h-12 w-auto" src={LogoCYT} alt="Workflow" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Ingreso a usuarios
              </h2>
              <p className="mt-2  text-sm text-gray-600">
                O{' '}
                <a
                  href="/unirse"
                  className=" text-md font-medium text-blue-900 hover:text-blue-800"
                >
                  crea una cuenta
                </a>
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <Formik
                  initialValues={{ username: '', password: '' }}
                  validationSchema={LoginSchema}
                  onSubmit={submit}
                >
                  <Form className="space-y-3">
                    <div>
                      <label className="form-label" htmlFor="username">
                        Correo electrónico
                      </label>
                      <Field
                        type="email"
                        name="username"
                        className="input"
                        placeholder="correo electrónico"
                      />
                      <ErrorMessage
                        component="span"
                        className="input-error"
                        name="username"
                      />
                    </div>
                    <div>
                      <label className="form-label" htmlFor="password">
                        Contraseña
                      </label>
                      <div className="full flex">
                        <Field
                          type={showPass ? 'text' : 'password'}
                          name="password"
                          className="border shadow-sm focus:ring-blue-800 focus:border-blue-800 block w-full sm:text-sm  border-gray-300 rounded-l"
                          placeholder="contraseña"
                        />
                        <div
                          onClick={() => toggleShowPass(!showPass)}
                          className="cursor-pointer text-center hover:bg-gray-50 flex items-center  w-10 rounded-r border border-gray-300"
                        >
                          {!showPass ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-center items-center m-auto"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6  m-auto"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      <ErrorMessage
                        component="span"
                        className="input-error"
                        name="password"
                      />
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="text-sm">
                        <a
                          href="/reset-password"
                          className="font-medium text-blue-700 hover:text-blue-700"
                        >
                          ¿Olvidaste tu contraseña?
                        </a>
                      </div>
                    </div>

                    <div>
                      <button type="submit" className="button-blue">
                        Iniciar sesión
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
