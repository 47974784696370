import React, { Fragment, useState, useEffect } from 'react'
import { Form, Row, Col, Button, Avatar, Icon, message, Modal } from 'antd'
import { Link } from 'react-router-dom'

//Own components
import TypographyAvatar from '../Shared/Common/TypographyAvatar'
import PictureAvatar from '../Shared/Common/PictureAvatar'

// Own components
import PersonalInformation from './PersonalInformation'
// Utils
import { executivesRoles } from '../Shared/utils'
import AccountPermissions from '../Services/auth'
import useAccount from '../Hooks/useAccount'

// Services
import { AccountAPI } from '../Services/account'
import apiClient from '../Services/apiClient'

const { Item: FormItem } = Form

const AccountMain = (props) => {
  const [dataSource, setDataSource] = useState(null)
  const [visibleModal, setVisibleModal] = useState(false)
  const [permalink, setPermalink] = useState(null)
  const [updBtnRealEstate, setUpdBtnRealEstate] = useState([])
  const [accountType, accountRole] = useAccount()

  const canUpdate = AccountPermissions.accessGranted('clients', 'update')
  let modDate = new Date().getTime()

  const fetchData = async () => {
    let data = await AccountAPI.getAccount()
    setDataSource(data[0])
  }

  const getPagePermalink = async () => {
    const p = await apiClient.get('clients/permalink')
    setPermalink(p.data.permalink)
  }

  const onPermalinkChange = (value) => {
    const fortmatted = value.replace(' ', '-').toLowerCase()
    setPermalink(fortmatted)
  }

  const updatePermalink = async (e) => {
    try {
      if (permalink) {
        await apiClient.patch('clients/permalink', {
          permalink,
        })
        message.success('Url actualizada con éxito')
        fetchData()
      } else {
        getPagePermalink()
      }
    } catch (err) {
      message.error('Hubo un error al actualizar la url')
    }
  }

  useEffect(() => {
    fetchData()
    getPagePermalink()
    modDate = new Date().getTime()
  }, [])

  const handleSubmitUpdateProfile = async () => {
    toggleModal()
    await fetchData()
    modDate = new Date().getTime()
  }

  const toggleModal = () => setVisibleModal(!visibleModal)

  return (
    <Fragment>
      <div className=" ">
        <div className="px-4 py-5 sm:px-6 sm:m-1">
          <div>
            <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
              <div className="text-center mt-3">
                <TypographyAvatar
                  name={
                    dataSource &&
                    `${dataSource.first_name} ${dataSource.last_name}`
                  }
                ></TypographyAvatar>
              </div>
              <div className="md:flex">
                <div className="p-8">
                  <p
                    href="#"
                    className="block mt-1 text-lg leading-tight font-medium text-black"
                  >
                    Datos de contacto
                  </p>
                  <p className="mt-2 text-gray-500">
                    Estos son los datos que los usuarios de la plataforma verán
                    en las propiedades que tengas asignadas.
                  </p>
                  <div className="mt-3 uppercase tracking-wide text-sm text-blue-800 font-semibold">
                    {dataSource && dataSource.first_name}{' '}
                    {dataSource && dataSource.last_name}
                  </div>
                  <div className="flex mt-3">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="ml-3">
                      {dataSource
                        ? dataSource?.contact?.mobile
                        : '------------'}
                    </span>
                  </div>
                  <div className="flex mt-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                      <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
                    </svg>
                    <span className="ml-3">
                      {dataSource
                        ? dataSource.contact.whatsapp
                        : '------------'}
                    </span>
                  </div>
                  <div className="flex mt-3">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-3">
                      {dataSource ? dataSource.email : '------------'}
                    </span>
                  </div>
                </div>
              </div>
              {dataSource && (
                <button
                  onClick={toggleModal}
                  type="button"
                  data-splitbee-event="account-edit-contact"
                  className="w-100 text-center items-center px-4 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                >
                  <span className="inline">Editar </span>
                  <svg
                    className=" inline ml-2 -mr-0.5 h-4 w-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    ></path>
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* IF ADMIN SHOW  */}

      {canUpdate && accountType !== 'advertiser' && (
        <div className=" ">
          <div className="px-4 py-5 sm:px-6">
            <div>
              <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="text-center mt-3">
                  {dataSource?.logo_client ? (
                    <PictureAvatar
                      img={`${dataSource.logo_client}?lastmod=${modDate}`}
                    >
                      {' '}
                    </PictureAvatar>
                  ) : (
                    <TypographyAvatar
                      name={dataSource && dataSource.business_name}
                    ></TypographyAvatar>
                  )}
                </div>
                <div className="md:flex">
                  <div className="p-8">
                    <p className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">
                      Datos de la inmobiliaria
                    </p>
                    <p className="mt-2 text-gray-500">
                      Estos datos aparecerán en la guía inmobiliaria y en caso
                      de que falten datos de contacto en alguna propiedad.
                    </p>
                    <div className="mt-3 uppercase tracking-wide text-sm text-blue-800 font-semibold">
                      {dataSource && dataSource.business_name}
                    </div>
                    <div className="flex mt-3">
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>
                      <span className="ml-3">
                        {dataSource ? dataSource.phone_contact : '------------'}
                      </span>
                    </div>

                    <div className="flex mt-3">
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="ml-3">
                        {dataSource ? dataSource.email_contact : '------------'}
                      </span>
                    </div>
                  </div>
                </div>

                {canUpdate && dataSource && (
                  <Link
                    to={{
                      pathname: '/account/real-estate',
                      state: {
                        realEstate: dataSource.client,
                      },
                    }}
                  >
                    <button
                      type="button"
                      data-splitbee-event="account-edit-real-estate"
                      className="my-3 w-100 text-center items-center px-4 py-3 border-2 border-blue-900 shadow-sm text-sm leading-4 font-medium rounded-md text-blue-900 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                    >
                      <span className="inline">Editar </span>
                      <svg
                        className=" inline ml-2 -mr-0.5 h-4 w-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                )}

                <div className="m-3">
                  <label
                    htmlFor="company_website"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Editar Url de la inmobiliaria
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      casasyterrenos.com/inmobiliaria/
                    </span>
                    <input
                      type="text"
                      name="company_website"
                      id="company_website"
                      className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none border rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                      value={permalink || ''}
                      onBlur={updatePermalink}
                      onChange={(e) => onPermalinkChange(e.target.value)}
                      placeholder="www.example.com"
                    />
                  </div>
                </div>

                {dataSource?.permalink && (
                  <a
                    href={`${process.env.ENV.portal}/inmobiliaria/${dataSource.permalink}`}
                    target="_blank"
                    data-splitbee-event="account-page-real-estate"
                  >
                    <button
                      type="button"
                      className="items-center px-4 py-3 border border-transparent text-center text-base font-medium rounded-md shadow-sm text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 w-100"
                    >
                      Ir a la página de la inmobiliaria
                    </button>
                  </a>
                )}
              </div>

              <div className="mt-5 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl px-4 py-5 sm:px-6">
                <p className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">
                  Subscripción
                </p>
                <p className="m-2 text-gray-500">
                  La subscripción actual es válida hasta el:{' '}
                  {new Date(
                    dataSource?.contract_dates?.end_date
                  ).toLocaleDateString('es-MX')}
                </p>

                <a href="/renovacion" data-splitbee-event="account-renew">
                  <button
                    type="button"
                    className="items-center px-4 py-3 border border-transparent text-center text-base font-medium rounded-md shadow-sm text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 w-100"
                  >
                    Renovar ahora
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {dataSource && (
        <Modal
          visible={visibleModal}
          centered
          title="Editar datos de contacto"
          onCancel={toggleModal}
          footer={null}
        >
          <PersonalInformation
            data={dataSource}
            callback={handleSubmitUpdateProfile}
          />
        </Modal>
      )}
    </Fragment>
  )
}
export default AccountMain
