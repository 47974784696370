import React, { useState } from 'react'
import { Row, Col, Button, Form, Input, Select, DatePicker } from 'antd'
import moment from 'moment'
import UseAnalytics from '../Hooks/useAnalytics'
import Card from '../Components/Card'

const { Item: FormItem } = Form
const { Option } = Select
const { RangePicker } = DatePicker
const formatDate = 'YYYY-MM-DD'
const formatStart = 'YYYY-MM-DDT00:00:00.000-00:00'
const formatEnd = 'YYYY-MM-DDT23:59:59.999-00:00'

const FiltersForm = (props) => {
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { onSubmit, filtersAdded, resetFilters, hideTags } = props
  const [afterDate, setAfterDate] = useState(null)
  const [beforeDate, setBeforeDate] = useState(null)

  const { data: whatsappData, error: whatsappError } = UseAnalytics({
    event_type: 'click',
    event_value: 'contact_whatsapp',
    created__gt: afterDate,
    created__lt: beforeDate,
  })

  const { data: callData, error: callError } = UseAnalytics({
    event_type: 'click',
    event_value: 'contact_call',
    created__gt: afterDate,
    created__lt: beforeDate,
  })

  const tagsAdded = (values) => {
    let tagsNames = []

    Object.keys(values).forEach((key) => {
      let value = values[key]
      let informationTag

      if (key === 'search') informationTag = `Buscar: ${value}`
      if (key === 'property') informationTag = `ID Propiedad: ${value}`
      if (key === 'utm_source') informationTag = `UTM: ${value}`
      if (key === 'classification') {
        let typeClasification
        if (value === 'dev') typeClasification = 'Desarrollos'
        if (value === 'prop') typeClasification = 'Propiedades'
        if (value === 'proto') typeClasification = 'Prototipos'
        informationTag = `Clasificación: ${typeClasification}`
      }
      if (key === 'created_range') {
        const dateFormatted = value.replace(',', '/')
        informationTag = `Rango de fechas: ${dateFormatted}`
      }

      if (informationTag) {
        tagsNames.push(informationTag)
      }
    })

    filtersAdded(tagsNames)
  }

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        Object.keys(values).forEach((key) => {
          ;(values[key] === undefined && delete values[key]) ||
            (values[key] === '' && delete values[key]) ||
            (values[key].length === 0 && delete values[key])
        })
        const { rangePicker } = values

        if (rangePicker) {
          let [startDate, endDate] = rangePicker
          setAfterDate(moment(startDate).format(formatStart))
          setBeforeDate(moment(endDate).format(formatEnd))
          startDate = moment(startDate).format(formatDate)
          endDate = moment(endDate).format(formatDate)
          values.created_range = `${startDate},${endDate}`
        }

        onSubmit(values)
        if (!hideTags) tagsAdded(values)
      }
    })
  }

  const cleanFilters = () => {
    onSubmit()
    resetFields()
    setAfterDate(null)
    setBeforeDate(null)
    if (!hideTags) resetFilters()
  }

  return (
    <Form
      onSubmit={submitForm}
      className="login-form"
      colon={false}
      hideRequiredMark
      className={!hideTags ? '' : 'bg-light p-3'}
    >
      <Row type="flex" gutter={8}>
        <Col xs={24} md={6}>
          <FormItem label="Buscar por nombre, email o teléfono">
            {getFieldDecorator('search', {})(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24} md={3}>
          <FormItem label="ID Propiedad">
            {getFieldDecorator('property', {})(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24} md={5}>
          <FormItem label="Estatus">
            {getFieldDecorator(
              'status',
              {}
            )(
              <Select placeholder="Selecciona un estatus">
                <Option value="">Todos</Option>
                <Option value="1">Pendiente</Option>
                <Option value="2">Contactado</Option>
                <Option value="3">Descartado</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={6}>
          <FormItem label="Periodo de contactos">
            {getFieldDecorator(
              'rangePicker',
              {}
            )(<RangePicker className={!hideTags && 'block'} />)}
          </FormItem>
        </Col>
        <Col xs={24} md={4}>
          <div className="mb-4 md:mb-0">
            <p className="md:hidden">Este mes</p>

            <Card
              title="Contactos directos en WhatsApp"
              // subtitle={whatsappData?.count}
              subtitle={whatsappData?.count}
            />

            <Card title="Llamadas recibidas" subtitle={callData?.count} />
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col>
          <Button onClick={cleanFilters} className="mr-2" data-splitbee-event="contacts-clean-filters">
            Limpiar filtros
          </Button>
          <Button type="primary" htmlType="submit" data-splitbee-event="contacts-apply-filters">
            Aplicar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const FiltersFormWrapper = Form.create({})(FiltersForm)
export default FiltersFormWrapper
