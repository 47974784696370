import React, { useState, useEffect, useMemo } from 'react'
import SimpleSteps from '../Components/Navigation/SimpleSteps'
import GeneralDescription from './general_description'
import LocationProperty from './location'
import UploadMedia from './upload_media'
import { useHistory } from 'react-router-dom'
import log from '../Utils/logger'

function handleReload(e) {
  e.preventDefault()
  e.returnValue =
    'La información de la propiedad se perderá si sales ahora, ¿deseas abandonar la página?'
}

export default function create(props) {
  const [propertyId, setPropertyId] = useState(() => {
    return window.localStorage.getItem('propertyId') || null
  })
  const [step, setStep] = useState(() => {
    return window.localStorage.getItem('creationStep') || 1
  })
  const history = useHistory()

  useEffect(() => {
    window.addEventListener('beforeunload', handleReload)
    return () => {
      window.removeEventListener('beforeunload', handleReload)
      window.localStorage.removeItem('creationStep')
      window.localStorage.removeItem('propertyId')
    }
  }, [])

  const gotoLocationStep = (response) => {
    if (response?.data?.id) {
      window.localStorage.setItem('propertyId', response.data.id)
      setPropertyId(response.data.id)
    }
    window.localStorage.setItem('creationStep', 2)
    setStep(2)
  }

  const gotoMediaStep = (response) => {
    try {
      window.localStorage.setItem('creationStep', 3)
      setStep(3)
    } catch (err) {
      log.error(err)
    }
  }

  const finish = (e) => {
    history.push('/properties')
    window.localStorage.removeItem('creationStep')
    window.localStorage.removeItem('propertyId')
  }

  function setStatus(index, currentIndex) {
    if (index <= currentIndex) {
      return 'current'
    } else {
      return 'upcoming'
    }
  }

  const propertyCreationSteps = useMemo(() => {
    return [
      {
        name: 'Información General',
        description: 'Inmobiliaria o agente independiente',
        href: '#',
        status: setStatus(1, step),
      },
      {
        name: 'Ubicación',
        description: 'Define para preparar tu plan',
        href: '#',
        status: setStatus(2, step),
      },
      {
        name: 'Fotografías',
        description: 'Contrata tu plan y accede a PRO',
        href: '#',
        status: setStatus(3, step),
      },
    ]
  }, [step])

  const goBack = () => {
    window.localStorage.setItem('creationStep', step - 1)
    setStep(step - 1)
  }

  return (
    <div className="bg-white p-5 rounded m-5">
      <SimpleSteps steps={propertyCreationSteps} />
      {(step === 1 || step === '1') && (
        <GeneralDescription
          callBack={gotoLocationStep}
          propertyId={propertyId}
          action="create"
        />
      )}
      {(step === 2 || step === '2') && (
        <LocationProperty
          callBack={gotoMediaStep}
          propertyId={propertyId}
          goBack={goBack}
          action="create"
        />
      )}
      {(step === 3 || step === '3') && (
        <UploadMedia
          callBack={finish}
          propertyId={propertyId}
          goBack={goBack}
          action="create"
        />
      )}
    </div>
  )
}
