import axios from 'axios'
import { fetchHeaders } from './utils'

export const PropertyAPI = {
  getMyProperties,
  getProperties,
  getProperty,
  createProperty,
  editProperty,
  getPropertyInventory,
  getOrigin,
  getStats,
  requestPresignedUrl,
  uploadImageProperty,
  updateOrderImages,
  removeImageProperty,
  getImagesProperty,
}

const { api: API_URL } = process.env.ENV

async function getMyProperties(params) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}my_property`,
    params,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      error.results = []
      error.count = 0
      return error
    }
  )
}

async function getProperties(params) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}property`,
    params,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      error.results = []
      error.count = 0
      return error
    }
  )
}

async function getProperty(propertyID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}property/${propertyID}`,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function getPropertyInventory(propertyID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}inventory_property/${propertyID}`,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function editProperty(propertyID, data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'PATCH',
    url: `${API_URL}property/${propertyID}`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function getOrigin() {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}property/feeds?status=1`,
  })
}

async function getStats(propertyID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}stats_my_property/${propertyID}`,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function createProperty(data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'POST',
    url: `${API_URL}property`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function getImagesProperty(propertyID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'PATCH',
    url: `${API_URL}property/${propertyID}`,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function requestPresignedUrl(data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'POST',
    url: `${API_URL}aws_signature_url`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function uploadImageProperty(url, data) {
  /* return fetch(url, {
    method: 'PUT',
    body: data
  }).then(
    response => console.log(response) // if the response is a JSON object
  ).then(
    success => console.log(success) // Handle the success response object
  ).catch(
    error => console.log(error) // Handle the error response object
  ); */
  return axios({
    method: 'POST',
    headers: {
      'Content-Type':
        'multipart/form-data; boundary=---011000010111000001101001',
    },
    url: `${url}`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function updateOrderImages(propertyID, data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'PATCH',
    url: `${API_URL}update_order_image/${propertyID}`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function removeImageProperty(imgID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'DELETE',
    url: `${API_URL}delete_image/${imgID}`,
  }).then(
    (response) => {
      let { status } = response
      let resp = {}

      if (status > 199 && status < 300) {
        resp = {
          message: 'La imagen se a eliminado correctamente.',
          success: true,
        }
      } else
        resp = {
          message: '¡Oops! Ha habido un error. Intentalo nuevamente.',
          success: false,
        }
      return resp
    },
    (error) => {
      console.log(error)
    }
  )
}
