import axios from 'axios'
import { fetchHeaders } from './utils'

export const PromoterAPI = {
  signUpPromoter,
  getPromoters,
  getPromotersList,
  editPromoter,
}

const { api: API_URL } = process.env.ENV

async function signUpPromoter(data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'POST',
    url: `${API_URL}auth/membershipsignup/`,
    data,
  })
}

async function getPromoters(params = { status: 1 }) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    params,
    method: 'GET',
    url: `${API_URL}membership`,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function getPromotersList(params = { status: 1 }) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    params,
    method: 'GET',
    url: `${API_URL}membership/list`,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}
async function editPromoter(membershipID, data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'PATCH',
    url: `${API_URL}membership/${membershipID}`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}
