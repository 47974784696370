import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Form, Input } from 'antd'

// Own component
import UploadImgPresigned from '../../Shared/Common/UploadImgPresigned'

const { Item: FormItem } = Form

const MultimediaEditProperty = (props) => {
  const { getFieldDecorator, setFieldsValue } = props.form
  const { hidden, initialValues, propertyID, isLoading } = props
  const [imagesList, setImagesList] = useState([])

  useEffect(() => {
    if (imagesList.length > 0) setFieldsValue({ images: imagesList })
  }, [imagesList])

  useEffect(() => {
    const { youtube_url, virtual_tour_url, images } = initialValues
    setFieldsValue({
      youtube_url,
      virtual_tour_url,
      images,
    })
  }, [initialValues])

  const handleLoading = (loading) => {
    isLoading(loading)
  }

  const handleChangeImages = (imagesList) => {
    setImagesList(imagesList)
  }

  return (
    <Fragment>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        gutter={[48, 8]}
        className={hidden ? 'hidden' : 'flex'}
      >
        <Col xs={24}>
          <FormItem label="Imagenes">
            {getFieldDecorator('images', {
              rules: [
                {
                  required: true,
                  message: 'Agrega por lo menos una imagen a tu propiedad',
                },
              ],
            })(
              <UploadImgPresigned
                propertyID={propertyID}
                handleChange={handleChangeImages}
              />
            )}
          </FormItem>
        </Col>
      </Row>

      <Row
        type="flex"
        justify="space-between"
        align="middle"
        gutter={[48, 8]}
        className={hidden ? 'hidden' : 'flex'}
      >
        <Col xs={24}>
          <FormItem label="Dirección sitio web Youtube" colon={false}>
            {getFieldDecorator('youtube_url', {})(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Recorrido virtual matterport.com" colon={false}>
            {getFieldDecorator('virtual_tour_url', {})(<Input />)}
          </FormItem>
        </Col>
      </Row>
    </Fragment>
  )
}

export default MultimediaEditProperty
