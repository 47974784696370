import React from 'react'
import PropTypes from 'prop-types'
import matchAll from 'string.prototype.matchall'

export default function TypographyAvatar({ name, size }) {
  const n = name || 'John Doe'
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
  let initials = [...matchAll(n, rgx)] || []
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()

  return (
    <span
      className={`inline-flex items-center justify-center h-${size} w-${size} rounded-full bg-gray-500`}
    >
      <span className="text-sm font-lg leading-none text-white">
        {initials}
      </span>
    </span>
  )
}

TypographyAvatar.defaultProps = {
  name: 'John Doe',
  size: 16,
}

TypographyAvatar.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf([6, 8, 10, 12, 14, 16]),
}
