import React from 'react'
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik'
import UploadImages from '../../Components/Forms/UploadImages'
import uppyconfig from './utils/uppyConfig'
import * as Yup from 'yup'
import acquisitionClient from '../../Services/acquisitionApiClient'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const DescriptionSchema = Yup.object().shape({
  description: Yup.string().required('Campo requerido'),
  images: Yup.array()
    .of(Yup.string(), 'Debes agregar al menos una imagen')
    .min(1, 'Debes agregar al menos una imagen')
    .required('Debes agregar al menos una imagen'),
})

export default function form() {
  const history = useHistory()
  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      await acquisitionClient.patch(
        `/customers/properties/${localStorage.getItem('CYTpropertyUuid')}/`,
        values
      )
      setSubmitting(false)
      history.push('/unirse/seleccionar-plan')
    } catch (err) {
      setSubmitting(false)
      Sentry.captureException(err)
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        description: '',
        images: [],
      }}
      validationSchema={DescriptionSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form className="w-full space-y-6">
          <div>
            <label htmlFor="description" className="form-title">
              Descripción
            </label>
            <Field
              className="input"
              as="textarea"
              rows="4"
              name="description"
              placeholder="Texto sobre ubicación, amenidades, cosas que hay alrededor, etc."
            ></Field>
            <ErrorMessage
              name="description"
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
            />
          </div>

          <div>
            <label htmlFor="description" className="form-title">
              Fotos
            </label>
            <FieldArray name="images" component={memoizedImageUpload} />
            <ErrorMessage
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
              name="images"
            />
          </div>

          <div className="grid grid-cols-2 sticky md:relative  bottom-0 w-full md:bg-transparent bg-white z-50 p-3 md:p-5">
            <a href="/unirse/amenidades" className="self-center underline">
              Regresar
            </a>
            <div className="sticky bottom-0 p-2 bg-white md:block md:p-0">
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-blue-900 group-hover:text-blue-800"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Continuar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const ImageWithFieldArray = ({ push }) => {
  const config = uppyconfig({ callBack: push })

  return (
    <Form>
      <UploadImages uppyConfiguration={config} />
    </Form>
  )
}

const memoizedImageUpload = React.memo(
  ImageWithFieldArray,
  (prev, now) => false
)
