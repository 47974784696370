import axios from 'axios'
import { fetchHeaders } from './utils'

export const AccountAPI = {
  getAccount,
  getInformationOwnRealEstate,
  updateOwnRealEstate,
  updateAccount,
  sendCodeResetPassword,
  resetPassword,
}

const { api: API_URL } = process.env.ENV

async function getAccount() {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}accounts/me/`,
  }).then(
    (response) => {
      let { data } = response

      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function getInformationOwnRealEstate(IDRealEstate) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}clients/pro/${IDRealEstate}`,
  }).then(
    (response) => {
      let { data } = response

      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function updateOwnRealEstate(IDRealEstate, data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'PATCH',
    url: `${API_URL}clients/update/pro/${IDRealEstate}`,
    data,
  }).then(
    (response) => {
      let { status } = response

      if (status >= 200 && status <= 299) {
        return {
          success: true,
          message: 'Se ha actualizado la inmobiliaria correctamente.',
        }
      }
    },
    (error) => {
      return {
        success: false,
        message: '¡Oops! Algo ha ocurrido. Contacta con un ejecutivo.',
      }
    }
  )
}

async function updateAccount(data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'PATCH',
    url: `${API_URL}accounts/me/`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function sendCodeResetPassword(data) {
  return axios({
    method: 'POST',
    url: `${API_URL}auth/send_code_password/`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      const { data } = error.response
      return data
    }
  )
}

async function resetPassword(data) {
  return axios({
    method: 'POST',
    url: `${API_URL}auth/reset_password/`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      const { data } = error.response
      return data
    }
  )
}
