import React, { Fragment, useEffect } from 'react'
import { Form, InputNumber, Col, Checkbox } from 'antd'

// Own Components
// import SelectDevelop from "../../Shared/Selects/SelectDevelop";
// import SelectPrototype from "../../Shared/Selects/SelectPrototype";

// Utils
import { validateIntegers } from '../../Shared/utils'

// const { Option } = Select;
const { Item: FormItem } = Form

const PropertyBaseInputs = (props) => {
  const { form, initialvalues } = props
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form

  const isDevelop = getFieldValue('hasDevelop')
  const developID = getFieldValue('develop')

  useEffect(() => {
    if (initialvalues) {
      const {
        age,
        rooms,
        bathrooms,
        half_bathrooms,
        floors,
        parking,
        sqr_mt_construction,
        sqr_mt_lot,
        service_rooms,
      } = initialvalues
      setFieldsValue({
        age,
        rooms,
        bathrooms,
        half_bathrooms,
        floors,
        parking,
        sqr_mt_lot,
        sqr_mt_construction,
        service_rooms,
      })
    }
  }, [initialvalues])

  /*const handleChangeDevelop = value => {
    console.log("value", value);
  }*/

  return (
    <Fragment>
      {/*<Col xs={24} md={8}>
        <FormItem label="&nbsp;" colon={false}>
          {getFieldDecorator("hasDevelop", {
            initialValue: false,
            valuePropName: "checked"
          })(<Checkbox>¿Pertenece a un desarrollo?</Checkbox>)}
        </FormItem>
        </Col>*/}
      {/*isDevelop ? (
        <Fragment>
          <Col {...props}>
            <FormItem label="Selecciona un desarrollo" colon={false}>
              {getFieldDecorator("develop", {
                rules: [
                  {
                    required: true,
                    message: "Selecciona un desarrollo"
                  }
                ]
              })(<SelectDevelop
                    placeholder="Selecciona un desarrollo"
                    onChange={handleChangeDevelop}
                    />)}
            </FormItem>
          </Col>
          <Col {...props}>
            <FormItem label="Selecciona un prototipo">
              {getFieldDecorator("prototype", {
                rules: [
                  {
                    required: true,
                    message: "Selecciona un prototipo"
                  }
                ]
              })(
                <SelectPrototype
                  developID={developID}
                  placeholder="Selecciona un prototipo"
                />
              )}
            </FormItem>
          </Col>
        </Fragment>
              ) : null*/}
      <Col {...props}>
        <FormItem label="Antiguedad (en años)">
          {getFieldDecorator(
            'age',
            {}
          )(<InputNumber min={1} precision={0} step={1} className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Habitaciones">
          {getFieldDecorator('rooms', {
            rules: [
              {
                required: true,
                message: 'Indica la cantidad de habitaciones',
              },
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={1} className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Baños">
          {getFieldDecorator('bathrooms', {
            rules: [
              {
                required: true,
                message: 'Indica la cantidad de baños',
              },
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={1} className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Medios baños">
          {getFieldDecorator('half_bathrooms', {
            rules: [
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={0} className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Niveles">
          {getFieldDecorator('floors', {
            rules: [
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={1} max={50} className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Estacionamientos">
          {getFieldDecorator('parking', {
            rules: [
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Terreno en &#13217;">
          {getFieldDecorator(
            'sqr_mt_lot',
            {}
          )(<InputNumber min={1} className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Construcción en &#13217;">
          {getFieldDecorator(
            'sqr_mt_construction',
            {}
          )(<InputNumber min={1} className="w-100" />)}
        </FormItem>
      </Col>
      <Col {...props}>
        <FormItem label="Cuarto de servicio">
          {getFieldDecorator('service_rooms', {
            initialValue: false,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </FormItem>
      </Col>
    </Fragment>
  )
}

export default PropertyBaseInputs
