import React from 'react'
import { Row, Col, Button, Form } from 'antd'

const DeletePromoter = (props) => {
  const { getFieldDecorator, validateFields } = props.form
  const { id } = props.promoter
  const { onSubmit, promoter, onCancel } = props

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  getFieldDecorator('id', { initialValue: id })
  getFieldDecorator('status', { initialValue: 3 })

  return (
    <Form
      onSubmit={submitForm}
      className="login-form"
      colon={false}
      hideRequiredMark
      data-splitbee-event="promoters-delete-promoter-save"
    >
      <Row
        type="flex"
        justify="center"
        gutter={[48, 48]}
        className="text-center my-2"
      >
        <Col xs={24}>
          ¿Estás seguro de eliminar a {promoter.first_name} {promoter.last_name}
          ?
        </Col>
      </Row>
      <Row type="flex" justify="end" gutter={8}>
        <Col>
          <Button onClick={onCancel} data-splitbee-event="promoters-delete-promoter-cancel">Cancelar</Button>
        </Col>
        <Col>
          <Button type="danger" htmlType="submit">
            Eliminar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const DeletePromoterWrapper = Form.create({})(DeletePromoter)
export default DeletePromoterWrapper
