import React from 'react'
import { Row, Col } from 'antd'

//Own components
import ContainerLayout from '../../Layouts/ContainerLayout'
import PageHeaderStyled from '../Common/PageHeader'

const ContactPage = (props) => {
  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <PageHeaderStyled title="Preguntas frecuentes" className="px-0 pt-0" />
      <Row type="flex" justify="center">
        <Col xs={24} className="text-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/LzNOqY7_r3E"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="Casas y Terrenos"
            allowFullScreen
          ></iframe>
        </Col>
        <Col xs={24} className="text-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/-dkBmyUz08k"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="Casas y Terrenos"
            allowFullScreen
          ></iframe>
        </Col>
        <Col xs={24} className="text-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/lbuCzgbmGkk"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="Casas y Terrenos"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </ContainerLayout>
  )
}

export default ContactPage
