import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { PropertyAPI } from '../../Services/property'
import { IntegrationsAPI } from '../../Services/integrations'

import log from '../../Utils/logger'

const apiFeeds = ['538', '532', '536']

const schema = Yup.object({
  link_email_crm: Yup.string()
    .email('Correo electrónico inválido')
    .nullable(true),
  feed_id: Yup.string().required('CRM es un campo requerido'),
  external_token: Yup.string()
    .nullable(true)
    .when('feed_id', {
      is: (x) => apiFeeds.some((z) => z == x),
      then: (schema) => schema.required('Este campo es requerido'),
    }),
  external_id: Yup.string()
    .nullable(true)
    .when('feed_id', {
      is: (x) => x == 536 || x == 538,
      then: (schema) => schema.required('Este campo es requerido'),
    }),
})

export default function Create({ callBack, initialValues }) {
  const [feeds, setFeeds] = useState([])
  async function fetchData() {
    try {
      const response = await PropertyAPI.getOrigin()
      setFeeds(response.data)
    } catch (err) {
      log.error(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const submit = async (values) => {
    try {
      await IntegrationsAPI.updateCRM(values)
      callBack()
    } catch (err) {
      log.error(err)
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          ...(initialValues?.link_email_crm && {
            link_email_crm: initialValues?.link_email_crm,
          }),
          ...(initialValues?.feed
            ? { feed_id: initialValues?.feed }
            : { feed_id: 209 }),
          ...(initialValues?.external_token && {
            external_token: initialValues?.external_token,
          }),
          ...(initialValues?.external_id && {
            external_id: initialValues?.external_id,
          }),
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ isSubmitting, values, errors }) => (
          <Form className="space-y-5 md:space-y-2" data-splitbee-event={`integrations-${initialValues?.feed ? 'add' : 'edit-integration'}-save`}>
            <div>
              <label className="w-full" htmlFor="feed_id">
                CRM
              </label>
              <Field
                as="select"
                name="feed_id"
                className="uppercase border p-2 rounded-md border-gray-300 w-full block"
              >
                {feeds.map((x) => (
                  <option className="uppercase" value={x.id}>
                    {x.portal}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                render={(msg) => (
                  <div className="text-red-600 text-sm">{msg}</div>
                )}
                name="feed_id"
              />
            </div>

            {values.feed_id ? (
              <>
                {apiFeeds.some((x) => x == values.feed_id) ? (
                  <>
                    <div>
                      <label
                        className="capitalize w-full  "
                        htmlFor="external_token"
                      >
                        API Token{' '}
                      </label>
                      <Field
                        type="string"
                        name="external_token"
                        className="border p-2 rounded-md border-gray-300 w-full block"
                      />
                      <ErrorMessage
                        render={(msg) => (
                          <div className="text-red-600 text-sm">{msg}</div>
                        )}
                        name="external_token"
                      />
                    </div>

                    {(values.feed_id == 536 || values.feed_id == 538) && (
                      <div>
                        <label
                          className="capitalize w-full"
                          htmlFor="external_id"
                        >
                          Id_company
                        </label>
                        <Field
                          type="string"
                          name="external_id"
                          className="border p-2 rounded-md border-gray-300 w-full block"
                        />
                        <ErrorMessage
                          render={(msg) => (
                            <div className="text-red-600 text-sm">{msg}</div>
                          )}
                          name="external_id"
                        />
                      </div>
                    )}
                    <p alt="Cómo obtener API key" className=" ">
                      <a
                        className="hover:underline"
                        href="https://bekind.slite.com/api/s/note/3UPxtdHNV9KOeYrgpg332K/Como-obtener-tokens-para-integrarse-a-casasyterrenos-com"
                        target="_blank"
                      >
                        ¿Tienes dudas sobre como obtener el API key?
                      </a>
                    </p>
                  </>
                ) : (
                  <div>
                    <label
                      className="capitalize w-full"
                      htmlFor="link_email_crm"
                    >
                      email
                    </label>
                    <Field
                      type="string"
                      name="link_email_crm"
                      className="border p-2 rounded-md border-gray-300 w-full block"
                      placeholder="example@example.com"
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="text-red-600 text-sm">{msg}</div>
                      )}
                      name="link_email_crm"
                    />
                  </div>
                )}
              </>
            ) : null}

            <button
              disabled={isSubmitting}
              className="button-primary-mid"
              type="submit"
            >
              <span className="font-bold">
                {initialValues?.feed ? 'Editar' : 'Agregar integración'}
              </span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
