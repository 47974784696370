import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { RadioGroup } from '@headlessui/react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import Notification from '../Components/Overlays/Notification'

const { apiUrl } = process.env.ENV

const options = [
  { title: 'Trato directo', value: 'direct' },
  { title: 'Represento Inmobiliaria', value: 'representative' },
  { title: 'Promotor independiente', value: 'promoter' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Register = () => {
  const [viewNotification, toggleNotification] = useState(false)
  const history = useHistory()

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      const response = await axios.post(
        `${apiUrl}/customers/registration/`,
        values
      )
      const { token } = response.data
      localStorage.setItem('acquisitionToken', token)
      setSubmitting(false)
      if (values.account_type === 'direct') {
        history.push('/unirse/ubicacion-precio')
      } else {
        history.push('/unirse/propiedades')
      }
    } catch (err) {
      if (err?.response?.status === 409) {
        toggleNotification(true)
      }
      setSubmitting(false)
      Sentry.captureException(err)
    }
  }

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Campo requerido'),
    last_name: Yup.string().required('Campo requerido'),
    email: Yup.string()
      .email('Correo electrónico no válido')
      .required('Campo requerido'),
    city: Yup.string().required('Campo requerido'),
    account_type: Yup.string().required('Se debe elegir una opción'),
    phone: Yup.string()
      .length(10, 'Teléfono a 10 dígitos')
      .required('Campo requerido'),
    client_name: Yup.string(),
  })

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          client_name: '',
          name: '',
          last_name: '',
          email: '',
          city: '',
          phone: '',
          account_type: '',
        }}
        validationSchema={SignupSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="w-full relative space-y-3">
            <RadioGroup
              value={values.account_type}
              onChange={(val) => setFieldValue('account_type', val)}
            >
              <RadioGroup.Label className="form-title my-10  ">
                ¿Qué describe mejor tu situación?
              </RadioGroup.Label>
              <div className="space-y-3 md:space-y-0 md:grid md:grid-cols-3 gap-2">
                {options.map((option, i) => (
                  <RadioGroup.Option
                    key={i}
                    value={option.value}
                    className="md:h-28  relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
                  >
                    {({ checked }) => (
                      <>
                        <div className="flex flex-1 items-center">
                          <div className="text-sm  flex-1">
                            <RadioGroup.Label
                              as="p"
                              className="text-base leading-6 font-medium text-gray-900 text-center"
                            >
                              {option.title}
                            </RadioGroup.Label>
                          </div>
                        </div>

                        <div
                          className={classNames(
                            checked
                              ? 'border-yellow-500'
                              : 'border-transparent',
                            'absolute -inset-px rounded-lg border-2 pointer-events-none'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
            {errors.account_type && (
              <div className="text-sm text-red-600">{errors.account_type}</div>
            )}
            <p className="form-title my-8">Datos de contacto</p>
            <div className="md:grid grid-cols-2 md:gap-6 md:space-y-0 space-y-3">
              <div>
                <label htmlFor="first_name" className=" font-medium">
                  Nombre
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                  required
                  className="input"
                  placeholder="Nombre"
                />
                {errors.name && touched.name && (
                  <div className="text-sm text-red-600">{errors.name}</div>
                )}
              </div>

              <div>
                <label htmlFor="last_name" className=" font-medium">
                  Apellido
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  onChange={handleChange}
                  value={values.last_name}
                  required
                  className="input"
                  placeholder="Apellido"
                />
                {errors.last_name && touched.last_name && (
                  <div className="text-sm text-red-600">{errors.last_name}</div>
                )}
              </div>

              <div>
                <label htmlFor="email-address" className=" font-medium">
                  Correo electrónico
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={handleChange}
                  value={values.email}
                  required
                  className="input"
                  placeholder="you@example.com"
                />
                {errors.email && touched.email && (
                  <div className="text-sm text-red-600">{errors.email}</div>
                )}
              </div>

              <div>
                <label htmlFor="phone" className=" font-medium">
                  Teléfono
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  onChange={handleChange}
                  value={values.phone}
                  required
                  autoComplete="tel"
                  className="input"
                  placeholder="000-000-0000"
                />
                {errors.phone && touched.phone && (
                  <div className="text-sm text-red-600">{errors.phone}</div>
                )}
              </div>

              <div>
                <label htmlFor="city" className=" font-medium">
                  Ciudad
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  autoComplete="new-city"
                  onChange={handleChange}
                  value={values.city}
                  required
                  className="input"
                  placeholder="Ciudad"
                />
                {errors.city && touched.city && (
                  <div className="text-sm text-red-600">{errors.city}</div>
                )}
              </div>
              <div>
                {values.account_type !== 'direct' && (
                  <>
                    <label htmlFor="client_name" className=" font-medium">
                      Nombre de inmobiliaria
                    </label>
                    <input
                      id="client_name"
                      name="client_name"
                      type="text"
                      required
                      onChange={handleChange}
                      value={values.client_name}
                      className="input"
                      placeholder="Inmobiliaria o Nombre comercial"
                    />
                    {errors.client_name && touched.client_name && (
                      <div className="text-sm text-red-600">
                        {errors.client_name}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="mt-3 col-span-2 sticky bottom-0 w-full p-4 bg-white md:block">
              <div
                className="hidden mb-10  md:flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-blue-900 group-hover:text-blue-800"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Inicio
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Notification
        show={viewNotification}
        setShow={toggleNotification}
        type="error"
        title="Correo electrónico en uso"
        description="El correo electrónico ingresado ya ha sido registrado previamente"
      />
    </>
  )
}

export default Register
