import React from 'react'
import GoogleMapReact from 'google-map-react'

const { maps: GOOGLE_MAPS_KEY } = process.env.ENV.google

export default function MapInput({ coordinates }) {
  const defaultCoordinates = { lat: 24.1719969, lng: -103.5970966 }

  return (
    <div className="w-full h-full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
        center={coordinates || defaultCoordinates}
        zoom={15}
        yesIWantToUseGoogleMapApiInternals
      >
        {coordinates?.lat && coordinates?.lng && (
          <div
            className="text-blue-800 absolute -translate-x-1/2 -translate-y-1/2"
            lat={coordinates.lat}
            lng={coordinates.lng}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </GoogleMapReact>
    </div>
  )
}

MapInput.defaultProps = {
  callBack: () => true,
}
