import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'antd'

// Utils
import { capitalizeFirstLetter } from './../../Shared/utils'

const InactiveProperty = (props) => {
  const { getFieldDecorator, validateFields } = props.form
  const { id } = props.property
  const { onSubmit, action } = props

  const [typeButton, setTypeButton] = useState('primary')
  const [textAction, setTextAction] = useState('publicar')

  useEffect(() => {
    if (action === 'inactive') {
      setTypeButton('danger')
      setTextAction('inactivar')
    }
  }, [action])

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  getFieldDecorator('id', { initialValue: id })
  getFieldDecorator('status', { initialValue: action })

  return (
    <Form
      onSubmit={submitForm}
      className="login-form"
      colon={false}
      hideRequiredMark
    >
      <Row type="flex" gutter={48}>
        <Col xs={24}>
          <p className="text-center pb-5 pt-4 mb-0">
            ¿Estás seguro de{' '}
            <span className="font-weight-bolder">{textAction}</span> la
            propiedad con ID {id}?
          </p>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button type={typeButton} htmlType="submit" data-splitbee-event={`properties-${action}-property-confirm`}>
            {capitalizeFirstLetter(textAction)}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const InactivePropertyWrapper = Form.create({})(InactiveProperty)
export default InactivePropertyWrapper
