import React, { useEffect, useMemo } from 'react'

import { Dashboard } from '@uppy/react'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/dashboard/dist/style.css'

function UploadImages({ uppyConfiguration, ...rest }) {
  return (
    <Dashboard
      {...rest}
      inline={true}
      width="100%"
      uppy={uppyConfiguration}
      proudlyDisplayPoweredByUppy={false}
      locale={{
        strings: {
          dropHereOr: 'Arrastra aquí o %{browse}',
          browse: 'explora tus archivos',
        },
      }}
    />
  )
}

export default React.memo(UploadImages, (x, y) => false)
