import React from 'react'
import { Icon } from 'antd'

const HomeSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path fill="#F05540" d="M382.933 73.6H441.6V176H382.933z"></path>
      <path fill="#F3705A" d="M360.533 21.333H464V76.8H360.533z"></path>
      <path
        fill="#FFD15C"
        d="M44.8 276.267V512h418.133V276.267L253.866 67.2C172.8 149.333 126.933 195.2 44.8 276.267z"
      ></path>
      <path
        fill="#F7B64C"
        d="M464 276.267L254.933 67.2c-41.6 40.533-73.6 72.533-105.6 104.533L44.8 276.266v48l209.067-209.067 209.067 209.067v6.4-54.4H464z"
      ></path>
      <path
        fill="#415A6B"
        d="M216.533 512H90.667V388.267c0-35.2 28.8-62.933 62.933-62.933 35.2 0 62.933 28.8 62.933 62.933V512z"
      ></path>
      <path fill="#344A5E" d="M253.867 325.333H418.134V469.333H253.867z"></path>
      <g fill="#8AD7F8">
        <path d="M270.933 342.4H327.466V452.267H270.933z"></path>
        <path d="M344.533 342.4H401.06600000000003V452.267H344.533z"></path>
      </g>
      <path
        fill="#F3705A"
        d="M502.4 232.533L279.467 9.6c-12.8-12.8-35.2-12.8-48 0L9.6 232.533c-12.8 12.8-12.8 35.2 0 48 12.8 12.8 35.2 12.8 48 0L256 82.133l198.4 198.4c12.8 12.8 35.2 12.8 48 0 12.8-13.866 12.8-35.2 0-48z"
      ></path>
    </svg>
  )
}

const HomeIcon = (props) => <Icon component={HomeSVG} {...props} />

export default HomeIcon
