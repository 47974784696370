import React, { useEffect, useState, Fragment } from 'react'
import { Tabs, Icon } from 'antd'

// Own components
import GeneralDetailsProperty from '../Details/General'
import MultimediaDetailsProperty from '../Details/Multimedia'
import FeaturesDetailsForm from '../Details/Features'
import LocationDetailsForm from '../../Shared/Common/LocationDetailsForm'

// Services
import { PropertyAPI } from './../../Services/property'

const { TabPane } = Tabs

const DetailsProperties = (props) => {
  const { id: propertyID } = props.property
  const [dataSource, setDataSource] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      let dataGeneral = await PropertyAPI.getPropertyInventory(propertyID)
      let dataStats = await PropertyAPI.getStats(propertyID)
      let dataProperty = { ...dataGeneral, ...dataStats }
      dataProperty.id = propertyID
      setDataSource(dataProperty)
    }

    fetchData()
  }, [propertyID])

  return (
    <Fragment>
      {dataSource ? (
        <Tabs
          tabBarStyle={{
            textAlign: 'center',
            marginTop: '4px',
            marginBottom: 0,
          }}
          defaultActiveKey="1"
        >
          <TabPane
            tab={
              <span data-splitbee-event="inventory-cyt-details-item-general">
                <Icon type="profile" />
                General
              </span>
            }
            key="1"
          >
            <GeneralDetailsProperty data={dataSource} />
          </TabPane>
          <TabPane
            tab={
              <span data-splitbee-event="inventory-cyt-details-item-location">
                <Icon type="environment" />
                Ubicación
              </span>
            }
            key="2"
          >
            <LocationDetailsForm data={dataSource} />
          </TabPane>
          <TabPane
            tab={
              <span data-splitbee-event="inventory-cyt-details-item-features">
                <Icon type="reconciliation" />
                Características
              </span>
            }
            key="3"
          >
            <FeaturesDetailsForm data={dataSource} />
          </TabPane>
          {dataSource.images.length > 0 ? (
            <TabPane
              tab={
                <span data-splitbee-event="inventory-cyt-details-item-multimedia">
                  <Icon type="picture" />
                  Multimedia
                </span>
              }
              key="4"
            >
              <MultimediaDetailsProperty data={dataSource} />
            </TabPane>
          ) : null}
        </Tabs>
      ) : (
        'Loading...'
      )}
    </Fragment>
  )
}

export default DetailsProperties
