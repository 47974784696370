const propertyTypes = [
  {
    id: 18,
    name: 'casas',
    value: 'house',
    requiredFields: ['bathrooms', 'rooms'],
    fields: [
      'age',
      'half_bathrooms',
      'floors',
      'parking',
      'sqr_mt_lot',
      'sqr_mt_construction',
    ],
  },
  {
    id: 19,
    name: 'departamentos',
    value: 'apartment',
    requiredFields: ['floor_number', 'rooms', 'bathrooms'],
    fields: [
      'age',
      'half_bathrooms',
      'floors',
      'parking',
      'sqr_mt_lot',
      'sqr_mt_construction',
    ],
  },
  {
    id: 34,
    name: 'terrenos',
    value: 'lot',
    requiredFields: ['sqr_mt_lot'],
    fields: ['sqr_mt_front', 'sqr_mt_long'],
  },
  {
    id: 15,
    name: 'bodegas',
    value: 'cellar',
    requiredFields: ['sqr_mt_lot', 'sqr_mt_construction'],
    fields: ['sqr_mt_cellar', 'sqr_mt_office'],
  },
  {
    id: 39,
    name: 'consultorios',
    value: 'consultingRoom',
    requiredFields: [],
    fields: ['parking', 'sqr_mt_lot', 'sqr_mt_construction', 'bathrooms'],
  },
  {
    id: 20,
    name: 'edificios',
    value: 'building',
    requiredFields: ['bathrooms'],
    fields: ['parking', 'age', 'levels', 'sqr_mt_lot', 'sqr_mt_construction'],
  },
  {
    id: 21,
    name: 'granjas',
    value: 'farm',
    requiredFields: [],
    fields: [
      'rooms',
      'bathrooms',
      'parking',
      'age',
      'levels',
      'sqr_mt_lot',
      'sqr_mt_construction',
    ],
  },
  {
    id: 22,
    name: 'haciendas',
    value: 'farm',
    requiredFields: [],
    fields: [
      'rooms',
      'bathrooms',
      'parking',
      'age',
      'levels',
      'sqr_mt_lot',
      'sqr_mt_construction',
    ],
  },
  {
    id: 23,
    name: 'hoteles',
    value: 'hotel',
    requiredFields: [],
    fields: [
      'rooms',
      'bathrooms',
      'parking',
      'age',
      'levels',
      'sqr_mt_lot',
      'sqr_mt_construction',
    ],
  },
  {
    id: 26,
    name: 'locales',
    value: 'shop',
    requiredFields: ['bathrooms'],
    fields: [
      'parking',
      'sqr_mt_lot',
      'sqr_mt_construction',
      'sqr_mt_front',
      'sqr_mt_long',
    ],
  },
  {
    id: 28,
    name: 'oficinas',
    value: 'office',
    requiredFields: ['bathrooms'],
    fields: [
      'rooms',
      'age',
      'parking',
      'sqr_mt_lot',
      'sqr_mt_construction',
      'sqr_mt_front',
      'sqr_mt_long',
    ],
  },
  {
    id: 29,
    name: 'parques industriales',
    value: 'industrialPark',
    requiredFields: [],
    fields: [
      'sqr_mt_lot',
      'sqr_mt_construction',
      'sqr_mt_cellar',
      'sqr_mt_office',
    ],
  },
  {
    id: 31,
    name: 'predios rústicos',
    value: 'rustic',
    requiredFields: [],
    fields: [
      'rooms',
      'bathrooms',
      'parking',
      'age',
      'levels',
      'sqr_mt_long',
      'sqr_mt_construction',
    ],
  },
  {
    id: 33,
    name: 'ranchos',
    value: 'ranch',
    requiredFields: ['bathrooms', 'rooms'],
    fields: ['parking', 'age', 'sqr_mt_lot', 'sqr_mt_construction'],
  },
  {
    id: 45,
    name: 'nave industrial',
    value: 'nave-industrial',
    requiredFields: [],
    fields: [
      'sqr_mt_lot',
      'sqr_mt_construction',
      'sqr_mt_cellar',
      'sqr_mt_office',
    ],
  },
  {
    id: 43,
    name: "Salón de Eventos",
    value: "eventroom",
    requiredFields: [],
    fields: [
      "sqr_mt_lot",
      "sqr_mt_construction",
      "parking",
      "bathrooms",
    ],
  },
]

const fields = {
  parking: 'Estacionamientos',
  age: 'Antiguedad en años',
  sqr_mt_lot: 'Metros cuadrados de terreno',
  sqr_mt_construction: 'Metros cuadrados de construcción',
  bathrooms: 'Baños',
  rooms: 'Habitaciones',
  half_bathrooms: 'Medios baños',
  levels: 'Número de pisos',
  floors: 'Número de Pisos',
  floor_number: 'Piso en que se encuentra',
  sqr_mt_office: 'Metros cuadrados de oficina',
  sqr_mt_cellar: 'Metros cuadrados de bodega',
  sqr_mt_front: 'Metros de frente',
  sqr_mt_long: 'Metros de fondo',
}

const propertyConstants = {
  fields,
  propertyTypes,
}

export default propertyConstants
