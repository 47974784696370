import axios from 'axios'
import { fetchHeaders } from './utils'

export const GeneralAPI = {
  getPropertyType,
  getStates,
  getMunicipality,
  getNeighborhoods,
  getDevelops,
  getPrototypes,
  downloadPDF,
  downloadInventoryPDF,
}

const { api: API_URL } = process.env.ENV

async function getPropertyType() {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}property_type`,
  })
    .then((response) => {
      let { data } = response

      return data
    })
    .catch((error) => {
      console.log(error.response)
    })
}

async function getStates() {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}locations/state/`,
  })
    .then((response) => {
      let { data } = response

      return data.results
    })
    .catch((error) => {
      console.log(error.response)
    })
}

async function getMunicipality(stateID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}locations/municipality/?state_id=${stateID}`,
  })
    .then((response) => {
      let { data } = response

      return data.results
    })
    .catch((error) => {
      console.log(error.response)
    })
}

async function getNeighborhoods(municipalityID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}locations/neighborhood/?municipality=${municipalityID}`,
  })
    .then((response) => {
      let { data } = response

      return data
    })
    .catch((error) => {
      console.log(error.response)
    })
}

async function getDevelops() {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}real_estate_development_list/`,
  })
    .then((response) => {
      let { data } = response

      return data
    })
    .catch((error) => {
      console.log(error.response)
    })
}

async function getPrototypes(developID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}real_estate_development/${developID}/prototypes`,
  })
    .then((response) => {
      let { data } = response

      return data
    })
    .catch((error) => {
      console.log(error.response)
    })
}

async function downloadPDF(propertyID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    responseType: 'arraybuffer',
    method: 'GET',
    url: `${API_URL}reports/property_pdf/${propertyID}`,
  })
    .then((response) => {
      let { data } = response

      return data
    })
    .catch((error) => {
      console.log(error.response)
    })
}

async function downloadInventoryPDF(propertyID) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    responseType: 'arraybuffer',
    method: 'GET',
    url: `${API_URL}reports/property_pdf_inventory/${propertyID}`,
  })
    .then((response) => {
      let { data } = response

      return data
    })
    .catch((error) => {
      console.log(error.response)
    })
}
