import React, { forwardRef, useEffect, useState } from 'react'
import { Upload, Button } from 'antd'
import { imageToObject } from '../../Shared/utils'

const ClientLogo = ({ ...props }, ref) => {
  const [tgtImage, setTgtImage] = useState({})
  const { onChange, value } = props

  const addToList = (event) => {
    setTgtImage({ ...event.file })
  }

  const formatImageList = (item, file, fileExt) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        item.name = file.name
        item.base64 = event.target.result
        item.format = fileExt[1] === 'jpeg' ? 'jpg' : fileExt[1]
        item.content_type = file.type
        resolve(item)
      }
    })
  }

  const downloadImage = async (item) => {
    const fetchFile = await fetch(item)
    const fileBlob = await fetchFile.blob()
    const file = new File([fileBlob], '', { type: fileBlob.type })
    const fileExt = fileBlob.type.split('/')
    const newItem = await formatImageList({}, file, fileExt)
    return newItem
  }

  useEffect(() => {
    const getImage = async () => {
      const logo = await downloadImage(value)
      setTgtImage({ ...logo })
    }
    if (typeof value === 'string') {
      getImage()
    }
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(tgtImage)
    }
  }, [tgtImage])

  return (
    <div ref={ref} className="text-center my-4">
      <Upload
        accept=".jpg,.jpeg,.png"
        showUploadList={false}
        customRequest={addToList}
        transformFile={imageToObject}
      >
        <Button>Subir logotipo</Button>
      </Upload>
    </div>
  )
}

export default forwardRef(ClientLogo)
