import React, { Fragment, useEffect } from 'react'
import { Form, InputNumber, Col } from 'antd'

const { Item: FormItem } = Form

const PropertyIndustrialPlant = (props) => {
  const { form, initialvalues, ...rest } = props
  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    if (initialvalues) {
      const {
        sqr_mt_lot,
        sqr_mt_construction,
        sqr_mt_cellar,
        sqr_mt_office,
      } = initialvalues
      setFieldsValue({
        sqr_mt_lot,
        sqr_mt_construction,
        sqr_mt_cellar,
        sqr_mt_office,
      })
    }
  }, [initialvalues])

  return (
    <Fragment>
      <Col {...rest}>
        <FormItem label="Terreno en &#13217;">
          {getFieldDecorator('sqr_mt_lot', {
            rules: [
              {
                required: true,
                message: 'Indica el total de metros cuadrados',
              },
            ],
          })(
            <InputNumber
              min={1}
              style={{
                width: '100%',
              }}
            />
          )}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Construcción en &#13217;">
          {getFieldDecorator('sqr_mt_construction', {
            rules: [
              {
                required: true,
                message: 'Indica el total de metros cuadrados',
              },
            ],
          })(
            <InputNumber
              min={1}
              style={{
                width: '100%',
              }}
            />
          )}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Bodega en &#13217;">
          {getFieldDecorator(
            'sqr_mt_cellar',
            {}
          )(
            <InputNumber
              min={1}
              style={{
                width: '100%',
              }}
            />
          )}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Oficina en &#13217;">
          {getFieldDecorator(
            'sqr_mt_office',
            {}
          )(
            <InputNumber
              min={1}
              style={{
                width: '100%',
              }}
            />
          )}
        </FormItem>
      </Col>
    </Fragment>
  )
}

export default PropertyIndustrialPlant
