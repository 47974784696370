import React, { Fragment } from 'react'
import { Row, Col, Form } from 'antd'

const { Item: FormItem } = Form

const StatisticProperty = (props) => {
  const { number_views, number_contacts, ctr } = props.property

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <FormItem
            label="Total de veces vista"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light">{number_views}</span>
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            label="Total de contactos"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light">{number_contacts}</span>
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label="CTR" className="font-weight-bold mb-0" colon={false}>
            <span className="font-weight-light">{ctr}</span>
          </FormItem>
        </Col>
      </Row>
    </Fragment>
  )
}

export default StatisticProperty
