import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Upload, Modal, Button } from 'antd'

const { Item: FormItem } = Form

const MultimediaDetailsProperty = (props) => {
  const { images } = props.data || ''
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    const newFileList = Object.keys(images).map((key) => {
      let url = images[key].url
      let uid = images[key].id
      return { uid, url }
    })
    setFileList(newFileList)
  }, [])

  const handlePreview = async (file) => {
    setPreviewImage(file.url)
    setPreviewVisible(true)
  }

  const handleCancel = () => setPreviewVisible(false)
  const handleBeforeUpload = () => false

  return (
    <div className="mx-4 my-4">
      <Row type="flex" justify="center" className="mb-3">
        <Col>
          <FormItem
            label="Imagenes"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="d-block mt-2">
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                beforeUpload={handleBeforeUpload}
                showUploadList={{
                  showPreviewIcon: true,
                  showDownloadIcon: false,
                  showRemoveIcon: false,
                }}
              />
            </span>
          </FormItem>
        </Col>

        <Modal
          visible={previewVisible}
          centered
          closable={false}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
          <Row type="flex" justify="end" className="mt-2">
            <Col>
              <Button type="primary" onClick={handleCancel}>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal>
      </Row>
    </div>
  )
}

export default MultimediaDetailsProperty
