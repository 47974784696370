import React, { Fragment, useEffect } from 'react'
import { Form, InputNumber, Checkbox, Col } from 'antd'

// Utils
import { validateIntegers } from '../../Shared/utils'

const { Item: FormItem } = Form

const PropertyRanch = (props) => {
  const { form, initialvalues, ...rest } = props
  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    if (initialvalues) {
      const {
        rooms,
        bathrooms,
        parking,
        age,
        levels,
        service_rooms,
        sqr_mt_lot,
        sqr_mt_construction,
      } = initialvalues
      setFieldsValue({
        rooms,
        bathrooms,
        parking,
        age,
        levels,
        service_rooms,
        sqr_mt_lot,
        sqr_mt_construction,
      })
    }
  }, [initialvalues])

  return (
    <Fragment>
      <Col {...rest}>
        <FormItem label="Habitaciones">
          {getFieldDecorator('rooms', {
            rules: [
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={1} style={{ width: '100%' }} />)}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Baños">
          {getFieldDecorator('bathrooms', {
            rules: [
              {
                required: true,
                message: 'Indica el total de baños',
              },
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={1} style={{ width: '100%' }} />)}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Estacionamientos">
          {getFieldDecorator('parking', {
            rules: [
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber style={{ width: '100%' }} />)}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Antiguedad (en años)">
          {getFieldDecorator(
            'age',
            {}
          )(<InputNumber min={1} style={{ width: '100%' }} />)}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Número de pisos">
          {getFieldDecorator('levels', {
            rules: [
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={1} max={50} style={{ width: '100%' }} />)}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Cuarto de servicio">
          {getFieldDecorator('service_rooms', {
            valuePropName: 'checked',
          })(<Checkbox />)}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Terreno en &#13217;">
          {getFieldDecorator('sqr_mt_lot', {
            rules: [
              {
                required: true,
                message: 'Indica el total de metros cuadrados',
              },
            ],
          })(<InputNumber min={1} style={{ width: '100%' }} />)}
        </FormItem>
      </Col>
      <Col {...rest}>
        <FormItem label="Construcción en &#13217;">
          {getFieldDecorator('sqr_mt_construction', {
            rules: [
              {
                required: true,
                message: 'Indica el total de metros cuadrados',
              },
            ],
          })(<InputNumber min={1} style={{ width: '100%' }} />)}
        </FormItem>
      </Col>
    </Fragment>
  )
}

export default PropertyRanch
