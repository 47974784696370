import React from 'react'
import { useHistory } from 'react-router-dom'
import CircleSteps from '../../Components/Navigation/CircleSteps'
import ProgressBar from '../../Components/Navigation/ProgressBar'
import Loading from '../../Components/Loading'
import DescriptionForm from './form'
import useAcquisitionUser from '../../Hooks/useAcquisitionUser'
import getAcquisitionToken from '../../Utils/getAcquisitionToken'

const particularSteps = [
  {
    name: 'Tipo de cuenta',
    description: 'Particular',
    href: '#',
    status: 'complete',
  },
  {
    name: 'Ubicación y precio',
    description: 'Define el tipo de inmueble y operación',
    href: '#',
    status: 'complete',
  },
  {
    name: 'Amenidades',
    description: '¿Qué pueden encontrar en la propiedad?',
    href: '#',
    status: 'complete',
  },
  {
    name: 'Descripción y fotos',
    description: 'Detalles más específicos',
    href: '#',
    status: 'current',
  },
  {
    name: 'Pago',
    description: 'Contrata tu plan y accede a PRO',
    href: '#',
    status: 'upcoming',
  },
]

export default function Index() {
  const history = useHistory()

  const token = getAcquisitionToken()
  if (!token) {
    history.push('/unirse')
    return (<Loading />)
  }

  const { data: user, error } = useAcquisitionUser(token?.uuid)

  // redirect preconditions
  if (!user && !error) {
    return (<Loading />)
  }

  if (!user && error) {
    history.push('/unirse')
    return (<Loading text="Hubo un error cargando el usuario, por favor intenta de nuevo." />)
  }

  if (user.status === 'paid') {
    history.push('/unirse/confirmar-datos')
    return (<Loading text="Tu pago fue procesado con éxito, te estamos redirigiendo..." />)
  } else if (user.status === 'finished') {
    history.push('/unirse/resumen')
    return (<Loading text="Ya casi finalizas el proceso, te estamos redirigiendo..." />)
  }

  return (
    <div className="relative">
      <div className="md:hidden bg-gray-100 p-3 space-y-1 ">
        <p className="text-center leading-4 font-semibold uppercase">
          Descripción y fotos
        </p>
        <p className="text-center">Detalles más específicos</p>
        <ProgressBar
          config={{
            step: 2,
            total: 5,
          }}
        />
      </div>
      <div className="md:grid grid-cols-3  ">
        <div className="min-h-screen hidden md:block col-span-1 bg-gray-100 pt-16 ">
          <div className="block w-3/4 m-auto space-y-3">
            <p className="text-5xl text-black leading-none font-extrabold">
              Publica en Casas y Terrenos
            </p>
            <p className="text-lg leading-6 font-semibold tracking-wider uppercase">
              Toma al rededor de 7 minutos
            </p>
            <div className="hidden md:block">
              <CircleSteps steps={particularSteps} />
            </div>
          </div>
        </div>
        <div className="col-span-2 block ">
          <div className="flex md:min-h-screen w-full md:w-3/4 lg:w-2/3 m-auto pt-16 px-5 ">
            <DescriptionForm />
          </div>
        </div>
      </div>
    </div>
  )
}
