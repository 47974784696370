import React from 'react'
import { Row, Col, Form } from 'antd'

const { Item: FormItem } = Form

const FeaturesDetailsForm = (props) => {
  const {
    bathrooms,
    half_bathrooms,
    rooms,
    parking,
    floor_number,
    service_rooms,
    laundry,
    sqr_mt_construction,
    sqr_mt_lot,
    age,
    cellar,
    floors,
  } = props.data

  return (
    <div className="m-4">
      <Row type="flex" justify="start" gutter={48}>
        <Col xs={12}>
          <FormItem
            label="Baños"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {bathrooms}
            </span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Medios baños"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {half_bathrooms}
            </span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Habitaciones"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">{rooms}</span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Estacionamiento"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {parking}
            </span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Pisos"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {floors}
            </span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Número de piso"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {floor_number}
            </span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Años"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">{age}</span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Lavandería"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {laundry}
            </span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Área de construcción"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {sqr_mt_construction}m²
            </span>
          </FormItem>
        </Col>

        <Col xs={12}>
          <FormItem
            label="Áreao del terreno"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light d-block lh-6 mb-2">
              {sqr_mt_lot}m²
            </span>
          </FormItem>
        </Col>

        <Col xs={24} className="mt-2">
          {service_rooms ? (
            <span className="font-weight-light d-block mb-1">
              - Cuenta con servicio de cuarto.
            </span>
          ) : null}
          {cellar ? (
            <span className="font-weight-light d-block mb-1">
              - Cuenta con cava.
            </span>
          ) : null}
        </Col>
      </Row>
    </div>
  )
}

export default FeaturesDetailsForm
