import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Icon, Layout, Menu, Dropdown, Row, Col } from 'antd'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'

//Own Components
import LogoCYT from '../Shared/Images/logo-cyt-pro-white.png'
import UserIcon from '../Shared/Icons/User'

// Services
import AccountPermissions from '../Services/auth'

// Custom hooks
import { useWindowWidthSize } from '../Shared/customHooks'

const { Header, Footer, Sider, Content } = Layout
const { Item: MenuItem } = Menu

const HeaderStyled = styled(Header)`
  background: #fff;
  padding: 0 2em;
  text-align: right;
`

const MenuItemDropdownStyled = styled(MenuItem)`
  &:hover {
    background-color: rgb(9, 32, 86) !important;
  }

  &:hover .ant-dropdown-link {
    color: #fff;
  }
`

const MenuDropdownStyled = styled(Menu)`
  &.ant-dropdown-menu {
    border-radius: 0;
    padding: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.45);
  }
`

const MenuItemSiderStyled = styled(MenuItem)`
  &.ant-menu-item-selected {
    background-color: rgb(9, 32, 86) !important;
  }
  &:hover {
    padding-left: 45px !important;
  }
`

const MainLayout = withRouter(({ history, ...props }) => {
  const { children } = props
  const widthSize = useWindowWidthSize()
  const [userInfo, setUserInfo] = useState(null)
  const [responsiveMenu, setResponsiveMenu] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const [menuElements, setMenuElement] = useState([
    <MenuItemSiderStyled key="1">
      <Link to="/contacts">
        <Icon type="contacts" style={{ fontSize: '1.1rem' }} />
        <span>Contactos</span>
      </Link>
    </MenuItemSiderStyled>,
    <MenuItemSiderStyled key="2">
      <Link to="/properties">
        <Icon type="home" style={{ fontSize: '1.1rem' }} />
        <span>Propiedades</span>
      </Link>
    </MenuItemSiderStyled>,
    <MenuItemSiderStyled key="4">
      <Link to="/reports">
        <Icon type="file-text" style={{ fontSize: '1.1rem' }} />
        <span>Reportes</span>
      </Link>
    </MenuItemSiderStyled>,
    <MenuItemSiderStyled key="5">
      <Link to="/inventory-cyt">
        <Icon type="file-search" style={{ fontSize: '1.1rem' }} />
        <span>Inventario CYT</span>
      </Link>
    </MenuItemSiderStyled>,
    <MenuItemSiderStyled key="8">
      <Link to="/faq">
        <span>Preguntas Frecuentes</span>
      </Link>
    </MenuItemSiderStyled>,
    <MenuItemSiderStyled key="9">
      <Link to="/contact-us">
        <span>Contáctanos</span>
      </Link>
    </MenuItemSiderStyled>,
    <MenuItemSiderStyled key="10">
      <Link to="/account">
        <Icon type="user" style={{ fontSize: '1.1rem' }} />
        <span>Cuenta</span>
      </Link>
    </MenuItemSiderStyled>,
  ])

  const integrationAccess = AccountPermissions.accessGranted(
    'integrations',
    'list'
  )
  const promoterAccess = AccountPermissions.accessGranted('memberships', 'list')

  useEffect(() => {
    if (widthSize <= 1024) {
      setResponsiveMenu(true)
      setCollapsed(true)
    } else {
      setResponsiveMenu(false)
      setCollapsed(false)
    }
  }, [widthSize])

  useEffect(() => {
    if (promoterAccess) {
      menuElements.splice(
        3,
        0,
        <MenuItemSiderStyled key="3">
          <Link to="/promoter">
            <Icon type="user" style={{ fontSize: '1.1rem' }} />
            <span>Promotores</span>
          </Link>
        </MenuItemSiderStyled>
      )
      setMenuElement([...menuElements])
    }
  }, [promoterAccess])

  useEffect(() => {
    if (integrationAccess) {
      menuElements.push(
        <MenuItemSiderStyled key="7">
          <Link to="/integrations">
            <Icon type="block" style={{ fontSize: '1.1rem' }} />
            <span>Integraciones</span>
          </Link>
        </MenuItemSiderStyled>,
        <MenuItemSiderStyled key="11">
          <Link to="/recibos">
            <span>Recibos</span>
          </Link>
        </MenuItemSiderStyled>
      )
      setMenuElement([...menuElements])
    }
  }, [integrationAccess])

  const fetchUserInfo = async () => {
    const dataUserInfo = await Auth.currentAuthenticatedUser()
    const { attributes: currentUser } = dataUserInfo
    setUserInfo(currentUser)
  }

  useEffect(() => {
    fetchUserInfo()
    return () => setUserInfo(null)
  }, [])

  const handleClickLogout = async () => {
    AccountPermissions.clearPermissions()
    Auth.signOut()
    history.push('/')
  }

  const handleRedirectProfile = () => {
    history.push('/account/')
  }

  const toggleMenuSide = () => {
    setCollapsed(!collapsed)
  }

  const handleClickMenu = () => {
    if (widthSize <= 1024) setCollapsed(true)
  }

  const renderUserDropdown = () => {
    return (
      <>
        {userInfo ? (
          <Dropdown overlay={UserMenu} overlayClassName="">
            <a
              className="ant-dropdown-link"
              className="float-right d-inline-block"
              style={{ width: '200px' }}
            >
              <UserIcon
                style={{ width: '14px', top: '-1px' }}
                className="position-relative mr-1"
              />
              {userInfo.name} {userInfo.family_name}
              <Icon type="down" className="ml-1" />
            </a>
          </Dropdown>
        ) : (
          <></>
        )}
      </>
    )
  }

  const UserMenu = () => (
    <MenuDropdownStyled style={{ top: '-4px' }}>
      <MenuItemDropdownStyled key={0}>
        <a className="ant-dropdown-link" onClick={handleRedirectProfile}>
          <Icon type="user" className="mr-2" /> Mi perfil
        </a>
      </MenuItemDropdownStyled>
      <MenuItemDropdownStyled key={1}>
        <a className="ant-dropdown-link" onClick={handleClickLogout}>
          <Icon type="poweroff" className="mr-2" /> Cerrar sesión
        </a>
      </MenuItemDropdownStyled>
    </MenuDropdownStyled>
  )

  return (
    <Layout>
      <Sider
        collapsed={collapsed}
        collapsible
        collapsedWidth="0"
        style={{
          overflow: 'auto',
          minHeight: '100vh',
        }}
        breakpoint="lg"
      >
        <div className="px-3 py-1">
          <Link to="/">
            <img
              src={LogoCYT}
              alt="Logo Casas y Terrenos"
              className="img-fluid m-2"
            />
          </Link>
        </div>
        <Menu
          mode="inline"
          theme="dark"
          style={{ background: 'rgb(2, 0, 36)' }}
          onClick={handleClickMenu}
        >
          {menuElements}
        </Menu>
      </Sider>

      <Layout>
        <HeaderStyled>
          <Row type="flex" justify="space-between">
            <Col>
              <Icon
                className="trigger"
                type={collapsed ? 'bars' : 'menu-fold'}
                onClick={toggleMenuSide}
                style={{ fontSize: '1.3rem' }}
              />
            </Col>
            <Col>
              {collapsed && responsiveMenu && renderUserDropdown()}
              {!responsiveMenu && renderUserDropdown()}
            </Col>
          </Row>
        </HeaderStyled>
        <Content className="">{children}</Content>
        <Footer className="text-center">casasyterrenos.com</Footer>
      </Layout>
    </Layout>
  )
})

export default MainLayout
