import React, { useState, useEffect } from 'react'
import { useField } from 'formik'

export default function NumberIncrement(props) {
  const { label } = props
  const [field, meta, helpers] = useField(props.name)
  const { value } = meta
  const { setValue } = helpers

  const decrement = (n) => {
    if (n !== 0) {
      setValue(n - 1)
    }
  }

  const increment = (n) => {
    setValue(n + 1)
  }

  return (
    <div className="flex  items-center  p-3">
      <div className="flex-grow grid grid-cols-3">
        <div className="flex-grow col-span-2">{label}</div>
        <div className="flex-grow col-span-1 flex text-center items-center ">
          <button
            type="button"
            className="text-white font-bold flex-none bg-yellow-500 w-10 h-10 rounded-full"
            onClick={() => decrement(value)}
          >
            -
          </button>
          <div className="flex-grow font-bold ">{value}</div>
          <button
            type="button"
            className="text-white font-bold flex-none bg-yellow-500 w-10 h-10 rounded-full"
            onClick={() => increment(value)}
          >
            +
          </button>
        </div>
      </div>
    </div>
  )
}

NumberIncrement.defaultProps = {
  callBack: (e) => console.log(e),
  label: '',
}
