import React, { useEffect } from 'react'
import Card from '../Components/Card'
import UseAnalytics from '../Hooks/useAnalytics'
import UseContacts from '../Hooks/useContacts'

function Index() {
  const { data: whatsappData, error: whatsappError } = UseAnalytics({
    event_type: 'click',
    event_value: 'contact_whatsapp',
  })

  const { data: callData, error: callError } = UseAnalytics({
    event_type: 'click',
    event_value: 'contact_call',
  })

  const { data: contactsData, error: contactsError } = UseContacts({
    status: '1',
  })

  return (
    <div>
      <Card
        title="Intentos de contacto por Whatsapp"
        subtitle={whatsappData?.count}
      />
      <Card
        title="Intentos de contacto por llamada"
        subtitle={callData?.count}
      />
      <Card title="Mensajes activos" subtitle={contactsData?.count} />
    </div>
  )
}

export default Index
