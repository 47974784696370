import React, { useState, useEffect } from 'react'
import LogoCYT from '../../Shared/Images/logo-cyt-pro-white.png'
import getAcquisitionToken from '../../Utils/getAcquisitionToken'
import useAcquisitionUser from '../../Hooks/useAcquisitionUser'

const WEBSITE_URL = process.env.ENV.portal

export default function Index() {
  const [name, setName] = useState('Alejandra')
  const [url, setUrl] = useState('')
  const token = getAcquisitionToken()
  const { data: user, error } = useAcquisitionUser(token.uuid)

  useEffect(() => {
    if (user?.account_type === 'direct') {
      setUrl(localStorage.getItem('cytProperyUrl'))
    }
  }, [user])

  return (
    <div className="relative">
      <div className="md:hidden bg-blue-800 text-white p-3 space-y-1 pb-10">
        <img
          src={LogoCYT}
          alt="Logo Casas y Terrenos"
          className="max-w-xs m-auto mb-10"
        />
        <p className="text-4xl leading-10 font-extrabold uppercase">
          Te damos la bienvenida,{' '}
          <span className="capitalize">
            {user?.name && `${user.name} ${user.last_name}`}
          </span>
        </p>
      </div>
      <div className="md:grid grid-cols-3  ">
        <div className="min-h-screen hidden md:block col-span-1 text-white bg-blue-800 pt-16  ">
          <div className="block w-3/4 m-auto space-y-3">
            <img
              src={LogoCYT}
              alt="Logo Casas y Terrenos"
              className="max-w-xs  mb-10"
            />
            <p className="text-5xl  leading-none font-extrabold ">
              Te damos la bienvenida,{' '}
              <span className="capitalize">
                {user?.name && `${user.name} ${user.last_name}`}
              </span>
            </p>
          </div>
        </div>
        <div className="col-span-2 block ">
          <div className=" md:min-h-screen w-full md:w-3/4 lg:w-2/3 m-auto pt-16 px-5 ">
            {user?.account_type === 'direct' ? (
              <WelcomeIndividual url={WEBSITE_URL + url} />
            ) : (
              <WelcomePro />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const WelcomeIndividual = ({ url }) => (
  <div className="space-y-5">
    <p className="text-xl leading-7">¡Tu propiedad ha sido publicada!</p>

    <div>
      <p className="mb-2">Puedes verla aquí</p>
      <a href={url || ''}>
        <button className="button-blue-xl">Ver publicación</button>
      </a>
    </div>
    <div>
      <p className="mb-2">O ver tus contactos aquí</p>
      <a href="https://pro-beta.casasyterrenos.com/login">
        <button className="button-blue-xl">Ver contactos</button>
      </a>
    </div>
  </div>
)
const WelcomePro = () => (
  <div className="space-y-5">
    <p className="text-xl leading-7">¡Ahora eres PRO!</p>

    <div>
      <p className="mb-2">Empieza a publicar</p>
      <a href="https://pro-beta.casasyterrenos.com/login">
        <button className="button-blue-xl">Ingresa a tu cuenta</button>
      </a>
    </div>
  </div>
)
