import axios from 'axios'
import { Auth } from 'aws-amplify'

function cleanJSON(data) {
  if (data) {
    Object.keys(data).forEach((key) => {
      ;(data[key] === undefined && delete data[key]) ||
        (data[key] === '' && delete data[key]) ||
        (data[key] === null && delete data[key])
    })
  }
  return data
}

axios.interceptors.request.use(
  function (config) {
    const { method, data, url } = config
    if (['post', 'put', 'patch'].includes(method)) {
      if (
        url.startsWith('https://cytpanel.casasyterrenos.com/api/v1/property')
      ) {
        console.log('Property Request:', config)
        console.log('Data Property Request: ', config.data)
      }
      config.data = cleanJSON(data)
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status } = error.response
      let response = {
        message: '',
        success: false,
      }
      if (status === 500) {
        response.message =
          '¡Oops! Ha ocurrido un error en el servidor. Intenta más tarde.'
        return Promise.reject(response)
      }
    }
    return Promise.reject(error)
  }
)

export const fetchHeaders = async () => {
  const session = await Auth.currentSession()

  return {
    'content-type': 'application/json',
    authorization: `Bearer ${session.idToken.jwtToken}`,
  }
}
