import React from 'react'
import useSWR from 'swr'
import apiClient from '../Services/apiClient'

function useNeighborhoods(municipalityID) {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data)

  const { data, error } = useSWR(
    municipalityID
      ? `/locations/neighborhood/?municipality=${municipalityID}`
      : null,
    fetcher
  )

  const loading = !data && !error

  const clean = React.useMemo(() => {
    if (data) {
      const ids = data.map((o) => o.name)
      return data.filter(({ name }, index) => !ids.includes(name, index + 1))
    }
  }, [data])

  return { data: clean, error, loading }
}

export default useNeighborhoods
