import React, { useState, useEffect, Fragment } from 'react'
import {
  Layout,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Row,
  Col,
  message,
  Result,
} from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'

// Own components
// import LogoCYT from '../Shared/Images/logo-black-cyt.png'
import LogoCYT from '../Shared/Images/logo-blue.svg'
import ModalStyled from '../Shared/Common/Modal'

// Services
import { AccountAPI } from '../Services/account'

const { Content } = Layout
const { Item: FormItem } = Form

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  background-image: url('https://www.casasyterrenos.com/static/img/home/splash.jpg');
  background-repeat: no-repeat;
  background-size: cover;
`

const ContentStyled = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(8, 50, 115, 0.7);
`

const ResetPassword = withRouter(({ history, ...props }) => {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form
  const { notification } = props
  const [widthCard, setWidthCard] = useState(null)
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [userDataAccess, setUserDataAccess] = useState(null)
  const [textModal, setTextModal] = useState('')

  const toggleModal = () => setVisibleModal(!visibleModal)

  const comparePasswords = (rule, value, callback) => {
    if (value && value !== getFieldValue('newPassword')) {
      callback('Las contraseñas no coinciden')
    }
    callback()
  }

  useEffect(() => {
    if (notification) {
      message.error(notification)
    }
  }, [notification])

  useEffect(() => {
    function handleResize() {
      let validation = window.innerWidth <= 768
      if (validation) {
        setWidthCard('90%')
      } else {
        setWidthCard('30%')
      }
    }

    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }) // Empty array ensures that effect is only run on mount and unmount

  const handleSubmitLogin = async (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        if (!resetPassword) {
          const response = await AccountAPI.sendCodeResetPassword(values)
          if (response.hasOwnProperty('Error')) {
            const { Error } = response
            setTextModal(Error)
          } else setResetPassword(true)

          setVisibleModal(true)
        } else {
          const response = await AccountAPI.resetPassword(values)
          if (response.hasOwnProperty('Error')) {
            const { Error } = response
            setTextModal(Error)
            setResetPassword(false)
            setVisibleModal(true)
          } else {
            const { username, password } = values
            setVisibleSuccessModal(true)
            setUserDataAccess({
              username,
              password,
            })
          }
        }
      }
    })
  }

  const redirectMain = async () => {
    try {
      const { username, password } = userDataAccess || ''
      await Auth.signIn(username, password)
      history.push('/')
    } catch (error) {
      const { message: response } = error
      message.error(response)
    }
  }

  return (
    <LayoutStyled>
      <ContentStyled>
        <Card bordered={false} style={{ width: widthCard }} className="rounded">
          <Form
            onSubmit={handleSubmitLogin}
            className="login-form"
            colon={false}
          >
            <Row>
              <Col xs={24}>
                <img
                  src={LogoCYT}
                  className="w-full"
                  alt="Logotipo Casas y Terrenos"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <h3 className="text-center text-dark font-weight-bold">
                  Reestablecer la contraseña
                </h3>
                <FormItem label="Correo electrónico">
                  {getFieldDecorator('username', {
                    /* rules: [
                      { required: true, message: "Ingresa tu correo electrónico" },
                      { type: "email", message: "Ingresa un correo electrónico válido."}
                    ] */
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="Correo electrónico"
                      disabled={resetPassword}
                    />
                  )}
                </FormItem>
              </Col>
              {resetPassword && (
                <Fragment>
                  <Col xs={24}>
                    <FormItem label="Código de verificación">
                      {getFieldDecorator('code', {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Escribe el código de verificacion.',
                          },
                        ],
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  <Col xs={24}>
                    <FormItem label="Nueva contraseña">
                      {getFieldDecorator('newPassword', {
                        rules: [
                          {
                            type: 'string',
                            required: true,
                            whitespace: true,
                            message: 'Por favor escriba una contraseña.',
                          },
                          {
                            min: 8,
                            required: true,
                            message:
                              'La contraseña debe de ser de mínimo 8 caracteres.',
                          },
                          {
                            required: true,
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                            message:
                              'La contraseña debe contener por lo menos un número y una letra mayúscula',
                          },
                        ],
                      })(<Input.Password />)}
                    </FormItem>
                  </Col>
                  <Col xs={24}>
                    <FormItem label="Confirmar nueva contraseña">
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            type: 'string',
                            required: true,
                            whitespace: true,
                            message: 'Por favor escriba la contraseña',
                          },
                          {
                            validator: comparePasswords,
                          },
                        ],
                      })(<Input.Password />)}
                    </FormItem>
                  </Col>
                </Fragment>
              )}
            </Row>
            <Row>
              <Col xs={24}>
                <Button type="primary" htmlType="submit" block>
                  Reestablecer contraseña
                </Button>
              </Col>
            </Row>
            <Row className="text-center mt-4">
              <Col xs={24}>
                <Link to="/contact-us">Contáctanos</Link>
              </Col>
              <Col xs={24} className="mt-2">
                <Link to="/">Ya tengo cuenta</Link>
              </Col>
            </Row>
          </Form>
        </Card>

        <ModalStyled
          visible={visibleModal}
          centered
          destroyOnClose
          onOk={toggleModal}
          okText="Entendido."
          cancelButtonProps={{ disabled: true, className: 'd-none' }}
          bodyStyle={{ paddingBottom: 0 }}
        >
          <Icon
            type={resetPassword ? 'mail' : 'warning'}
            theme="twoTone"
            twoToneColor={resetPassword ? '#0d47a1' : '#ff4d4f'}
            className="d-block mx-auto"
            style={{
              fontSize: '3.5em',
            }}
          />
          <h2 className="text-center my-4">
            {resetPassword ? '¡Revisa tu correo electrónico!' : '¡Oh, oh!'}
          </h2>
          {resetPassword ? (
            <Fragment>
              <p className="text-justify mb-1">
                Para poder <strong>reestablecer tu contraseña </strong>
                deberás teclear un código de verificación que será enviado a tu
                correo electrónico.
              </p>
              <p className="text-justify mb-1">
                Una vez que obtengas el código, da clic en el botón "Entendido"
                y sigue los pasos siguientes.
              </p>
            </Fragment>
          ) : (
            <p className="text-justify mb-1">{textModal}</p>
          )}

          <p className="text-justify mb-1">
            Para cualquier inconveniente, no dudes en{' '}
            <Link to="/contact-us">contáctarnos</Link>.
          </p>
        </ModalStyled>

        <ModalStyled
          visible={visibleSuccessModal}
          centered
          destroyOnClose
          onOk={redirectMain}
          okText="Entendido."
          cancelButtonProps={{ disabled: true, className: 'd-none' }}
          bodyStyle={{ paddingBottom: 0 }}
        >
          <Icon
            type="check-circle"
            theme="twoTone"
            twoToneColor="#0d47a1"
            className="d-block mx-auto"
            style={{
              fontSize: '3.5em',
            }}
          />
          <h2 className="text-center my-4">
            ¡Tu contraseña se ha actualizado correctamente!
          </h2>
          <p className="text-justify mb-2">
            Tus datos de acceso se han reestablecido. Serás redireccionado al
            portal al dar click en el botón "Entendido".
          </p>

          <p className="text-justify mb-1">
            Para cualquier inconveniente, no dudes en{' '}
            <Link to="/contact-us">contáctarnos</Link>.
          </p>
        </ModalStyled>
      </ContentStyled>
    </LayoutStyled>
  )
})

const ResetPasswordWrapper = Form.create()(ResetPassword)

export default ResetPasswordWrapper
