import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import splitbee from '@splitbee/web'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
//import ErrorBoundary from "./Shared/Common/ErrorBoundary";

Sentry.init({
  dsn:
    'https://75ea856a826a41b39daac5ae5d43a570@o268819.ingest.sentry.io/5664132',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
})

splitbee.init()

ReactDOM.render(<App />, document.getElementById('root'))

// No console log.
/* if (process.env.NODE_ENV === "production") {
  console.log = () => {}
} */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
