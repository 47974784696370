import React from 'react'
import {
  MailIcon,
  PhoneIcon,
  DocumentIcon,
  CheckCircleIcon,
  TrashIcon,
} from '@heroicons/react/solid'
import { message as antdMessage, Avatar } from 'antd'
import matchAll from 'string.prototype.matchall'
import downloadPropertyPdf from '../../Utils/downloadPdf'

const { portal: PORTALURL } = process.env.ENV

export default function LeadDetail({
  image,
  phone,
  email,
  message,
  propertyId,
  contactId,
  callBack,
  name,
  title,
}) {
  const dowload = async () => {
    antdMessage.loading('Descargando archivo. Puede tardar varios segundos.', 0)
    try {
      await downloadPropertyPdf(propertyId)
      antdMessage.destroy()
      antdMessage.success('El PDF se descargó éxitosamente.')
    } catch {
      antdMessage.destroy()
      antdMessage.error(
        'Ocurrió un error descargando el PDF. Intentalo de nuevo más tarde.'
      )
    }
  }

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  let initials = [...matchAll(name, rgx)] || []

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()

  return (
    <div className="md:mx-5 shadow bg-white">
      {/* init */}
      <div className="bg-white px-4 py-2 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Avatar
                  style={{
                    backgroundColor: '#f56a00',
                    verticalAlign: 'middle',
                  }}
                  size="large"
                  gap={2}
                >
                  {initials}
                </Avatar>
              </div>
              <div className="ml-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {name}
                </h3>
                <p>{phone}</p>
                <p>{email}</p>
              </div>
            </div>
          </div>
          <div className="md:ml-4 mt-4 md:flex-shrink-0 flex-shrink-1 flex">
            {phone && (
              <a href={`tel:${phone}`} data-splitbee-event="contacts-call-lead">
                <button
                  type="button"
                  className="relative inline-flex items-center  px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <PhoneIcon
                    className="-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="hidden md:block">Llamar</span>
                </button>
              </a>
            )}
            {email && (
              <a href={`mailto:${email}`} data-splitbee-event="contacts-email-lead">
                <button
                  type="button"
                  className="ml-3 relative inline-flex items-center px-4  py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <MailIcon
                    className="-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="hidden md:block">Enviar</span>
                </button>
              </a>
            )}
            <button
              type="button"
              data-splitbee-event="contacts-contacted-lead"
              onClick={() => callBack({ action: 'contacted', contactId })}
              className="ml-3 relative inline-flex items-center px-4  py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <CheckCircleIcon
                className="-ml-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
            <button
              type="button"
              data-splitbee-event="contacts-discarded-lead"
              onClick={() => callBack({ action: 'discarded', contactId })}
              className="ml-3 relative inline-flex items-center px-4  py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <TrashIcon
                className="-ml-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>

      {/* END */}

      {message.length > 0 && (
        <div className="bg-purple-50 w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
          {message}
        </div>
      )}

      <div className="grid md:grid-cols-2 gap-3 p-4">
        <img
          style={{ maxHeight: '300px' }}
          className="rounded-lg"
          src={image}
        ></img>

        <div className="">
          <a target="_blank" href={`${PORTALURL}/propiedad/${propertyId}`} data-splitbee-event="contacts-link-property-lead">
            <p className="mb-2 text-blue-600 hover:text-blue-800 hover:underline">
              {title}
            </p>
          </a>
          <button
            onClick={dowload}
            data-splitbee-event="contacts-download-pdf-lead"
            type="button"
            className=" relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <DocumentIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>Descargar PDF de la propiedad</span>
          </button>
        </div>
      </div>
    </div>
  )
}
