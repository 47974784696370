import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Steps, Button, Form, Icon, message } from 'antd'

// Own components
import ContainerLayout from '../../Layouts/ContainerLayout'
import PageHeaderStyled from '../../Shared/Common/PageHeader'
import GeneralCreateProperty from './GeneralCreateProperty'
import LocationCreateForm from '../../Shared/Common/LocationCreateForm'
import MultimediaCreateProperty from './MultimediaCreateProperty'
import AmenitiesCreateProperty from './AmenitiesCreateProperty'
import ModalStyled from '../../Shared/Common/Modal'

// Services
import { PropertyAPI } from '../../Services/property'

// Utils
import client from '../../Services/apiClient'
import { requiredFields } from '../Utils/'

const { Step } = Steps

const PropertiesCreate = (props) => {
  const history = useHistory()
  const { state } = history.location || {}
  const [currentStep, setCurrentStep] = useState(0)
  const [hiddenForm, setHiddenForm] = useState([false, true, true, true])
  const [loadingProperty, setLoadingProperty] = useState(false)
  const [visibleModal, setVisibleModal] = useState(true)

  const { form } = props
  const { validateFields, validateFieldsAndScroll } = form

  const steps = [
    {
      title: 'General',
    },
    {
      title: 'Ubicación',
    },
    {
      title: 'Amenidades',
    },
    {
      title: 'Multimedia',
    },
  ]

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
    hideShowSteps('next')
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
    hideShowSteps('prev')
  }

  const hideShowSteps = (prevOrNext) => {
    const newHiddenForm = hiddenForm.map((item, idx) => {
      let actualStep = prevOrNext === 'prev' ? currentStep - 1 : currentStep + 1
      if (idx === actualStep) {
        return false
      } else {
        return true
      }
    })

    setHiddenForm(newHiddenForm)
  }

  const resizeImage = (item) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        let multi
        if (img.naturalWidth > 1920 || img.naturalHeight > 1080) {
          if (img.naturalWidth > img.naturalHeight) {
            multi = 1080 / img.naturalHeight
          } else if (img.naturalHeight > img.naturalWidth) {
            multi = 1920 / img.naturalWidth
          } else {
            multi = 870 / img.naturalWidth
          }
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const tgWidth = img.naturalWidth * multi
          const tgHeight = img.naturalHeight * multi
          canvas.width = tgWidth
          canvas.height = tgHeight
          img.width = tgWidth
          img.height = tgHeight
          img.className = 'd-none'
          ctx.drawImage(img, 0, 0, tgWidth, tgHeight)
          item.base64 = canvas.toDataURL(item.content_type, 1.0)
          document.body.appendChild(img)
        }
        const strImg = item.base64.split(',')
        strImg.shift()
        strImg.join()
        item.base64 = strImg[0]
        delete item.name
        resolve(item)
      }
      img.onerror = () => reject()
      img.src = item.base64
    })
  }

  const formatImages = async (arrayImg) => {
    const promises = []
    const lenArrayImg = arrayImg.length

    for (let i = 0, len = lenArrayImg; i < len; i += 1) {
      promises.push(resizeImage(arrayImg[i]))
    }
    return Promise.all(promises)
  }

  const submitForm = (e) => {
    e.preventDefault()
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        Object.keys(errors).some((field) => {
          let { message: msgError } = errors[field].errors[0]
          if (requiredFields['firstForm'].includes(field)) {
            setHiddenForm([false, true, true, true])
            setCurrentStep(0)
          }
          if (requiredFields['secondForm'].includes(field)) {
            setHiddenForm([true, false, true, true])
            setCurrentStep(1)
          }
          message.error(msgError)
          return true
        })
      } else {
        const [lat, long] = values.latLng
        // values.images = await formatImages(values.images);
        values.latitude = lat.toString()
        values.longitude = long.toString()
        values.property_type = values.property_type.key
        values.state = values.state.key
        values.municipality = values.municipality.key
        values.colony = values.colony.key
        setLoadingProperty(true)

        let response = await PropertyAPI.createProperty(values)
        if (response) {
          history.replace('/properties/create', {
            lastStep: true,
            currentStep: 4,
            propertyID: response.id,
          })
        }

        setLoadingProperty(false)
      }
    })
  }

  const submitFormMultimedia = async (e) => {
    try {
      e.preventDefault()
      validateFields(async (errors, values) => {
        try {
          if (!errors) {
            if (values.images.length > 0) {
              values.images = values['images'].map((item) => {
                return { url: item.url }
              })
              await client.patch(`property/${state.propertyID}`, {
                images: values.images,
              })

              history.push('/properties', {
                msg: 'La propiedad se guardo correctamente.',
                success: true,
              })
            } else {
              message.error('Debes subir al menos una imagen')
            }
          }
        } catch {
          message.error('Hubo un error, intenta más tarde')
        }
      })
    } catch (err) {
      message.error('Hubo un error, intenta más tarde')
    }
  }

  useEffect(() => {
    if (history.location.state) {
      const { currentStep } = history.location.state || {}

      if (currentStep) {
        setCurrentStep(currentStep - 1)
      }
    }
  }, [history.location.state])

  const handleLoading = (loading) => {
    setLoadingProperty(loading)
  }

  const toggleModal = () => setVisibleModal(!visibleModal)

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <PageHeaderStyled
        title="Propiedades"
        subTitle="Crear propiedad"
        className="px-0 pt-0"
      />

      <Row type="flex" gutter={[16, 48]} className="mb-3">
        <Col xs={24}>
          <Steps current={currentStep}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
      </Row>

      {!state ? (
        <Form onSubmit={submitForm} className="login-form" colon={false}>
          <GeneralCreateProperty form={form} hidden={hiddenForm[0]} />
          <LocationCreateForm form={form} hidden={hiddenForm[1]} />
          <AmenitiesCreateProperty form={form} hidden={hiddenForm[2]} />

          <Row type="flex" justify="end" gutter={48}>
            <Col>
              {currentStep > 0 && (
                <Button className="mr-2" onClick={prevStep}>
                  Anterior
                </Button>
              )}
              {currentStep < 3 - 1 && (
                <Button type="primary" onClick={nextStep}>
                  Siguiente
                </Button>
              )}
              {currentStep === steps.length - 2 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingProperty}
                >
                  Siguiente
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <Form
          onSubmit={submitFormMultimedia}
          className="login-form"
          colon={false}
        >
          <MultimediaCreateProperty
            isLoading={handleLoading}
            form={form}
            propertyID={state.propertyID || {}}
          />

          <Row type="flex" justify="end" gutter={48}>
            <Col>
              {/* <Button className="mr-2" onClick={prevStep}>
                  Anterior
                </Button> */}
              <Button
                type="primary"
                htmlType="submit"
                disabled={loadingProperty}
              >
                Terminar y guardar imagenes.
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </ContainerLayout>
  )
}

const PropertiesCreateWrapper = Form.create()(PropertiesCreate)
export default PropertiesCreateWrapper
