import React, { useEffect } from 'react'
import { message } from 'antd'
import { Formik } from 'formik'
import { AccountAPI } from '../../Services/account'

export default function PersonalInformation(props) {
  const { data, callback } = props
  const { contact, email } = data
  const onSubmit = async (values) => {
    const { first_name, last_name, mobile, whatsapp } = values
    const format = {
      first_name,
      last_name,
      contact: {
        mobile,
        whatsapp,
      },
    }
    try {
      await AccountAPI.updateAccount(format)
      message.success('La información de contacto se actualizó')
      callback()
    } catch (err) {
      message.error(
        'Hubo un error al actualizar la información, intenta más tarde'
      )
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          first_name: data.first_name,
          last_name: data.last_name,
          mobile: contact.mobile,
          whatsapp: contact.whatsapp,
        }}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} data-splitbee-event="account-edit-contact-save">
            {errors.email && touched.email && errors.email}

            <div className="m-2">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre(s)
              </label>
              <input
                name="first_name"
                type="text"
                value={values.first_name}
                onChange={handleChange}
                className="m-1 p-1 shadow-sm sm:text-sm border border-gray-900 rounded-md w-full focus:ring focus:ring-blue-600 "
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Apellido
              </label>
              <input
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                type="text"
                className="m-1 p-1 shadow-sm sm:text-sm border border-gray-900 rounded-md w-full focus:ring focus:ring-blue-600 "
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-700"
              >
                Teléfono
              </label>
              <input
                value={values.mobile}
                onChange={handleChange}
                name="mobile"
                type="text"
                className="m-1 p-1 shadow-sm sm:text-sm border border-gray-900 rounded-md w-full focus:ring focus:ring-blue-600 "
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="whatsapp"
                className="block text-sm font-medium text-gray-700"
              >
                Whatsapp
              </label>
              <input
                name="whatsapp"
                value={values.whatsapp}
                onChange={handleChange}
                type="text"
                className="m-1 p-1 shadow-sm sm:text-sm border border-gray-900 rounded-md w-full focus:ring focus:ring-blue-600 "
              />
            </div>

            <div className="m-2 ">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email (no se puede editar)
              </label>
              <input
                name="email"
                value={email}
                disabled
                type="text"
                className="m-1 p-1 shadow-sm sm:text-sm border border-gray-900 rounded-md w-full focus:ring focus:ring-blue-600 "
              />
            </div>

            {errors.password && touched.password && errors.password}
            <button
              type="submit"
              disabled={isSubmitting}
              className="my-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Guardar
            </button>
          </form>
        )}
      </Formik>
    </div>
  )
}
