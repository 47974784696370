import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Row, Col, message, Pagination } from 'antd'
import { parse } from 'date-fns'

// Own components
import FiltersForm from './FiltersForm'
import ContainerLayout from '../Layouts/ContainerLayout'
import PageHeaderStyled from '../Shared/Common/PageHeader'
import ModalStyled from '../Shared/Common/Modal'
import ActionsContacts from './Actions'
import Lead from './components/Lead'
import LeadDetail from './components/LeadDetail'
import client from '../Services/apiClient'

// Services
import { ContactsAPI } from '../Services/contacts'

const ContactsList = (props) => {
  let history = useHistory()
  const [dataSource, setDataSource] = useState([])
  const [detailLead, setDetailLead] = useState(null)
  const [action, setAction] = useState(null)
  const [dataRow, setDataRow] = useState(null)
  const [titleModal, setTitleModal] = useState('')
  const [visibleModal, setVisibleModal] = useState(false)

  const toggleModal = () => setVisibleModal(!visibleModal)

  const getLeads = async () => {
    try {
      const response = await client.get('list_contact/', {
        params: {
          status: '1',
        },
      })

      if (response.data.results[0]) {
        setDetailLead(response.data.results[0])
      }

      setDataSource(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getLeads()
  }, [])

  const selectLead = (lead) => {
    if (window?.innerWidth < 640) {
      history.push(`/contacts/${lead.id}`)
    } else {
      setDetailLead(lead)
    }
  }

  const handleSubmitOnActions = async (dataForm) => {
    if (action === 'contacted' || action === 'discarded')
      handleSubmitContactedDiscardedContact(dataForm)
  }

  const handleSubmitContactedDiscardedContact = async (dataForm) => {
    const { id: contactID, ...restData } = dataForm
    await ContactsAPI.updateContact(contactID, restData)
    toggleModal()
    message.success('Se ha actualizado la información de contacto')
    getLeads()
  }

  const showActionModal = ({ action, contactId }) => {
    if (!action || !contactId) {
      throw new Error('Expected arguments { action, contactId }')
    }

    const dataContact = dataSource.results.find(
      (property) => Number(property.id) === Number(contactId)
    )
    switch (action) {
      case 'contacted':
        setTitleModal('Marcar como contactado')
        setAction(action)
        setDataRow(dataContact)
        setVisibleModal(true)
        break
      case 'discarded':
        setTitleModal('Marcar como descartado')
        setAction(action)
        setDataRow(dataContact)
        setVisibleModal(true)
        break
      default:
        break
    }
  }

  function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    var separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  }

  const changePage = async (e) => {
    const endpoint = (dataSource.next || dataSource.previous).split('v1/')[1]
    const url = updateQueryStringParameter(endpoint, 'page', e)

    try {
      const response = await client.get(url)

      if (response.data.results[0]) {
        setDetailLead(response.data.results[0])
      }

      setDataSource(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmitFilters = async (values) => {
    const defaultParams = { status: 1 }
    const params = { ...defaultParams, ...values }

    try {
      const response = await client.get('list_contact/', {
        params,
      })

      if (response.data.results[0]) {
        setDetailLead(response.data.results[0])
      }

      setDataSource(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <ContainerLayout backgroundColor="#ffffff">
      <PageHeaderStyled title="Contactos" className="px-0 pt-0" />

      <Row type="flex" gutter={[40, 48]} className="mb-3">
        <Col xs={24}>
          <div className="md:grid grid-cols-5">
            <div className="col-span-5">
              <FiltersForm hideTags onSubmit={handleSubmitFilters} />
            </div>
          </div>
        </Col>
      </Row>

      <div className="md:grid md:grid-cols-5 grid-gap-3 mb-3">
        <div className="col-span-1">
          {dataSource?.results?.length > 0 &&
            dataSource?.results?.map((lead) => (
              <Lead
                key={lead.id}
                onClick={() => selectLead(lead)}
                selected={detailLead.id === lead.id}
                date={parse(lead.created, 'dd-MM-yyyy HH:m:s', new Date())}
                name={lead.name}
                property={lead.property_title}
              ></Lead>
            ))}
        </div>
        <div className="hidden md:block col-span-4">
          {detailLead && (
            <LeadDetail
              callBack={showActionModal}
              name={detailLead.name}
              phone={detailLead.phone}
              email={detailLead.email}
              image={detailLead.image_cover}
              message={detailLead.message}
              title={detailLead.property_title}
              contactId={detailLead.id}
              propertyId={detailLead.property_id}
            ></LeadDetail>
          )}
        </div>
      </div>

      <Pagination onChange={changePage} total={dataSource.count} />

      <ModalStyled
        visible={visibleModal}
        onCancel={toggleModal}
        title={titleModal}
        centered
        footer={null}
      >
        {action ? (
          <ActionsContacts
            action={action}
            contact={dataRow}
            onSubmit={handleSubmitOnActions}
          />
        ) : null}
      </ModalStyled>
    </ContainerLayout>
  )
}

export default ContactsList
