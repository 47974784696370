import React from 'react'
import SelectPlan from './forms/select_plan'
import LogoCYT from '../Shared/Images/logo-blue.svg'

export default function index(props) {
  return (
    <div className="pb-10">
      <div className="text-center p-5 font-bold">
        <img src={LogoCYT} className="mx-auto h-24 w-auto" />
        <h2 className="text-2xl mt-3">Selecciona un producto</h2>
        <p>{props?.contract?.end_date && 'Tu paquete expiró el'}</p>
      </div>
      <SelectPlan {...props} />
    </div>
  )
}
