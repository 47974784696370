import React, { useEffect, useState, forwardRef } from 'react'
import { Select } from 'antd'

// Services
import { GeneralAPI } from '../../Services/general'

const { Option } = Select

const SelectPropertyType = forwardRef(({ ...props }, ref) => {
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let response = await GeneralAPI.getPropertyType()
      setDataSource(response)
    }
    fetchData()
  }, [])

  return (
    <Select {...props} ref={ref} placeholder="Selecciona el tipo de propiedad">
      {dataSource.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  )
})

export default SelectPropertyType
