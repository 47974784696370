import axios from 'axios'
import { fetchHeaders } from './utils'

export const ReceiptsAPI = {
  getReceipts,
}

const { api: API_URL } = process.env.ENV

async function getReceipts(params) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}invoices/`,
    params,
  })
}
