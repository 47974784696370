import React, { useEffect, useState } from 'react'
import { PropertyAPI } from './../../Services/property'
import { sub, getISODay, formatISO } from 'date-fns'

import ContainerLayout from './../../Layouts/ContainerLayout'
import { formatNumberPrice } from '../../Shared/utils'
import { useWindowWidthSize } from '../../Shared/customHooks'


const { portal: PORTALURL } = process.env.ENV

const ExpiringProperties = (props) => {
  const [dataSource, setDataSource] = useState({})
  const [selected, onSelect] = useState([])

  const widthDevice = useWindowWidthSize()

  const fetchData = async () => {
    const ninetyDaysAgoFromNextMonday = sub(new Date(), { days: 90 - (7 - getISODay(new Date) % 7 + 1) })
    const values = {
      status: 'published',
      feed__isnull: true,
      modified__lte: formatISO(ninetyDaysAgoFromNextMonday, { representation: 'date' }),
    }
    const data = await PropertyAPI.getMyProperties(values)
    setDataSource(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onErrorImage = (e) => {
    e.target.src =
      'https://cyt-media.s3-us-west-2.amazonaws.com/img_casas/NoFoto.png'
  }

  const onChangeSelected = (id) => {
    if (selected.find(x => x == id)) {
      onSelect(selected.filter(x => x !== id))
    } else {
      onSelect([...selected, id])
    }
  }

  const edit = async () => {
    const values = {
      modified: new Date()
    }

    await selected.map(async (x) => {
      await PropertyAPI.editProperty(x, values)
    })

    await fetchData()
  }
  const url = `${PORTALURL}/propiedad/`


  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider" />
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Tipo de propiedad
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Dirección
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Fecha de creación
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Precio
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataSource?.results?.map(element => (
                    <tr className="bg-white space-y-4 space-x-6 text-sm text-gray-500 text-center">
                      <td className="whitespace-nowrap p-2">
                        <img
                          className="image-table-fluid"
                          src={element.image_cover || ''}
                          alt={element?.title || ''}
                          onError={onErrorImage}
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </td>
                      <td className="whitespace-nowrap p-2">
                        <a
                          href={`${url}/${element.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="test-center text-blue-cyt"
                        >
                          {element.id}
                        </a>
                      </td>
                      <td className="whitespace-nowrap text-center">
                        {element?.property_type?.name}
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="capitalize">
                          <div>{element?.colony?.name || ''}</div>
                          <div>
                            {element?.municipality?.name  || ''}
                            {element?.state?.name && (<span>, {element?.state.name}</span>)}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {element.date_created}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {element?.price_rent > 0 && (<div key="1">Renta: ${formatNumberPrice(element.price_rent)}</div>)}
                        {element?.price_sale > 0 && (<div key="2">Venta: ${formatNumberPrice(element.price_sale)}</div>)}
                        {element?.price_transfer > 0 && (<div key="3">Traspaso: ${formatNumberPrice(element.price_transfer)}</div>)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <div className="flex justify-center flex-wrap space-x-0 sm:space-x-2">
                        <input type="checkbox" id="checkbox" onChange={() => onChangeSelected(element.id)} />
                        <label for="checkbox">Mantener</label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selected.length >= 1 && (
        <div className="my-7 flex justify-end" onClick={edit}>
          <button className="bg-blue-cyt text-white p-3 rounded-md text-bold">
            Guardar
          </button>
        </div>
      )}
    </ContainerLayout>
  )
}

export default ExpiringProperties
