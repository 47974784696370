import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col, Form, Button, Input, DatePicker } from 'antd'

// Own components
import ContainerLayout from '../Layouts/ContainerLayout'
import PageHeaderStyled from '../Shared/Common/PageHeader'
import SelectReports from '../Shared/Selects/SelectReports'
import SelectPropertyStatus from '../Shared/Selects/SelectPropertyStatus'
import SelectOperationType from '../Shared/Selects/SelectOperationType'

// Services
import { ReportAPI } from '../Services/reports'

// Custom hook
import { useWindowWidthSize } from '../Shared/customHooks'

const { Item: FormItem } = Form
const { RangePicker } = DatePicker
const defaultDate = 'YYYY-MM-DD'

const ReportsList = (props) => {
  const [textButton, setTextButton] = useState('Exportar')
  const [loading, setLoading] = useState(false)
  const [showRangePicker, setShowRangePicker] = useState(false)
  const [showPropertyID, setShowPropertyID] = useState(false)
  const [showStatusProperty, setShowStatusProperty] = useState(false)
  const [showOperationType, setShowOperationType] = useState(false)
  const [responsiveDesign, setResponsiveDesign] = useState(false)
  const widthDevice = useWindowWidthSize()

  const {
    getFieldDecorator,
    resetFields,
    validateFields,
    setFields,
  } = props.form

  const handleSubmitForm = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        const { typeReport, status_property, operation_type } = values
        const [start_date, end_date] = values.rangePicker
        values.start_date = moment(start_date).format(defaultDate)
        values.end_date = moment(end_date).format(defaultDate)
        if (status_property) {
          values.status_property = status_property
            .map((item) => item.key)
            .join(',')
        }
        if (operation_type) {
          values.operation_type = operation_type
            .map((item) => item.key)
            .join(',')
        }
        if (typeReport) {
          setShowRangePicker(false)
          setShowPropertyID(false)
          setShowStatusProperty(false)
          setShowOperationType(false)
        }
        delete values.rangePicker
        setLoading(true)
        setTextButton('Descargando... Esto puede demorar más de un minuto.')
        let response = await ReportAPI.basicReports(typeReport, values)
        const url = window.URL.createObjectURL(new Blob([response]))
        const fakeLink = document.createElement('a')
        fakeLink.href = url
        fakeLink.setAttribute('download', 'file.csv')
        document.body.appendChild(fakeLink)
        fakeLink.click()
        setLoading(false)
        setTextButton('Exportar')
        resetFields()
      }
    })
  }

  const handleSelectReport = (value) => {
    if (value === 'my_leads_report') {
      setShowPropertyID(true)
      setShowStatusProperty(false)
      setShowOperationType(false)
      setShowRangePicker(true)
    } else if (value === 'properties_list_report') {
      setShowPropertyID(false)
      setShowRangePicker(true)
      setShowStatusProperty(true)
      setShowOperationType(true)
    }
  }

  const validatePropertyID = (e) => {
    const { value } = e.target

    if (value.length > 0) {
      if (value % 1 !== 0) {
        setFields({
          property_id: {
            value,
            errors: [new Error('Ingresa un ID de propiedad valido.')],
          },
        })
      } else {
        setFields({
          property_id: {
            value,
          },
        })
      }
    }
  }

  useEffect(() => {
    if (widthDevice <= 1024) setResponsiveDesign(true)
    else setResponsiveDesign(false)
  }, [widthDevice])

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={responsiveDesign ? 2 : 5}
      marginY={2}
    >
      <PageHeaderStyled title="Reportes" className="px-0 pt-0" />

      <Form onSubmit={handleSubmitForm} colon={false}>
        <Row type="flex" justify="center" gutter={[48, 16]}>
          <Col xs={24} md={24} xl={12}>
            <FormItem label="Selecciona un reporte">
              {getFieldDecorator('typeReport', {
                rules: [
                  {
                    required: true,
                    message: 'Debes selecciona un reporte.',
                  },
                ],
              })(<SelectReports onSelect={handleSelectReport} />)}
            </FormItem>
          </Col>
        </Row>

        {showRangePicker && (
          <Row type="flex" justify="center" gutter={[48, 16]}>
            <Col xs={24} lg={12}>
              <FormItem label="Selecciona un rango de fechas">
                {getFieldDecorator('rangePicker', {
                  rules: [
                    {
                      required: true,
                      message: 'Debes seleccionar un rango de fechas',
                    },
                  ],
                })(<RangePicker className="d-block" />)}
              </FormItem>
            </Col>
          </Row>
        )}

        {showStatusProperty && (
          <Row type="flex" justify="center" gutter={[48, 16]}>
            <Col xs={24} lg={12}>
              <FormItem label="Selecciona uno o varios estatus de propiedad">
                {getFieldDecorator(
                  'status_property',
                  {}
                )(
                  <SelectPropertyStatus
                    mode="multiple"
                    placeholder="Selecciona un estatus"
                    labelInValue
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}

        {showOperationType && (
          <Row type="flex" justify="center" gutter={[48, 16]}>
            <Col xs={24} lg={12}>
              <FormItem label="Selecciona uno o varios tipos de operación">
                {getFieldDecorator(
                  'operation_type',
                  {}
                )(
                  <SelectOperationType
                    placeholder="Selecciona un tipo de operación"
                    mode="multiple"
                    labelInValue
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}

        <Row type="flex" justify="center" gutter={[48, 16]}>
          <Col xs={24} lg={12}>
            <Button
              type="primary"
              htmlType="submit"
              block={responsiveDesign ? true : false}
              className="float-right"
              loading={loading}
              data-splitbee-event="reports-export"
            >
              {textButton}
            </Button>
          </Col>
        </Row>
      </Form>
    </ContainerLayout>
  )
}

const RReportsListWrapper = Form.create({})(ReportsList)
export default RReportsListWrapper
