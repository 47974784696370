import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Layout } from 'antd'

//Own Components
import LogoCYT from '../Shared/Images/logo-white-cyt.png'
const { Footer, Sider, Content } = Layout

const OutsideLayout = withRouter(({ history, ...props }) => {
  const { children, outside } = props

  if (!outside) return <div>{children}</div>

  return (
    <Layout>
      <Sider
        collapsible
        style={{
          overflow: 'auto',
          minHeight: '100vh',
        }}
        breakpoint={('xs', 'sm', 'md')}
      >
        <div className="px-3 py-1">
          <Link to="/">
            <img
              src={LogoCYT}
              alt="Logo Casas y Terrenos"
              className="img-fluid"
            />
          </Link>
        </div>
      </Sider>
      <Layout>
        <Content className="">{children}</Content>
        <Footer className="text-center">casasyterrenos.com</Footer>
      </Layout>
    </Layout>
  )
})

export default OutsideLayout
