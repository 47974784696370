import React from 'react'
import { Icon } from 'antd'

const PeopleSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512.003 512.003"
      version="1.1"
      viewBox="0 0 512.003 512.003"
      xmlSpace="preserve"
    >
      <path
        fill="#E6AF78"
        d="M335.446 361.939L335.446 300.15 176.558 300.15 176.558 361.939 256.002 432.556z"
      ></path>
      <path
        fill="#EFF2FA"
        d="M458.286 390.843l-109.229-25.701c-1.65-.388-3.167-1.047-4.587-1.846l-88.469 51.607-85.019-54.103a17.59 17.59 0 01-8.036 4.343L53.717 390.844c-15.95 3.753-27.222 17.985-27.222 34.37v69.134c0 9.751 7.904 17.654 17.654 17.654h423.702c9.751 0 17.654-7.904 17.654-17.654v-69.134c.002-16.386-11.27-30.619-27.219-34.371z"
      ></path>
      <path
        fill="#D29B6E"
        d="M176.558 300.15v65.193c100.078 36.057 158.888-54.185 158.888-54.185v-11.009H176.558v.001z"
      ></path>
      <path
        fill="#F0C087"
        d="M141.249 97.127l7.692 169.228a52.962 52.962 0 0021.13 39.965l36.498 27.374a52.969 52.969 0 0031.778 10.593h35.309c11.46 0 22.61-3.717 31.778-10.593l36.498-27.374a52.962 52.962 0 0021.13-39.965l7.692-169.228H141.249z"
      ></path>
      <path
        fill="#E6AF78"
        d="M229.521 132.435c35.309 0 88.271-8.827 100.833-35.309H141.249l7.692 169.228a52.962 52.962 0 0021.131 39.965l36.498 27.374a52.969 52.969 0 0031.778 10.593h17.654c-17.654 0-52.963-35.309-52.963-79.444V158.916c0-8.827 8.827-26.481 26.482-26.481z"
      ></path>
      <g fill="#E4EAF6">
        <path d="M91.3 454.714l-57.199-51.382a35.246 35.246 0 00-7.603 21.882v69.134c0 9.751 7.904 17.654 17.654 17.654h61.79v-24.454A44.14 44.14 0 0091.3 454.714zM420.705 454.714l57.199-51.382a35.246 35.246 0 017.603 21.882v69.134c0 9.751-7.904 17.654-17.654 17.654h-61.79v-24.454a44.133 44.133 0 0114.642-32.834z"></path>
      </g>
      <path
        fill="#5B5D6E"
        d="M278.07 512.001L233.934 512.001 239.451 432.556 272.553 432.556z"
      ></path>
      <path
        fill="#515262"
        d="M278.07 414.902h-44.136v16.613c0 5.451 4.418 9.869 9.869 9.869H268.2c5.451 0 9.869-4.418 9.869-9.869v-16.613h.001z"
      ></path>
      <g fill="#E4EAF6">
        <path d="M175.319 342.287l80.684 72.615s-22.596 11.407-50.48 34.398c-5.752 4.742-14.453 2.821-17.538-3.966l-37.907-83.394 11.992-17.987c2.984-4.48 9.248-5.266 13.249-1.666z"></path>
        <path d="M336.686 342.287l-80.684 72.615s22.596 11.407 50.48 34.398c5.752 4.742 14.453 2.821 17.538-3.966l37.907-83.394-11.992-17.987c-2.985-4.48-9.249-5.266-13.249-1.666z"></path>
      </g>
      <path
        fill="#785550"
        d="M309.516 38.647l8.275 58.48c37.775 7.555 43.219 66.837 44.003 83.769.142 3.073 1.123 6.04 2.79 8.625l14.413 22.358s-4.933-36.964 17.654-61.79c.001 0 7.757-146.751-87.135-111.442z"
      ></path>
      <path
        fill="#F0C087"
        d="M387.851 208.115l-9.965 39.861a10.61 10.61 0 01-10.296 8.038 10.612 10.612 0 01-10.531-9.296l-5.097-40.77c-1.364-10.913 7.144-20.551 18.142-20.551h.008c11.896.001 20.624 11.178 17.739 22.718z"
      ></path>
      <path
        fill="#694B4B"
        d="M149.709 22.831l13.056 8.919c-59.031 43.584-47.998 118.339-47.998 118.339 17.654 17.654 17.654 61.79 17.654 61.79l17.654-17.654s-6.813-50.998 26.481-70.617c30.895-18.206 57.928-8.827 85.513-8.827 73.927 0 94.616-27.861 91.03-61.79-1.856-17.556-28.698-54.126-97.098-52.963-27.604.469-79.443 8.827-106.292 22.803z"
      ></path>
      <path
        fill="#5A4146"
        d="M144.559 107.057s-9.379-36.964 18.206-75.306c-59.031 43.584-47.998 118.339-47.998 118.339 17.654 17.654 17.654 61.79 17.654 61.79l17.654-17.654s-6.813-50.998 26.481-70.617c30.895-18.206 57.928-8.827 85.513-8.827 12.023 0 22.5-.805 31.832-2.185-56.932 1.908-90.493-41.217-149.342-5.54z"
      ></path>
      <path
        fill="#E6AF78"
        d="M124.153 208.115l9.965 39.861a10.61 10.61 0 0010.296 8.038c5.353 0 9.867-3.985 10.531-9.296l5.097-40.77c1.364-10.913-7.144-20.551-18.142-20.551h-.008c-11.896.001-20.624 11.178-17.739 22.718z"
      ></path>
      <path
        fill="#E4EAF6"
        d="M370.755 494.346h-61.79a8.827 8.827 0 00-8.827 8.827V512h79.444v-8.827a8.828 8.828 0 00-8.827-8.827z"
      ></path>
    </svg>
  )
}

const PeopleIcon = (props) => <Icon component={PeopleSVG} {...props} />

export default PeopleIcon
