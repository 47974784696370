import React, { useState, useEffect, useMemo } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { RadioGroup, Switch } from '@headlessui/react'
import getAcquisitionToken from '../../Utils/getAcquisitionToken'
import useAcquisitionUser from '../../Hooks/useAcquisitionUser'
import acquisitionClient from '../../Services/acquisitionApiClient'
import NumberFormat from 'react-number-format'
import VISITS from '../../Shared/Images/visits.png'
import VALIDATION from '../../Shared/Images/validation.png'
import CONTACT from '../../Shared/Images/contact.png'
import * as Sentry from '@sentry/react'

import { loadStripe } from '@stripe/stripe-js'

const SelectPlan = () => {
  const [stripe, setStripe] = useState()
  const token = getAcquisitionToken()
  const { data: user, error } = useAcquisitionUser(token?.uuid)

  async function getStripe() {
    const s = await loadStripe(process.env.ENV.STRIPE_PUBLIC_KEY)
    setStripe(s)
  }

  const checkout = async (id) => {
    let { data } = await acquisitionClient.post(
      '/api/v1/products/checkout/acquisition/',
      {
        price_id: id,
      }
    )
    stripe.redirectToCheckout({ sessionId: data.id })
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [response, setResponse] = useState([])
  const [products, setProducts] = useState([])

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      await acquisitionClient.patch(`/customers/users/${token.uuid}/`, values)
      checkout(values.plan_id)
      setSubmitting(false)
    } catch (err) {
      setSubmitting(false)
      Sentry.captureException(err)
    }
  }

  const getPackages = async () => {
    try {
      const response = await acquisitionClient.get(
        '/api/v1/products?external_processor=stripe'
      )
      setResponse(response.data)
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    if (response.length > 1 && user?.account_type) {
      if (user.account_type === 'direct') {
        const prices = response.find((p) =>
          p.name.toLowerCase().includes('particular')
        )
        setProducts(prices)
      } else if (
        user.account_type === 'representative' ||
        user.account_type === 'promoter'
      ) {
        const prices = response.find((p) => p.name == 'PRO' || p.name == 'Pro')
        setProducts(prices)
      }
    }
  }, [response, user])

  useEffect(() => {
    getPackages()
    getStripe()
    return () => {
      setResponse({})
    }
  }, [])

  const PlanSchema = Yup.object().shape({
    plan_id: Yup.string().required('Elige un plan'),
  })

  return (
    <Formik
      initialValues={{
        plan_id: '',
        should_invoice: false,
      }}
      validationSchema={PlanSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="w-full relative space-y-3">
          <RadioGroup
            value={values.plan_id}
            onChange={(val) => setFieldValue('plan_id', val)}
          >
            <RadioGroup.Label className="form-title my-10  ">
              Ya casi terminas, escoge tu plan:
            </RadioGroup.Label>
            <div className=" space-y-3 md:space-y-0 md:grid grid-cols-1 gap-2">
              {products?.name &&
                products?.prices.map((product, i) => (
                  <RadioGroup.Option
                    key={i}
                    value={product.external_id}
                    className="md:h-24 relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
                  >
                    {({ checked }) => (
                      <>
                        <div className="flex flex-1 items-center">
                          <div className="text-sm  flex-1">
                            <RadioGroup.Label
                              as="p"
                              className="text-base leading-6 font-medium text-gray-900"
                            >
                              {product.metadata.value}{' '}
                              {
                                { days: 'días', months: 'meses' }[
                                  product.metadata.value_type
                                ]
                              }
                            </RadioGroup.Label>
                            <div className="flex">
                              <NumberFormat
                                decimalScale={2}
                                value={product.amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                              <p className="ml-1">+ IVA</p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={classNames(
                            checked
                              ? 'border-yellow-500'
                              : 'border-transparent',
                            'absolute -inset-px rounded-lg border-2 pointer-events-none'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
            </div>
          </RadioGroup>
          {errors.plan_id && (
            <div className="text-sm text-red-600">{errors.plan_id}</div>
          )}
          <Switch.Group
            as="div"
            className="flex items-center justify-between p-5 shadow rounded"
          >
            <span className="flex-grow flex flex-col">
              <Switch.Label
                as="span"
                className="text-xl font-medium text-gray-900"
                passive
              >
                ¿Requieres factura?
              </Switch.Label>
              <Switch.Description
                as="span"
                className="text-sm leading-5 font-normal  text-gray-500"
              >
                Podrás solicitarla una vez completado tu pago en la siguiente...
              </Switch.Description>
            </span>
            <Switch
              checked={values.should_invoice}
              onChange={(val) => setFieldValue('should_invoice', val)}
              className={classNames(
                values.should_invoice ? 'bg-yellow-500' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  values.should_invoice ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>
          <div>
            <div className="w-100 mt-6 md:m-none md:flex gap-4 ">
              <div className="flex-grow">
                <p className="text-base md:text-xl font-extrabold">
                  Realiza tu pago y disfruta de los beneficios de PRO:
                </p>
              </div>
            </div>
            <div className="block md:grid grid-cols-3 grid-row-1 h-full md:justify-between  align-middle items-center">
              <div className="flex flex-col">
                <img className="w-2/3 m-auto md:w-full" src={VISITS} />
                <p className="min-h-full text-lg md:text-sm text-center mx-4">
                  Más de 400,000 personas al mes podrán ver tu propiedad.
                </p>
              </div>
              <div className="flex flex-col">
                <img className="w-2/3 m-auto md:w-full" src={VALIDATION} />
                <p className="md:text-sm text-lg text-center mx-4">
                  Validamos correos electrónicos y teléfonos por tí.
                </p>
              </div>
              <div className="flex flex-col">
                <img className="w-2/3 m-auto md:w-full" src={CONTACT} />
                <p className="md:text-sm text-lg text-center mx-4">
                  Obtén contactos directamente en tu correo electrónico.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-3 col-span-2 sticky bottom-0 w-full  md:absolute md:bottom-28  p-4 bg-white md:block md:p-0">
            <div
              className="hidden mb-10  md:flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="grid grid-cols-2">
              <a
                href={
                  user?.account_type === 'direct'
                    ? '/unirse/descripcion'
                    : '/unirse'
                }
                className="self-center underline"
              >
                Regresar
              </a>
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Contratar y publicar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
export default SelectPlan
