import React, { Fragment } from 'react'
import { Row, Col, Form } from 'antd'

// Own component
import Amenities from '../../Shared/Common/Amenities'

const { Item: FormItem } = Form

const AmenitiesCreateProperty = (props) => {
  const { form } = props
  const { getFieldDecorator } = form
  const { hidden } = props

  return (
    <Fragment>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        gutter={[48, 8]}
        className={hidden ? 'hidden' : 'flex'}
      >
        <Col xs={24}>
          <FormItem label="Selecciona las amenidades">
            {getFieldDecorator('amenities', {
              initialValue: [],
            })(<Amenities />)}
          </FormItem>
        </Col>
      </Row>
    </Fragment>
  )
}

export default AmenitiesCreateProperty
