import React from 'react'
import { Row, Col, Typography } from 'antd'

//Own components
import ContainerLayout from '../../Layouts/ContainerLayout'
import PageHeaderStyled from '../Common/PageHeader'
import OutsideLayout from '../../Layouts/OutsideLayout'

const { Paragraph } = Typography

const ContactPage = (props) => {
  const { outside } = props

  return (
    <OutsideLayout outside={outside}>
      <ContainerLayout
        backgroundColor="#ffffff"
        paddingX={2}
        paddingY={2}
        marginX={2}
        marginY={2}
      >
        <PageHeaderStyled title="Contáctanos" className="px-0 pt-0" />
        <Row type="flex" justify="center">
          <Col
            xs={24}
            sm={8}
            className="text-center"
            style={{ fontSize: '1.1em' }}
          >
            <span className="d-block text-center mr-2">
              ¿Tienes dudas, comentarios o sugerencias?
            </span>
            <span className="d-block text-center mr-2">
              ¡Escríbenos! Para nosotros es un placer atenderte y conocer tus
              opiniones.
            </span>
            <br />
            <span className="d-block font-weight-bold mt-3 mr-2">
              Horario de atención:
            </span>
            <br />
            <span className="d-block">Lunes a Viernes de 8:30am a 6:00pm </span>
            <br />
            <br />
            <span className="d-block font-weight-bold">Whatsapp:</span>
            <span className="d-block">
              <Paragraph className="mb-0" copyable={{ text: 3317150016 }}>
                <a
                  href="https://api.whatsapp.com/send?phone=+5213317150016"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-splitbee-event="contact-us-phone"
                >
                  33 1715 0016
                </a>
              </Paragraph>
            </span>
            <span className="d-block font-weight-bold mt-3">
              Correo electrónico:
            </span>
            <span className="d-block">
              <Paragraph
                className="mb-0"
                copyable={{ text: 'pelizalde@casasyterrenos.com' }}
              >
                <a
                  href="mailto:pelizalde@casasyterrenos.com"
                  data-splitbee-event="contact-us-email"
                >
                  pelizalde@casasyterrenos.com
                </a>
              </Paragraph>
            </span>
            <span className="d-block font-weight-bold mt-3">Dirección</span>
            <span className="d-block">
              <Paragraph
                className="mb-0"
                copyable={{
                  text:
                    'Av. Juan Palomar y Arias 567, Monraz, 44670 Guadalajara, Jal',
                }}
              >
                Av. Juan Palomar y Arias 567, Monraz, 44670 Guadalajara, Jal
              </Paragraph>
            </span>
          </Col>
        </Row>
      </ContainerLayout>
    </OutsideLayout>
  )
}

export default ContactPage
