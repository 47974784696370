import React from 'react'
import { Row, Col, Form } from 'antd'

// Own Components
import MapLocation from './Maps'

const { Item: FormItem } = Form

const LocationDetailsDevelopment = (props) => {
  const {
    street,
    exterior_number,
    colony,
    municipality,
    state,
    latitude,
    longitude,
  } = props.data || ''

  return (
    <Form className="login-form" colon={false} hideRequiredMark>
      <div className="m-4">
        {latitude && longitude ? (
          <Row className="mb-2">
            <Col xs={24}>
              <MapLocation latitude={latitude} longitude={longitude} />
            </Col>
          </Row>
        ) : null}
        <Row type="flex" justify="start" gutter={48}>
          <Col xs={24}>
            <FormItem label="Calle" className="font-weight-bold mb-0">
              <span className="font-weight-light d-block lh-6 mb-2">
                {street}
              </span>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label="No. Exterior" className="font-weight-bold mb-0">
              <span className="font-weight-light">{exterior_number}</span>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label="Colonia" className="font-weight-bold mb-0">
              <span className="font-weight-light">{colony && colony.name}</span>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label="Municipio" className="font-weight-bold mb-0">
              <span className="font-weight-light">
                {municipality && municipality.name}
              </span>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label="Estado" className="font-weight-bold mb-0">
              <span className="font-weight-light">{state && state.name}</span>
            </FormItem>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export default LocationDetailsDevelopment
