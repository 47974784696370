import React from 'react'
import { Icon } from 'antd'

const UserSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512.001 512.001"
      version="1.1"
      viewBox="0 0 512.001 512.001"
      xmlSpace="preserve"
    >
      <path
        fill="#EF4C35"
        d="M278.665 418.858L289.429 390.192 256.407 365.893 223.276 390.276 235.25 418.858z"
      ></path>
      <path
        fill="#DD3E2B"
        d="M235.25 418.858L235.881 420.366 221.1 512 292.932 512 278.134 420.278 278.665 418.858z"
      ></path>
      <path
        fill="#D2D2D7"
        d="M230.312 512L245.273 419.253 230.816 384.73 184.43 340.627 193.511 406.757 222.988 512z"
      ></path>
      <path
        fill="#E4E4EA"
        d="M291.181 512L320.998 405.542 330.084 339.397 281.819 384.598 268.774 419.342 283.721 512z"
      ></path>
      <path
        fill="#F5F5F7"
        d="M291.867 403.172c9.546 7.025 15.319.771 17.475-2.483l30.413-62.511-39.251-25.908-9.265 16.683-34.522 35.836-33.594-32.724-6.511-22.629-43.549 28.74 29.696 61.038c.821 1.624 6.844 12.407 18.329 3.966l35.319-25.993 33.662 24.771c.534.325 1.124.717 1.798 1.214z"
      ></path>
      <path
        fill="#694B4B"
        d="M261.415 0h-9.211C189.279 0 138.09 51.196 138.09 114.118v53.533s25.98-2.577 29.176-15.176l-.017-.013c1.097-1.193 2.014-2.552 2.661-4.072 14.737-.444 83.578-.98 122.89-48.459 4.692 13.725 20.562 39.713 51.1 47.897a14.51 14.51 0 002.709 4.444l-.253.204c3.194 12.599 29.176 15.176 29.176 15.176v-53.534C375.532 51.196 324.338 0 261.415 0zm86.037 144.28l1.099-.796h.156l-1.79 1.326.535-.53zm-.559.343l.123-.388.381-.751h.155l-.453.943-.206.196zm-3.027 2.34l-.042-.06 2.814-2.036-.664.642-2.057 1.521-.051-.067zm2.323-.661l-.881 1.823c-.007-.002-.009-.002-.016-.006l-.123-.056c-.02-.009-.043-.023-.06-.032l1.436-2.855.038-.036-.394 1.162z"
      ></path>
      <path
        fill="#563B3B"
        d="M158.204 143.582v-29.465c0-61.097 48.263-111.122 108.663-113.978-1.81-.087-3.626-.14-5.452-.14h-9.211c-62.925 0-114.114 51.196-114.114 114.118v53.533s11.48-1.145 20.115-5.607v-18.461z"
      ></path>
      <path
        fill="#FBCB99"
        d="M361.826 151.107a14.37 14.37 0 00-12.793 2.14 14.378 14.378 0 00-5.852 11.577v40.943c0 4.567 2.172 8.864 5.852 11.568a14.356 14.356 0 0012.789 2.146c2.755-.856 5.459-2.095 8.041-3.679a14.436 14.436 0 003.93-3.556 14.454 14.454 0 004.186-3.257c5.805-6.565 9.003-14.979 9.003-23.699 0-15.756-10.108-29.494-25.156-34.183z"
      ></path>
      <path
        fill="#EDBA89"
        d="M165.003 153.245a14.382 14.382 0 00-12.791-2.143c-15.046 4.694-25.161 18.432-25.161 34.187 0 15.762 10.114 29.499 25.161 34.191a14.37 14.37 0 0012.791-2.144 14.354 14.354 0 005.855-11.571v-40.948c0-4.564-2.171-8.864-5.855-11.572z"
      ></path>
      <path
        fill="#FBCB99"
        d="M296.246 277.139c-2.825-2.703-6.615-4.121-10.55-3.976l-.488.023a14.63 14.63 0 00-2.61.331 77.48 77.48 0 01-16.795 1.862h-17.566a77.58 77.58 0 01-17.297-1.973 14.338 14.338 0 00-12.162 2.764 14.372 14.372 0 00-5.417 11.238v41.794l-.008.141a14.388 14.388 0 004.319 11.088l28.869 28.12a14.366 14.366 0 0010.022 4.071h.233a14.361 14.361 0 0010.118-4.402l29.742-30.872a14.357 14.357 0 004.021-9.965v-39.864a14.374 14.374 0 00-4.431-10.38z"
      ></path>
      <path
        fill="#EDBA89"
        d="M259.476 364.235l-28.872-28.117a14.381 14.381 0 01-4.321-11.087l.013-.142v-41.794c0-3.676 1.423-7.176 3.906-9.818-4.041-.706-8.2.327-11.422 2.892a14.372 14.372 0 00-5.417 11.238v41.794l-.008.141a14.388 14.388 0 004.319 11.088l28.869 28.12a14.366 14.366 0 0010.022 4.071h.233a14.357 14.357 0 0010.117-4.402l.128-.134a14.304 14.304 0 01-7.567-3.85z"
      ></path>
      <path
        fill="#FBCB99"
        d="M341.015 139.38c-1.738-.648-3.378-1.402-4.979-2.205-24.143-11.265-34.164-25.917-38.198-34.658a9.827 9.827 0 01-.725-1.151c-2.885-5.305-7.382-1.947-7.382-1.947l-.02-.011c-22.546 17.293-47.173 27.406-67.647 33.29-7.97 2.355-15.605 4.058-22.51 5.296-13.723 2.52-22.546 2.85-22.546 2.85-7.517.541-13.339 6.799-13.339 14.334v58.625c0 46.631 37.94 84.566 84.567 84.566h17.566c46.626 0 84.563-37.934 84.563-84.566v-60.961a14.367 14.367 0 00-9.35-13.462z"
      ></path>
      <path
        fill="#EDBA89"
        d="M180.912 213.803v-58.625c0-7.535 5.82-13.792 13.336-14.334 0 0 8.82-.33 22.546-2.85 6.908-1.238 14.543-2.941 22.515-5.296 18.116-5.211 39.491-13.741 59.802-27.62a48.918 48.918 0 01-1.274-2.562 9.827 9.827 0 01-.725-1.151c-2.885-5.305-7.382-1.947-7.382-1.947l-.02-.011c-22.546 17.293-47.173 27.406-67.647 33.29-7.97 2.355-15.605 4.058-22.51 5.296-13.723 2.52-22.546 2.85-22.546 2.85-7.517.541-13.339 6.799-13.339 14.334v58.625c0 46.631 37.94 84.566 84.567 84.566h17.236c-46.623.001-84.559-37.934-84.559-84.565z"
      ></path>
      <g fill="#324A5E">
        <path d="M92.384 512h140.049l-50.186-179.167-51.759 21.569-.07.026c-21.022 7.379-56.597 34.147-56.597 87.653v51.377C74.099 499 76.444 512 92.384 512zM438.18 494.081v-51.997c0-54.164-37-81.002-56.674-87.683l-49.812-20.756-49.961 178.356h137.922c15.371-.001 18.101-12.092 18.525-17.92z"></path>
      </g>
      <g fill="#283D4C">
        <path d="M182.247 332.834l-51.759 21.569-.07.026c-21.022 7.379-56.597 34.147-56.597 87.653v51.377c.278 5.54 2.624 18.54 18.563 18.54h2.323v-69.917c0-53.507 35.574-80.275 56.597-87.653l.069-.026 33.057-13.776-2.183-7.793zM379.854 512v-71.43a8.12 8.12 0 00-8.121-8.121 8.12 8.12 0 00-8.121 8.121V512h16.242zM150.423 512v-71.43a8.12 8.12 0 00-8.121-8.121 8.12 8.12 0 00-8.121 8.121V512h16.242z"></path>
      </g>
      <path
        fill="#EDBA89"
        d="M265.261 220.686c2.985 0 5.414 2.429 5.414 5.414s-2.429 5.414-5.414 5.414h-13.834a5.42 5.42 0 01-5.414-5.414v-26.39a5.42 5.42 0 015.414-5.414 5.42 5.42 0 015.414 5.414v20.976h8.42z"
      ></path>
    </svg>
  )
}

const UserIcon = (props) => <Icon component={UserSVG} {...props} />

export default UserIcon
