import React from 'react'
import { Row, Col, Button, Form, Input } from 'antd'

// Own components
import SelectPromoterRoles from '../../Shared/Selects/SelectPromoterRoles'

const { Item: FormItem } = Form

const CreatePromoter = (props) => {
  const {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    resetFields,
  } = props.form
  const { onSubmit } = props

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        onSubmit(values, () => resetFields())
      }
    })
  }

  const comparePasswords = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback('Las contraseñas no coinciden')
    } else {
      callback()
    }
  }

  return (
    <Form onSubmit={submitForm} className="login-form" colon={false} data-splitbee-event="promoters-create-save">
      <Row type="flex" gutter={48}>
        <Col xs={24}>
          <FormItem label="Nombre(s)">
            {getFieldDecorator('first_name', {
              rules: [{ required: true, message: 'Ingresa tu(s) nombre(s)' }],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Apellido Paterno">
            {getFieldDecorator('last_name', {
              rules: [
                { required: true, message: 'Ingresa tu apellido paterno' },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Apellido Materno">
            {getFieldDecorator('second_last_name', {})(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Correo electrónico">
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Ingresa tu correo electrónico' },
                {
                  type: 'email',
                  message: 'Ingresa un correo electrónico válido.',
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Rol del promotor">
            {getFieldDecorator('role', {
              rules: [
                { required: true, message: 'Selecciona el rol del promotor' },
              ],
            })(<SelectPromoterRoles placeholder="Selecciona un rol" />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Teléfono">
            {getFieldDecorator('phone_number', {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Escribe el teléfono.',
                },
                {
                  required: true,
                  pattern: /^([0-9])(\w{9,9}\b)+$/,
                  message: 'El teléfono no es válido',
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Whatsapp">
            {getFieldDecorator('whatsapp', {
              rules: [
                {
                  type: 'string',
                  message: 'Whatsapp',
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Contraseña">
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Ingresa una contraseña.' },
                {
                  required: true,
                  pattern: new RegExp(
                    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
                  ),
                  message: (
                    <ul>
                      <li>Al menos una letra mayúscula. [A - Z]</li>
                      <li>Al menos un número. [0 - 9]</li>
                      <li>Al menos 8 caracteres.</li>
                    </ul>
                  ),
                },
              ],
            })(<Input.Password />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Confirmar contraseña">
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  type: 'string',
                  required: true,
                  whitespace: true,
                  message: 'Confirma la contraseña.',
                },
                {
                  validator: comparePasswords,
                },
              ],
            })(<Input.Password />)}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const CreatePromoterWrapper = Form.create({})(CreatePromoter)
export default CreatePromoterWrapper
