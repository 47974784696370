import React, { useEffect, useRef, useState, forwardRef } from 'react'
import GoogleMapReact from 'google-map-react'
import { message } from 'antd'

const { maps: MAPSKEY } = process.env.ENV.google

//const defaultCoordenates = { lat: 24.1719969, lng: -103.5970966 };
const zoomMap = 5

const MapLocation = ({ ...props }, ref) => {
  const mapWrapper = useRef()
  const {
    state,
    municipality,
    colony,
    street,
    exteriorNumber,
    onChange,
    value,
  } = props

  let [map, setMap] = useState(null)
  let [mapsAPI, setMapsAPI] = useState(null)
  const [defaultCoordenates, setDefaultCoordenates] = useState({
    lat: 24.1719969,
    lng: -103.5970966,
  })

  const [mapsCircleMaker, setMapsCircleMaker] = useState(null)
  const [mapMarker, setMapMarker] = useState(null)

  const triggerChange = () => {
    const latLng = mapMarker.getPosition()
    if (onChange) {
      onChange([latLng.lat(), latLng.lng()])
    }
  }

  useEffect(() => {
    if (value) {
      const [lat, lng] = value
      if (lat && lng) setDefaultCoordenates({ lat, lng })
    }
  }, [value])

  const checkMarkerPosition = (mapsAPI) =>
    mapsAPI.geometry.spherical.computeDistanceBetween(
      mapMarker.getPosition(),
      mapsCircleMaker.getCenter()
    ) <= mapsCircleMaker.getRadius()

  const callMaps = async (map, mapsAPI) => {
    const searchTerm = `https://maps.googleapis.com/maps/api/geocode/json?address=México+${state}+${municipality}+${colony}+${street}+${exteriorNumber}&key=${MAPSKEY}`
    let place = await fetch(searchTerm)
    place = await place.json()
    if (place.status === 'OK') {
      const { lat, lng } = place.results[0].geometry.location

      const currentCoordenates = new mapsAPI.LatLng(lat, lng)

      map.setCenter(currentCoordenates)
      map.setOptions({
        zoom: 15,
      })

      mapMarker.setPosition(currentCoordenates)
      mapMarker.setVisible(true)
      mapsCircleMaker.setCenter(currentCoordenates)
      triggerChange()
      mapsAPI.event.addListener(mapMarker, 'dragend', () => {
        const inArea = checkMarkerPosition(mapsAPI)
        if (inArea === false) {
          mapMarker.setPosition(currentCoordenates)
        }
        triggerChange()
      })
    } else if (place.status === 'ZERO_RESULTS') {
      message.warning('No se encontró la dirección')
    } else {
      message.error('Hubo un error en la búsqueda')
    }
  }

  useEffect(() => {
    if (
      state &&
      municipality &&
      colony &&
      street &&
      exteriorNumber &&
      mapsAPI &&
      mapMarker &&
      mapsCircleMaker
    ) {
      callMaps(map, mapsAPI)
    }
  }, [
    state,
    municipality,
    colony,
    street,
    exteriorNumber,
    mapsAPI,
    mapMarker,
    mapsCircleMaker,
  ])

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    let gmapMarker, gmapCircle
    setMap(map)
    setMapsAPI(maps)

    gmapMarker = new maps.Marker({
      map,
    })
    gmapMarker.setDraggable(true)
    gmapCircle = new maps.Circle({
      map,
      radius: 3000,
      fillColor: '#0d47a1',
      strokeColor: '#0d47a1',
    })
    setMapsCircleMaker(gmapCircle)
    setMapMarker(gmapMarker)
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '500px', width: '100%' }} ref={mapWrapper}>
      {defaultCoordenates ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPSKEY }}
          defaultCenter={defaultCoordenates}
          defaultZoom={zoomMap}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      ) : null}
    </div>
  )
}

export default forwardRef(MapLocation)
