import React, { useEffect, useState, forwardRef } from 'react'
import { Select } from 'antd'

//Services
import { GeneralAPI } from '../../Services/general'

const { Option } = Select

const SelectMunicipality = forwardRef(({ ...props }, ref) => {
  const [dataSource, setDataSource] = useState([])
  const [disableControls, setDisableControls] = useState(true)
  const { disabled, filter, state } = props

  useEffect(() => {
    const getData = async () => {
      let response = await GeneralAPI.getMunicipality(state)
      setDataSource(response)
      setDisableControls(false)
    }
    if (state && state !== '') {
      getData()
    }
  }, [state])

  return (
    <Select
      {...props}
      ref={ref}
      disabled={disabled === true || disableControls === true || state === ''}
    >
      {dataSource.map((item) => (
        <Option key={item.id} value={item.id} filter={filter}>
          {item.name}
        </Option>
      ))}
    </Select>
  )
})

export default SelectMunicipality
