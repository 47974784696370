import create from 'zustand'
import { persist } from 'zustand/middleware'

const useUser = create(
  persist(
    (set, get) => ({
      user: undefined,
      setUser: (user) => set({ user }),
    }),
    {
      name: 'pro-me-storage', // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
)

export default useUser
