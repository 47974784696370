import React from 'react'

// Own Components
import PropertyHouse from './PropertyHouse'
import PropertyApartment from './PropertyApartment'
import PropertyLot from './PropertyLot'
import PropertyCellar from './PropertyCellar'
import PropertyConsultingRoom from './PropertyConsultingRoom'
import PropertyBuilding from './PropertyBuilding'
import PropertyFarm from './PropertyFarm'
import PropertyEstate from './PropertyEstate'
import PropertyHotel from './PropertyHotel'
import PropertyShop from './PropertyShop'
import PropertyOffice from './PropertyOffice'
import PropertyIndustrialPark from './PropertyIndustrialPark'
import PropertyIndustrialPlant from './PropertyIndustrialPlant'
import PropertyRusticProperty from './PropertyRusticProperty'
import PropertyRanch from './PropertyRanch'

const GetPropertyType = (props) => {
  const { form, type } = props

  const renderPropertyType = (type) => {
    const propertyTypeInfo = {
      house: <PropertyHouse form={form} {...props} />,
      apartment: <PropertyApartment form={form} {...props} />,
      lot: <PropertyLot form={form} {...props} />,
      cellar: <PropertyCellar form={form} {...props} />,
      consultingRoom: <PropertyConsultingRoom form={form} {...props} />,
      building: <PropertyBuilding form={form} {...props} />,
      farm: <PropertyFarm form={form} {...props} />,
      estate: <PropertyEstate form={form} {...props} />,
      hotel: <PropertyHotel form={form} {...props} />,
      shop: <PropertyShop form={form} {...props} />,
      office: <PropertyOffice form={form} {...props} />,
      industrialPark: <PropertyIndustrialPark form={form} {...props} />,
      industrialPlant: <PropertyIndustrialPlant form={form} {...props} />,
      rustic: <PropertyRusticProperty form={form} {...props} />,
      ranch: <PropertyRanch form={form} {...props} />,
    }

    return propertyTypeInfo[type]
  }

  return renderPropertyType(type)
}

export default GetPropertyType
