// @ts-check
import React from 'react'
import { Formik, FieldArray, Form } from 'formik'
import SelectBox from '../../Components/Forms/SelectBox'
import NumberIncrement from '../../Components/Forms/NumberIncrement'
import { useHistory } from 'react-router-dom'
import acquisitionClient from '../../Services/acquisitionApiClient'
import * as Sentry from '@sentry/react'

const amenitiesArray = [
  { value: 'ac', name: 'Aire Acondicionado' },
  { value: 'pool', name: 'Alberca' },
  { value: 'furnished', name: 'Amueblado' },
  { value: 'gated', name: 'Coto' },
  { value: 'service_room', name: 'Cuarto de Servicio' },
  { value: 'study', name: 'Estudio' },
  { value: 'jacuzzi', name: 'Jacuzzi' },
  { value: 'office', name: 'Oficina' },
  { value: 'roof_garden', name: 'Roof Garden' },
]

export default function AmenitiesForm() {
  const history = useHistory()
  const setAmenities = (checked, value, amenities, arrayHelpers) => {
    if (checked) {
      arrayHelpers.push(value)
    } else {
      const i = amenities.indexOf(value)
      arrayHelpers.remove(i)
    }
  }

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      await acquisitionClient.patch(
        `/customers/properties/${localStorage.getItem('CYTpropertyUuid')}/`,
        values
      )
      setSubmitting(false)
      history.push('/unirse/descripcion')
    } catch (err) {
      setSubmitting(false)
      Sentry.captureException(err)
    }
  }

  return (
    <Formik
      initialValues={{
        amenities: [],
        rooms: 0,
        bathrooms: 0,
        halfBathrooms: 0,
      }}
      onSubmit={onSubmit}
      render={({ values, isSubmitting }) => (
        <Form className="w-full relative space-y-3">
          <FieldArray
            name="amenities"
            render={(arrayHelpers) => (
              <div className="w-full space-y-3 md:space-y-0 md:grid grid-cols-2 gap-3 overflow-y-auto">
                {amenitiesArray.map((amenity) => (
                  <>
                    <SelectBox
                      option={amenity}
                      callBack={(checked, value) =>
                        setAmenities(
                          checked,
                          value,
                          values.amenities,
                          arrayHelpers
                        )
                      }
                    />
                  </>
                ))}
              </div>
            )}
          />
          <div className="space-y-3">
            <NumberIncrement name="rooms" label="Habitaciones" />
            <NumberIncrement name="bathrooms" label="Baños" />
            <NumberIncrement name="halfBathrooms" label="Medios Baños" />
          </div>
          <div className="mt-3 col-span-2 w-full p-4 bg-white md:block md:p-0">
            <div
              className="hidden mb-10  md:flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-blue-900 group-hover:text-blue-800"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Continuar
            </button>
          </div>
        </Form>
      )}
    />
  )
}
