import { fetchHeaders } from './utils'
import log from '../Utils/logger'
import usePermissions from '../Hooks/usePermissions'

const { api: API_URL } = process.env.ENV
const STORAGE_LABEL = 'AccountPermissions'

class AccountPermissions {
  static async init() {
    let result
    const headers = await fetchHeaders()
    const clientPermissions = await fetch(`${API_URL}auth/permissions_user/`, {
      method: 'GET',
      headers,
    })
    switch (clientPermissions.status) {
      case 200:
        result = await clientPermissions.json()
        result.product = 'advertiser'
        localStorage.setItem(STORAGE_LABEL, JSON.stringify(result))
        return Promise.resolve(result)
      default:
        return Promise.reject()
    }
  }

  static clearPermissions() {
    localStorage.removeItem(STORAGE_LABEL)
  }

  static accessGranted(authorization, typeAuthorization) {
    try {
      const { state } = JSON.parse(localStorage.getItem(STORAGE_LABEL))
      if (state.permissions[authorization]) {
        return state.permissions[authorization].includes(typeAuthorization)
      } else {
        return false
      }
    } catch (err) {
      log.error(err)
      return false
    }
  }
}

export default AccountPermissions
