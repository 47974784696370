import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik'
import { message } from 'antd'
import SelectBox from '../../Components/Forms/SelectBox'
import usePromoters from '../../Hooks/usePromoters'
import NumberFormat from 'react-number-format'
import propertyConstants from '../../constants/properties'
import * as Yup from 'yup'
import apiClient from '../../Services/apiClient'
import log from '../../Utils/logger'
import useUser from '../../Hooks/useUser'

const internals = {
  transactionTypesArray: [
    { name: 'Venta', value: 1 },
    { name: 'Renta', value: 2 },
    { name: 'Traspaso', value: 3 },
  ],
  propertyTypes: propertyConstants.propertyTypes,
  setTransactionTypes: (checked, value, transactionTypes, arrayHelpers) => {
    if (checked) {
      arrayHelpers.push(value)
    } else {
      const i = transactionTypes.indexOf(value)
      arrayHelpers.remove(i)
    }
  },
}

const GeneralDescriptionSchema = Yup.object().shape({
  description: Yup.string().required('Agrega una descripción'),
  membership: Yup.number().integer().required('Selecciona un promotor'),
  property_type: Yup.number().required('Selecciona un tipo de propiedad'),
  operation_type: Yup.array().min(
    1,
    'Selecciona al menos un tipo de transacción'
  ),
  parking: Yup.number('Debe ser un número'),
  age: Yup.number('Debe ser un número'),
  sqr_mt_lot: Yup.number('Debe ser un número'),
  sqr_mt_construction: Yup.number('Debe ser un número'),
  bathrooms: Yup.number('Debe ser un número'),
  rooms: Yup.number('Debe ser un número'),
  half_bathrooms: Yup.number('Debe ser un número'),
  levels: Yup.number('Debe ser un número'),
  floors: Yup.number('Debe ser un número'),
  floor_number: Yup.number('Debe ser un número'),
  sqr_mt_office: Yup.number('Debe ser un número'),
  sqr_mt_cellar: Yup.number('Debe ser un número'),
  sqr_mt_front: Yup.number('Debe ser un número'),
  sqr_mt_long: Yup.number('Debe ser un número'),
  currency_sale: Yup.string(),
  currency_rent: Yup.string(),
  currency_transfer: Yup.string(),
  price_sale: Yup.number().integer('Debe ser un número entero'),
})

function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => (v != '' && v != null) || v === 0)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}

export default function GeneralDescription({ callBack, propertyId, action }) {
  const user = useUser((state) => state.user)
  const [propertyData, setPropertyData] = useState(null)
  const { data: promoters, error } = usePromoters({ status: 1, size: 100 })

  const activeMembership = user?.memberships[0]?.id

  async function onSubmit(values) {
    const cleanValues = removeEmpty(values)
    try {
      let response
      if (propertyData) {
        response = await apiClient.patch(`/property/${propertyId}`, {
          ...cleanValues,
        })
      } else {
        response = await apiClient.post(`/property`, {
          ...cleanValues,
        })
      }
      callBack(response)
    } catch (err) {
      log.error(err)
      message.error('Ocurrió un error al crear la propiedad')
    }
  }

  const cleanProperty = (data) => {
    const newObject = {
      ...data,
      operation_type: data.operation_type.map((o) => o.id),
      property_type: data.property_type.id,
      colony: data?.colony?.id,
      municipality: data?.municipality?.id,
      state: data?.state?.id,
      zip_code: '1231321',
    }
    return newObject
  }

  const getProperty = async (propertyId) => {
    try {
      const { data } = await apiClient.get(`property/${propertyId}`)

      const clean = cleanProperty(data)

      setPropertyData(clean)
    } catch (err) {
      log.error(err)
    }
  }

  useEffect(() => {
    if (propertyId) {
      getProperty(propertyId)
    }
  }, [])

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={GeneralDescriptionSchema}
      enableReinitialize={true}
      initialValues={
        propertyData
          ? { ...propertyData }
          : {
              property_type: 19,
              operation_type: [],
              membership: activeMembership,
              currency_sale: 'mxn',
              currency_rent: 'mxn',
              currency_transfer: 'mxn',
            }
      }
    >
      {({
        values,
        errors,
        touched,
        validationSchema,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form
          className="w-full space-y-6"
          data-splitbee-event={`properties-${action}-general`}
        >
          <div>
            <label htmlFor="description" className="form-label">
              Descripción <span className="text-red-600">*</span>
            </label>
            <Field
              className="input"
              as="textarea"
              rows="4"
              name="description"
              placeholder="Texto sobre ubicación, amenidades, cosas que hay alrededor, etc."
            ></Field>
            <ErrorMessage
              name="description"
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
            />
          </div>

          <div className="md:grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="membership" className="form-label">
                Promotor <span className="text-red-600">*</span>
              </label>
              {promoters && promoters.length && (
                <Field name="membership" as="select" className="select">
                  {promoters.map((promoter) => (
                    <option key={promoter.id} value={promoter.id}>
                      {promoter.first_name} {promoter.last_name}
                    </option>
                  ))}
                </Field>
              )}

              <ErrorMessage
                name="membership"
                render={(msg) => (
                  <div className="text-sm text-red-600">{msg}</div>
                )}
              />
            </div>

            <div>
              <label htmlFor="promoter" className="form-label">
                Tipo de propiedad <span className="text-red-600">*</span>
              </label>
              <Field as="select" name="property_type" className="select">
                {propertyConstants.propertyTypes.map((property) => (
                  <option
                    className="capitalize"
                    key={property.id}
                    value={parseInt(property.id, 10)}
                  >
                    {property.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="property_type"
                render={(msg) => (
                  <div className="text-sm text-red-600">{msg}</div>
                )}
              />
            </div>
          </div>

          <div>
            <label htmlFor="operation_type" className="form-label">
              Tipo(s) de transacción <span className="text-red-600">*</span>
            </label>
            <FieldArray
              name="operation_type"
              render={(arrayHelpers) => (
                <div className="w-full gap-2 space-y-3 md:grid md:grid-cols-3 md:space-y-0">
                  {internals.transactionTypesArray.map((transaction, index) => (
                    <SelectBox
                      key={index}
                      option={transaction}
                      fieldValue={values.operation_type}
                      callBack={(checked, value) =>
                        internals.setTransactionTypes(
                          checked,
                          value,
                          values.operation_type,
                          arrayHelpers
                        )
                      }
                    />
                  ))}
                </div>
              )}
            />
            <ErrorMessage
              name="operation_type"
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
            />
            <div className="space-y-3">
              {values.operation_type.includes(1) && (
                <div className="mt-3">
                  <label
                    htmlFor="price_sale"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Precio Venta
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center z-10">
                      <label htmlFor="currency_sale" className="sr-only">
                        Currency
                      </label>
                      <Field
                        as="select"
                        name="currency_sale"
                        className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                      >
                        <option value="mxn">MXN</option>
                        <option value="usd">USD</option>
                      </Field>
                    </div>
                    <NumberFormat
                      prefix={'$'}
                      placeholder="0.00"
                      isNumericString={true}
                      thousandSeparator={true}
                      value={values.price_sale}
                      onValueChange={(val) =>
                        setFieldValue('price_sale', val.floatValue)
                      }
                      className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full pl-16 pr-12  sm:text-sm md:text-lg  border-gray-300 rounded-md"
                    />
                  </div>
                  {errors.price_sale && touched.price_sale && (
                    <div className="text-sm text-red-600">
                      {errors.price_sale}
                    </div>
                  )}
                </div>
              )}
              {values.operation_type.includes(2) && (
                <div className="mt-3">
                  <label
                    htmlFor="price_rent"
                    className="block text-sm font-medium text-gray-700 "
                  >
                    Precio Renta
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center z-10">
                      <label htmlFor="currency_rent" className="sr-only">
                        Currency
                      </label>
                      <Field
                        as="select"
                        name="currency_rent"
                        className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                      >
                        <option value="mxn">MXN</option>
                        <option value="usd">USD</option>
                      </Field>
                    </div>
                    <NumberFormat
                      prefix={'$'}
                      placeholder="0.00"
                      isNumericString={true}
                      thousandSeparator={true}
                      value={values.price_rent}
                      onValueChange={(val) =>
                        setFieldValue('price_rent', val.floatValue)
                      }
                      className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full pl-16 pr-12  sm:text-sm md:text-lg  border-gray-300 rounded-md"
                    />
                  </div>
                  {errors.price_rent && touched.price_rent && (
                    <div className="text-sm text-red-600">
                      {errors.price_rent}
                    </div>
                  )}
                </div>
              )}
              {values.operation_type.includes(3) && (
                <div className="mt-3">
                  <label
                    htmlFor="price_transfer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Precio Traspaso
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center z-10">
                      <label htmlFor="currency_transfer" className="sr-only">
                        Currency
                      </label>
                      <Field
                        as="select"
                        name="currency_transfer"
                        className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                      >
                        <option value="mxn">MXN</option>
                        <option value="usd">USD</option>
                      </Field>
                    </div>
                    <NumberFormat
                      prefix={'$'}
                      placeholder="0.00"
                      isNumericString={true}
                      thousandSeparator={true}
                      value={values.price_transfer}
                      onValueChange={(val) =>
                        setFieldValue('price_transfer', val.floatValue)
                      }
                      className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full pl-16 pr-12  sm:text-sm md:text-lg  border-gray-300 rounded-md"
                    />
                  </div>
                  {errors.price_transfer && touched.price_transfer && (
                    <div className="text-sm text-red-600">
                      {errors.price_transfer}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="md:grid grid-cols-5 gap-4">
            {values.property_type &&
              propertyConstants.propertyTypes
                .find((property) => property.id == values.property_type)
                .requiredFields.map((field, id) => (
                  <div key={id}>
                    <label htmlFor={field} className="form-label">
                      {propertyConstants.fields[field]}{' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <Field
                      required
                      as="input"
                      type="number"
                      min="0"
                      className="input"
                      name={field}
                    ></Field>
                    <ErrorMessage
                      name={field}
                      render={(msg) => (
                        <div className="text-sm text-red-600">{msg}</div>
                      )}
                    />
                  </div>
                ))}

            {values.property_type &&
              propertyConstants.propertyTypes
                .find((property) => property.id == values.property_type)
                .fields.map((field, id) => (
                  <div key={id}>
                    <label htmlFor={field} className="form-label">
                      {propertyConstants.fields[field]}
                    </label>
                    <Field
                      as="input"
                      type="number"
                      min="0"
                      className="input"
                      name={field}
                    ></Field>
                    <ErrorMessage
                      name={field}
                      render={(msg) => (
                        <div className="text-sm text-red-600">{msg}</div>
                      )}
                    />
                  </div>
                ))}
          </div>

          <button
            disabled={isSubmitting}
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Continuar
          </button>
        </Form>
      )}
    </Formik>
  )
}

GeneralDescription.defaultProps = {
  callBack: (x) => {
    console.log(x)
  },
}
