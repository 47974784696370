import create from 'zustand'
import { persist } from 'zustand/middleware'

const useSession = create(
  persist(
    (set, get) => ({
      session: undefined,
      setSession: (session) => set({ session }),
    }),
    {
      name: 'pro-session', // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
)

export default useSession
