import React, { Fragment, useEffect } from 'react'
import { Form, InputNumber, Col } from 'antd'
import PropertyBasicInputs from './PropertyBasicInputs'

// Utils
import { validateIntegers } from '../../Shared/utils'

const { Item: FormItem } = Form

const PropertyApartment = (props) => {
  const { form, initialvalues } = props
  const { getFieldDecorator, setFieldsValue } = form
  useEffect(() => {
    if (initialvalues) {
      const { floor_number } = initialvalues
      setFieldsValue({
        floor_number,
      })
    }
  }, [initialvalues])

  return (
    <Fragment>
      <Col {...props}>
        <FormItem label="Número de piso">
          {getFieldDecorator('floor_number', {
            rules: [
              {
                required: true,
                message: 'Indica el número de piso donde se encuentra',
              },
              {
                validator: validateIntegers,
              },
            ],
          })(<InputNumber min={1} max={50} className="w-100" />)}
        </FormItem>
      </Col>
      <PropertyBasicInputs form={form} {...props} />
    </Fragment>
  )
}

export default PropertyApartment
