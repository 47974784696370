import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const SelectReports = React.forwardRef(({ ...props }, ref) => {
  return (
    <Select
      {...props}
      className="d-block"
      placeholder="Selecciona el reporte deseado."
      ref={ref}
    >
      <Option value="my_leads_report" data-splitbee-event="reports-leads-report">Leads</Option>
      <Option value="properties_list_report" data-splitbee-event="reports-property-report">Propiedades</Option>
    </Select>
  )
})

export default SelectReports
