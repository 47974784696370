import apiClient from '../../Services/apiClient'
import * as Sentry from '@sentry/react'

export default async function (data) {
  return new Promise((resolve, reject) => {
    apiClient
      .post('/aws_signature_url', {
        ...data,
      })
      .then((res) => {
        resolve({
          method: 'POST',
          url: res.data[0].url,
          fields: res.data[0].fields,
        })
      })
      .catch((err) => {
        Sentry.captureException(err)
        reject(err)
      })
  })
}
