import React from 'react'
import { Row, Col, Button, Form, Radio } from 'antd'

const { Item: FormItem } = Form
const { Group: RadioGroup, Button: RadioButton } = Radio

const DiscardedContacts = (props) => {
  const {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    resetFields,
  } = props.form
  const { onSubmit } = props
  const { id } = props.contact

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        values.status = 3
        onSubmit(values)
        resetFields()
      }
    })
  }

  getFieldDecorator('id', { initialValue: id })

  return (
    <Form onSubmit={submitForm} className="login-form" colon={false}>
      <Row type="flex" gutter={[48, 32]}>
        <Col xs={24}>
          <FormItem label="Razón de descarte">
            {getFieldDecorator('status_description', {
              rules: [
                { required: true, message: 'Ingresa la razón del descarte' },
              ],
            })(
              <RadioGroup className="w-100 text-center" buttonStyle="solid">
                <RadioButton className="w-50" value="No contestó" data-splitbee-event="contacts-discarded-lead-uncontacted">
                  No contestó
                </RadioButton>
                <RadioButton
                  className="w-50"
                  value="Datos de contacto incorrectos"
                  data-splitbee-event="contacts-discarded-lead-incorrect-data"
                >
                  Datos de contacto incorrectos
                </RadioButton>
              </RadioGroup>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button type="primary" htmlType="submit" data-splitbee-event="contacts-discarded-lead-save">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const DiscardedContactsWrapper = Form.create({})(DiscardedContacts)
export default DiscardedContactsWrapper
