import useSWR, { cache, mutate } from 'swr'
import apiClient from '../Services/apiClient'
import queryString from 'query-string'

function UseContacts(params) {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data)

  const { data, error } = useSWR(
    `/list_contact?${queryString.stringify(params)}`,
    fetcher
  )

  return { data, error }
}

export default UseContacts
