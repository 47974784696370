import React from 'react'
import PropTypes from 'prop-types'

export default function PictureAvatar({ img }) {
  return (
    <img className="inline-block h-14 w-14 rounded-full" src={img} alt="" />
  )
}

PictureAvatar.defaultProps = {
  img:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

PictureAvatar.propTypes = {
  img: PropTypes.string,
}
