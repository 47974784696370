import React from 'react'

// Own components
import DetailsContacts from './Details'
import ContactedContacts from './ContactedContacts'
import DiscardedContacts from './DiscardedContacts'

const ActionsContacts = (props) => {
  const { action, contact, onSubmit } = props

  const renderAction = (action) => {
    let actionRender
    if (action === 'details') {
      actionRender = <DetailsContacts contact={contact} />
    } else if (action === 'contacted') {
      actionRender = <ContactedContacts onSubmit={onSubmit} contact={contact} />
    } else if (action === 'discarded') {
      actionRender = <DiscardedContacts onSubmit={onSubmit} contact={contact} />
    } else {
      actionRender = <p>NO ACTION</p>
    }

    return actionRender
  }

  return renderAction(action)
}

export default ActionsContacts
