import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik'
import { message } from 'antd'
import UploadImages from '../../Components/Forms/UploadImages'
import apiClient from '../../Services/apiClient'
import config from './uppyConfigPro'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import log from '../../Utils/logger'
import * as Yup from 'yup'

export default function LocationForm({ propertyId, callBack, goBack, action }) {
  const [propertyData, setPropertyData] = useState({
    youtube_url: '',
    virtual_tour_url: '',
    images: [],
  })

  const cleanProperty = (data) => {
    const newObject = {
      images: data.images.map((i) => i.url),
      youtube_url: data.youtube_url || '',
      virtual_tour_url: data.virtual_tour_url || '',
    }
    return newObject
  }

  const getProperty = async (propertyId) => {
    try {
      const { data } = await apiClient.get(`property/${propertyId}`)
      setPropertyData(cleanProperty(data))
    } catch (err) {
      log.error(err)
    }
  }

  const useMemoizedGetProperty = useCallback(() => {
    getProperty(propertyId)
  }, [propertyId])

  useEffect(() => {
    useMemoizedGetProperty(propertyId)
    return () =>
      setPropertyData({
        youtube_url: '',
        virtual_tour_url: '',
        images: [],
      })
  }, [])

  async function onSubmit(values) {
    try {
      const newObject = {
        ...values,
        images: values.images.map((i) => {
          return { url: i }
        }),
      }
      const response = await apiClient.patch(
        `/property/${propertyId}`,
        newObject
      )
      callBack(response)
    } catch (err) {
      log.error(err)
      message.error('Ocurrió un error al crear la propiedad')
    }
  }

  const MediaSchema = Yup.object().shape({
    images: Yup.array().min(
      1,
      'Se debe agregar al menos una imagen para publicar la propiedad'
    ),
    youtube_url: Yup.string().matches(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
      'Ingresa una url de youtube válida'
    ),
    virtual_tour_url: Yup.string().url('Ingresa una url válida'),
  })

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialValues={propertyData}
      validationSchema={MediaSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form data-splitbee-event={`properties-${action}-upload`}>
          <div>
            <label htmlFor="description" className="form-title">
              Fotos
            </label>
            <FieldArray name="images">
              {({ move, swap, push, insert, unshift, pop, form, remove }) => {
                return (
                  <div className="p-3 space-y-3">
                    <ImageWithFieldArray push={push} propertyId={propertyId} />
                    {values.images.length > 0 && (
                      <p className="form-title">
                        Arrasta y define el orden de las imágenes
                      </p>
                    )}
                    <SortableArea
                      axis="xy"
                      items={values.images}
                      deleteImage={remove}
                      onSortEnd={({ oldIndex, newIndex }) =>
                        move(oldIndex, newIndex)
                      }
                      pressDelay={10}
                    />
                  </div>
                )
              }}
            </FieldArray>
            <ErrorMessage
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
              name="images"
            />
          </div>
          <div>
            <label htmlFor="youtube_url" className="form-label">
              Video en youtube
            </label>
            <Field
              as="input"
              type="text"
              name="youtube_url"
              className="input"
            ></Field>
            <ErrorMessage
              name="youtube_url"
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
            />
          </div>
          <div>
            <label htmlFor="virtual_tour_url" className="form-label">
              URL Recorrido virtual
            </label>
            <Field
              as="input"
              type="text"
              name="virtual_tour_url"
              className="input"
            ></Field>
            <ErrorMessage
              name="virtual_tour_url"
              render={(msg) => (
                <div className="text-sm text-red-600">{msg}</div>
              )}
            />
          </div>
          <div className="mt-5 grid grid-cols-2 gap-3 bg-gray-50 p-3 rounded">
            <div>
              <div
                onClick={goBack}
                data-splitbee-event={`properties-${action}-upload-back`}
                className="cursor-pointer group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md  bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              >
                Regresar
              </div>
            </div>
            <div className="text-right">
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Finalizar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const SortableArea = SortableContainer(
  ({ items, openEditor, markFirst, deleteImage }) => {
    return (
      <div className="md:grid md:grid-cols-2 lg:grid-cols-4 gap-3">
        {items.map((item, index) => (
          <SortableItem
            helperclassName={'SortableHelperWithOverride'}
            deleteImage={deleteImage}
            value={item}
            index={index}
            sortIndex={index}
            key={`${index}_${item}`}
            data-index={index}
          />
        ))}
      </div>
    )
  }
)

const SortableItem = SortableElement(({ value, sortIndex, deleteImage }) => {
  return (
    <li className="relative list-none  cursor-move select-none">
      <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group md:block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
        <img
          src={value}
          className="group-hover:opacity-75 object-cover pointer-events-none"
          onMouseDown={(e) => {
            // https://www.redips.net/firefox/disable-image-dragging/
            e.preventDefault()
          }}
        />
      </div>

      <button
        type="button"
        onClick={() => deleteImage(sortIndex)}
        className=" w-full items-center px-4 text-center py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <div className="pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-ml-1 h-5 w-5 text-gray-400 m-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </button>
    </li>
  )
})

const ImageWithFieldArray = ({ push, propertyId }) => {
  const uppyConfig = useMemo(() => {
    return config({ callBack: push, propertyId: propertyId })
  }, [propertyId])

  return (
    <UploadImages
      uppyConfiguration={uppyConfig}
      height={200}
      showSelectedFiles={false}
    />
  )
}

const MemoizedImageUpload = React.memo(
  ImageWithFieldArray,
  (prev, now) => false
)
