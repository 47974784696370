import jwt_decode from 'jwt-decode'
import log from '../Utils/logger'

export default function () {
  const urlToken = new URLSearchParams(window.location.search).get('token')
  const token = localStorage.getItem('acquisitionToken')
  if (urlToken) {
    localStorage.setItem('acquisitionToken', urlToken)
    try {
      var decoded = jwt_decode(urlToken)
      return decoded
    } catch (err) {
      log.error
    }
  }
  if (token) {
    try {
      var decoded = jwt_decode(token)
      return decoded
    } catch (err) {
      log.error
    }
  }
}
