export const formatNumberPrice = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatPriceInputNumber = (value) => {
  if (value) {
    if (value.length > 0) {
      return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
  return ''
}

export const parserPriceInputNumber = (value) =>
  value.replace(/\$\s?|(,*)/g, '')

export const imageToObject = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const fileType = file.name.split('.')
      resolve({
        name: file.name,
        base64: event.target.result,
        format: fileType[fileType.length - 1],
        content_type: file.type,
        originFile: file,
      })
    }
  })
}

export const currencyNumberParser = (value) => {
  return `$ ${parseFloat(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+\.)/g, '$&,')}`
}

export const formatImageList = (item, file, fileExt) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      item.name = file.name
      /* item.base64 = event.target.result; */
      item.format = fileExt[1] === 'jpeg' ? 'jpg' : fileExt[1]
      item.content_type = file.type
      resolve(item)
    }
  })
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const validateIntegers = async (rule, value, callback) => {
  // It validates values are integers or strings.
  if (value) {
    if (value.length > 0 || value.toString().length > 0) {
      if (value % 1 !== 0) {
        callback('No se permiten decimales.')
      }
    }
  }
  callback()
}

export const executivesRoles = {
  owner: 'Dueño',
  admin: 'Administrador',
  broker: 'Promotor',
}
