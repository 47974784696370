import React from 'react'
import logoColor from '../Shared/Images/logo-color-cyt.svg'

export function AcquisitionLayout(props) {
  return (
    <div>
      <div className="bg-gray-50 p-2 ">
        <img className="max-h-10 md:max-h-12" src={logoColor} alt="" />
      </div>
      {props.children}
    </div>
  )
}

const withAcquisitionLayout = (Component) => (
  <AcquisitionLayout>
    <Component />
  </AcquisitionLayout>
)

export default withAcquisitionLayout
