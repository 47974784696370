import React, { useEffect, useState, forwardRef } from 'react'
import { Select } from 'antd'

//Services
import { PromoterAPI } from '../../Services/promoter'

const { Option } = Select

const SelecPromoter = ({ ...props }, ref) => {
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let response = await PromoterAPI.getPromotersList()
      setDataSource(response)
    }
    fetchData()
  }, [])

  return (
    <Select {...props} ref={ref}>
      {dataSource.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.first_name} {item.last_name}
        </Option>
      ))}
    </Select>
  )
}

export default forwardRef(SelecPromoter)
