import React, { useState, useEffect, useRef } from 'react'
import { useCombobox } from 'downshift'
import acquisitionApiClient from '../../../Services/acquisitionApiClient'

async function getMunicipalities(input, setInput = (x) => console.log(x)) {
  if (input.length > 2) {
    const { data } = await acquisitionApiClient.get(
      '/customers/municipalities/search/',
      {
        params: {
          name: input,
        },
      }
    )
    const its = data.map((d) => d.name)
    setInput(its)
  } else {
    setInput([])
  }
}

export default function SelectMunicipality({ field, form, ...rest }) {
  const [inputItems, setInputItems] = useState([])

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      form.setFieldValue('city', inputValue)
      setInputItems(getMunicipalities(inputValue, setInputItems))
    },
  })

  return (
    <div className="relative">
      <div {...getComboboxProps()}>
        <input
          placeholder
          className="shadow-sm focus:ring-blue-800 focus:border-blue-800 block w-full sm:text-sm md:text-lg border border-gray-300 rounded-md px-3 py-2"
          {...field}
          {...getInputProps()}
        />
      </div>

      <ul
        {...getMenuProps()}
        className="absolute w-full inset-y-full z-50 bg-white"
      >
        {isOpen &&
          inputItems.length > 0 &&
          inputItems.map((item, index) => (
            <li
              className={`
                ${
                  highlightedIndex === index
                    ? 'bg-blue-800 text-white'
                    : 'bg-white'
                } p-2`}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  )
}

const Input = ({ inputRef }) => {
  const r = useRef(inputRef)
  return <input className="input" ref={r} />
}
