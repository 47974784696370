import React from 'react'
import { Row, Col, Button, Form, Input } from 'antd'

const { Item: FormItem } = Form

const EditPromoter = (props) => {
  const { getFieldDecorator, validateFields } = props.form
  const {
    id,
    first_name,
    last_name,
    second_last_name,
    email,
    phone_number,
    whatsapp,
  } = props.promoter
  const { onSubmit } = props

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  getFieldDecorator('id', { initialValue: id })

  return (
    <Form onSubmit={submitForm} className="login-form" colon={false} data-splitbee-event="promoters-edit-promoter-save">
      <Row type="flex" gutter={48}>
        <Col xs={24}>
          <FormItem label="Nombre(s)">
            {getFieldDecorator('first_name', {
              initialValue: first_name,
              rules: [{ required: true, message: 'Ingresa tu(s) nombre(s)' }],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Apellido Paterno">
            {getFieldDecorator('last_name', {
              initialValue: last_name,
              rules: [
                { required: true, message: 'Ingresa tu apellido paterno' },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Apellido Materno">
            {getFieldDecorator('second_last_name', {
              initialValue: second_last_name,
            })(<Input />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Correo electrónico">
            {getFieldDecorator('email', {
              initialValue: email,
              rules: [
                { required: true, message: 'Ingresa tu correo electrónico' },
                {
                  type: 'email',
                  message: 'Ingresa un correo electrónico válido.',
                },
              ],
            })(<Input disabled />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Whatsapp">
            {getFieldDecorator('whatsapp', {
              initialValue: whatsapp,
              rules: [
                { required: false, message: 'Número de whatsapp' },
              ],
            })(<Input  />)}
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label="Teléfono">
            {getFieldDecorator('phone_number', {
              initialValue: String(phone_number).replace(
                /\s|(\+[0-9][0-9])/g,
                ''
              ),
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Escribe el teléfono.',
                },
                {
                  required: true,
                  pattern: /^([0-9])(\w{9,9}\b)+$/,
                  message: 'El teléfono no es válido.',
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const EditPromoterWrapper = Form.create({})(EditPromoter)
export default EditPromoterWrapper
