import React from 'react'
// Own components
import DetailsProperty from './Details'
import ActiveInactiveProperty from './ActiveInactive'
import StatisticProperty from './Statistic'

const ActionsProperties = (props) => {
  const { action, property, onSubmit } = props

  const renderAction = (action) => {
    let actionRender
    if (action === 'details') {
      actionRender = <DetailsProperty property={property} />
    } else if (action === 'statistic') {
      actionRender = <StatisticProperty property={property} />
    } else if (action === 'inactive' || action === 'active') {
      actionRender = (
        <ActiveInactiveProperty
          action={action}
          property={property}
          onSubmit={onSubmit}
        />
      )
    } else {
      actionRender = <p>NO ACTION</p>
    }

    return actionRender
  }

  return renderAction(action)
}

export default ActionsProperties
