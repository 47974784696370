import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Form, Upload, Modal, Button, Carousel } from 'antd'
import styled from 'styled-components'

const CarouselStyled = styled(Carousel)`
  min-height: 160px;
  .slick-dots {
    bottom: 25px;
  }
  .slick-dots li button {
    width: 25px;
    height: 5px;
  }
  .slick-dots li.slick-active button {
    width: 40px;
    background: #1a3e6c;
  }
  & img {
    height: auto;
  }
`

const { Item: FormItem } = Form

const MultimediaDetailsProperty = (props) => {
  const carouselRef = useRef(null)
  const { images } = props.data || ''
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    const newFileList = Object.keys(images).map((key) => {
      let url = images[key].url
      let uid = images[key].id
      return { uid, url }
    })
    setFileList(newFileList)
  }, [])

  const handlePreview = async (file) => {
    setPreviewImage(file.url)
    setPreviewVisible(true)
    let carousel = await carouselRef
    let idx = fileList.findIndex((item) => item.url === file.url)
    carousel.current.goTo(idx)
  }

  const handleCancel = () => setPreviewVisible(false)
  const handleBeforeUpload = () => false

  return (
    <div className="mx-4 my-4">
      <Row type="flex" justify="center" className="mb-3">
        <Col>
          <FormItem
            label="Imagenes"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="d-block mt-2">
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                beforeUpload={handleBeforeUpload}
                showUploadList={{
                  showPreviewIcon: true,
                  showDownloadIcon: false,
                  showRemoveIcon: false,
                }}
              />
            </span>
          </FormItem>
        </Col>

        <Modal
          visible={previewVisible}
          centered
          closable={false}
          footer={null}
          onCancel={handleCancel}
        >
          <CarouselStyled ref={carouselRef}>
            {fileList.length > 0 &&
              fileList.map((item) => {
                return (
                  <div key={item.uid}>
                    <img
                      alt="example"
                      style={{ width: '100%' }}
                      src={item.url}
                    />
                  </div>
                )
              })}
          </CarouselStyled>
          <Row type="flex" justify="end" className="mt-2">
            <Col>
              <Button type="primary" onClick={handleCancel}>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal>
      </Row>
    </div>
  )
}

export default MultimediaDetailsProperty
