import React from 'react'
import SelectPlan from '../Packages/forms/select_plan'

export default function Renovacion() {
  return (
    <div className="mt-5">
      <SelectPlan />
    </div>
  )
}
