import React from 'react'
import PropertyBasicInputs from './PropertyBasicInputs'

const PropertyHouse = (props) => {
  const { form } = props

  return <PropertyBasicInputs form={form} {...props} />
}

export default PropertyHouse
