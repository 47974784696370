import useSWR from 'swr'
import apiClient from '../Services/apiClient'

function useStates() {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data?.results)

  const { data, error } = useSWR(`/locations/state/`, fetcher)

  return { data, error }
}

export default useStates
