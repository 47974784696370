import React, { useEffect, useState } from 'react'

import log from '../Utils/logger'
import ModalStyled from '../Shared/Common/Modal'
import Modal from '../Components/Overlays/Modal'
import apiClient from '../Services/apiClient'

import { IntegrationsAPI } from '../Services/integrations'
import { useSWRConfig } from 'swr'
import FeedForm from './forms/feed'

export default function index() {
  const { mutate } = useSWRConfig()
  const [openModal, setOpenModal] = useState(false)
  const { data: dataCRM, error } = IntegrationsAPI.getCRM()

  return (
    <div className="mt-10 max-w-screen-xl m-auto bg-white p-5">
      <div className="md:flex md:items-center md:justify-between ">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Integraciones
          </h2>
          <p>
            En esta sección podrás agregar y editar la integración a tu CRM
            inmobiliario para sincronizar inventario.
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          {dataCRM?.feed_name ? null : (
            <button
              onClick={setOpenModal}
              type="button"
              className="button-primary-mid"
              data-splitbee-event="integrations-add"
            >
              Agregar
            </button>
          )}
        </div>
      </div>
      {dataCRM?.feed_name ? (
        <Integration
          name={dataCRM?.feed_name}
          feedId={dataCRM?.feed}
          setOpenModal={setOpenModal}
        />
      ) : null}
      <ModalStyled
        visible={openModal}
        destroyOnClose={true}
        title="Editar integracion"
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <FeedForm
          initialValues={dataCRM || {}}
          callBack={() => {
            setOpenModal(false)
            mutate('clients/pro/link/crm/')
          }}
        />
      </ModalStyled>
    </div>
  )
}

function Integration({ name, lastSync, feedId, setOpenModal }) {
  const { mutate } = useSWRConfig()
  const [viewModal, toggleModal] = useState(false)
  const [count, setCount] = useState('')
  const removeIntegration = () => {
    IntegrationsAPI.deleteCRM({})
    mutate('clients/pro/link/crm/')
  }

  async function getFeedProperties() {
    try {
      const { data: properties } = await apiClient.get(
        `/property?feed=${feedId}&status=published`
      )
      setCount(properties.count)
    } catch (err) {
      log.error(err)
    }
  }

  useEffect(() => {
    getFeedProperties()
  }, [])

  return (
    <div>
      <ul role="list" className="py-4 space-y-2  sm:space-y-4 ">
        <li className="bg-white px-4 py-6 border border-gray-800 hover:shadow sm:rounded-lg sm:px-6 space-y-3">
          <div>
            <div className="sm:flex sm:justify-between sm:items-baseline">
              <h3 className="text-2xl uppercase font-medium">
                <span className="text-gray-900">{name}</span>
              </h3>
              <p className="mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3">
                <button
                  type="button"
                  data-splitbee-event="integrations-edit-integration"
                  className="inline-flex items-center p-3 border border-transparent rounded-md shadow-sm text-white bg-blue-cyt hover:bg-blue-cyt-alt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-cyt-alt"
                  onClick={() => setOpenModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                  Editar
                </button>
              </p>
            </div>
            {/* TBD */}
            {/* <p>Ultima actualización hoy a las 9:00hrs</p> */}
            <p>{count} propiedades sincronizadas activas</p>
          </div>

          <div className="sm:flex sm:justify-between sm:items-baseline">
            <button
              onClick={() => toggleModal(true)}
              className="button-error-mid"
              data-splitbee-event="integrations-delete-integration"
            >
              Remover Integración
            </button>
          </div>
        </li>
      </ul>
      <Modal
        open={viewModal}
        title={'Eliminar integración'}
        description="¿Estás seguro de querer eliminar esta integración? Esta acción no puede ser revertida"
        setOpen={toggleModal}
        cta="Eliminar"
        callBack={() => {
          removeIntegration()
          toggleModal(false)
          mutate('clients/pro/link/crm/')
        }}
      />
    </div>
  )
}
