import React from 'react'
import Register from './register_form'
import CircleSteps from '../Components/Navigation/CircleSteps'
import ProgressBar from '../Components/Navigation/ProgressBar'

const inmobiliariaSteps = [
  {
    name: 'Tipo de cuenta',
    description: 'Inmobiliaria o agente independiente',
    href: '#',
    status: 'current',
  },
  {
    name: 'Propiedades',
    description: 'Define para preparar tu plan',
    href: '#',
    status: 'upcoming',
  },
  {
    name: 'Pago',
    description: 'Contrata tu plan y accede a PRO',
    href: '#',
    status: 'upcoming',
  },
]

const particularSteps = [
  {
    name: 'Tipo de cuenta',
    description: 'Particular',
    href: '#',
    status: 'current',
  },
  {
    name: 'Ubicación y precio',
    description: 'Define el tipo de inmueble y operación',
    href: '#',
    status: 'upcoming',
  },
  {
    name: 'Amenidades',
    description: '¿Qué pueden encontrar en la propiedad?',
    href: '#',
    status: 'upcoming',
  },
  {
    name: 'Descripción y fotos',
    description: 'Detalles más específicos',
    href: '#',
    status: 'upcoming',
  },
]

export default function Index() {
  return (
    <div className="relative">
      <div className="md:hidden bg-gray-100 p-3 space-y-1 ">
        <p className="text-center leading-4 font-semibold uppercase">
          UBICACIÓN Y PRECIO
        </p>
        <p className="text-center">Define el tipo de inmueble y operación</p>
        <ProgressBar
          config={{
            step: 1,
            total: 5,
          }}
        />
      </div>
      <div className="md:grid grid-cols-3  ">
        <div className="min-h-screen hidden md:block col-span-1 bg-gray-100 pt-16 ">
          <div className="block w-3/4 m-auto space-y-3">
            <p className="text-5xl text-black leading-none font-extrabold">
              Publica en Casas y Terrenos
            </p>
            <p className="text-lg leading-6 font-semibold tracking-wider uppercase">
              Toma al rededor de 7 minutos
            </p>
            <div className="hidden md:block">
              <CircleSteps steps={inmobiliariaSteps} />
            </div>
          </div>
        </div>
        <div className="col-span-2 block ">
          <div className="flex md:min-h-screen w-full md:w-3/4 lg:w-2/3 m-auto pt-16 px-5 ">
            <Register />
          </div>
        </div>
      </div>
    </div>
  )
}
