import getBase64 from './getBase64Img'
import numberWithCommas from './commaSeparatedNumber'
import moment from 'moment'

const { MAPBOX_API_KEY } = process.env.ENV

export default async function getDocumentDefinition(propertyData, userData) {
  const {
    street,
    neighborhood,
    currency,
    municipality,
    state,
    prioritizedPrices,
    description,
    coords,
    features,
    images,
    land,
    typeOfProperty,
    amenities,
    canonical,
    id,
  } = propertyData

  const {
    email,
    phone_contact,
    phone_number,
    contact,
    first_name,
    last_name,
    second_last_name,
    business_name,
    logo_client,
  } = userData

  const b64Imgs = await Promise.all(
    images.map(async (image) => getBase64(image.original))
  )
  const footerImage = await getBase64(
    'https://cyt-media.s3-us-west-2.amazonaws.com/static/bottom_pdf.png'
  )
  const headerImage = await getBase64(
    'https://cyt-media.s3-us-west-2.amazonaws.com/static/cyt_blue.png'
  )
  const roomImage = await getBase64(
    'https://cyt-media.s3-us-west-2.amazonaws.com/static/room.png'
  )
  const bathroomImage = await getBase64(
    'https://cyt-media.s3-us-west-2.amazonaws.com/static/bathroom.png'
  )
  const parkingImage = await getBase64(
    'https://cyt-media.s3-us-west-2.amazonaws.com/static/parking.png'
  )
  const areaImage = await getBase64(
    'https://cyt-media.s3-us-west-2.amazonaws.com/static/area.png'
  )
  const constructionImage = await getBase64(
    'https://cyt-media.s3-us-west-2.amazonaws.com/static/construction.png'
  )
  const mapboxRequest = await getBase64(
    `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+0D47A1(${coords.lng},${coords.lat})/${coords.lng},${coords.lat},13.66,0/700x350@2x?access_token=pk.eyJ1IjoiaG5zaGFsbGVyIiwiYSI6ImNrMGw2bTZtdzB0NjIzanNicnhsbDNpdnkifQ.hqXs2Lq1M5cbfSXW9x9PZg`
  )
  const logo = logo_client && (await getBase64(logo_client))

  const rows = [
    {
      columnGap: 10,
      margin: [5, 15],
      columns: [],
    },
  ]

  const base64Images = b64Imgs.slice(0, 12)

  for (let i = 0; i < base64Images.length; i += 1) {
    if (rows[rows.length - 1].columns.length === 2) {
      // new row
      rows.push({
        columnGap: 10,
        margin: [5, 15],
        columns: [
          {
            image: `data:image/jpeg;base64,${base64Images[i]}`,
            width: 250,
          },
        ],
      })
    } else {
      // push to row
      rows[rows.length - 1].columns.push({
        image: `data:image/jpeg;base64,${base64Images[i]}`,
        width: 250,
      })
    }
  }

  return {
    footer: (currentPage, pageCount, pageSize) => {
      let footerContent = []
      if (currentPage !== 1) {
        footerContent = [
          {
            text: `${typeOfProperty} en  ${neighborhood}, ${municipality}, ${state}`,
            fontSize: 10,
            margin: [20, 0],
          },
          {
            ...(prioritizedPrices.venta && {
              text: `Venta: $ ${numberWithCommas(
                prioritizedPrices.venta
              )} ${currency}`,
              fontSize: 10,
              margin: [20, 0],
            }),
          },
          {
            ...(prioritizedPrices.renta && {
              text: `Renta: $ ${numberWithCommas(
                prioritizedPrices.renta
              )} ${currency}`,
              fontSize: 10,
              margin: [20, 0],
            }),
          },
          {
            ...(prioritizedPrices.traspaso && {
              text: `Traspaso: $ ${numberWithCommas(
                prioritizedPrices.traspaso
              )} ${currency}`,
              fontSize: 10,
              margin: [20, 0],
            }),
          },
        ]
      }
      return footerContent
    },
    header: (currentPage, pageCount) => {
      return [
        {
          image: `data:image/jpeg;base64,${headerImage}`,
          width: 200,
          height: 27,
          alignment: 'right',
          margin: [5, 20],
        },
      ]
    },
    content: [
      {
        columns: [
          {
            stack: [
              {
                text: `${typeOfProperty} en  ${neighborhood}, ${municipality}, ${state} \n\n`,
                style: 'h1',
              },
              { text: `${moment().format('l')} \n\n` },
              { text: `Id: ${id} \n\n`, style: 'h4' },
              { text: `Precio:\n`, style: 'h3' },
              {
                ...(prioritizedPrices.venta && {
                  text: `Venta: $ ${numberWithCommas(
                    prioritizedPrices.venta
                  )} ${currency} \n\n`,
                  fontSize: 10,
                }),
              },
              {
                ...(prioritizedPrices.renta && {
                  text: `Renta: $ ${numberWithCommas(
                    prioritizedPrices.renta
                  )} ${currency} \n\n`,
                  fontSize: 10,
                }),
              },
              {
                ...(prioritizedPrices.traspaso && {
                  text: `Traspaso: $ ${numberWithCommas(
                    prioritizedPrices.traspaso
                  )} ${currency} \n\n\n\n`,
                  fontSize: 10,
                }),
              },
              {
                text: `Ver propiedad en casasyterrenos.com  \n\n\n\n`,
                link: `https://casasyterrenos.com${canonical}`,
                style: 'link',
              },
            ],
          },
          {
            stack: [
              {
                columns: [
                  {
                    stack: [
                      {
                        image: `data:image/jpeg;base64,${roomImage}`,
                        width: 25,
                        alignment: 'center',
                      },
                      { text: `${features.rooms}`, alignment: 'center' },
                    ],
                  },
                  {
                    stack: [
                      {
                        image: `data:image/jpeg;base64,${bathroomImage}`,
                        width: 25,
                        alignment: 'center',
                      },
                      { text: `${features.bathrooms}`, alignment: 'center' },
                    ],
                  },
                  {
                    stack: [
                      {
                        image: `data:image/jpeg;base64,${parkingImage}`,
                        width: 25,
                        alignment: 'center',
                      },
                      { text: `${features.parking}`, alignment: 'center' },
                    ],
                  },
                ],
                style: 'h4',
              },
              {
                columns: [
                  {
                    stack: [
                      {
                        image: `data:image/jpeg;base64,${areaImage}`,
                        width: 25,
                        alignment: 'center',
                      },
                      { text: `${features.area}mts2`, alignment: 'center' },
                    ],
                  },
                  {
                    stack: [
                      {
                        image: `data:image/jpeg;base64,${constructionImage}`,
                        width: 25,
                        alignment: 'center',
                      },
                      {
                        text: `${features.construction}mts2`,
                        alignment: 'center',
                      },
                    ],
                  },
                ],
                style: 'h4',
                margin: [0, 20],
              },
            ],
            margin: [5, 30],
          },
        ],
      },
      {
        columns: [
          {
            image: `data:image/jpeg;base64,${base64Images[0]}`,
            width: 300,
          },
          {
            stack: [
              { text: 'Descripción: \n\n', style: 'h3', margin: [0, 5] },
              {
                text: description.replace(/<[^>]*>?/gm, '').trim(),
                margin: [0, 10],
                fontSize: 10,
                lineHeight: 1.2,
              },
              {
                ul: [...amenities],
                fontSize: 10,
                margin: [0, 20],
              },
            ],
          },
        ],
        columnGap: 10,
      },
      {
        text: 'Galería',
        style: 'h2',
        margin: [0, 10],
        pageBreak: 'before',
      },
      ...rows,
      {
        text: 'Ubicación: \n\n',
        style: 'h2',
        margin: [0, 5],
        pageBreak: 'before',
      },
      {
        image: `data:image/png;base64,${mapboxRequest}`,
        width: 500,
        alignment: 'center',
      },
      {
        text: [
          { text: `${street}, ${neighborhood}, \n\n` },
          { text: `${municipality}, ${state}` },
        ],
        margin: [0, 20],
      },
      {
        text: 'Información de contacto',
        style: 'h2',
      },
      {
        columns: [
          {
            stack: [
              {
                text: `${first_name} ${last_name} ${second_last_name}`,
                margin: 5,
              },
              { text: email && `Correo electrónico: ${email}`, margin: 5 },
              {
                text: contact.mobile && `Celular: ${contact.mobile}`,
                margin: 5,
              },
              {
                text: contact.whatsapp && `Whatsapp: ${contact.whatsapp}`,
                margin: 5,
              },
            ],
          },
          {
            ...(logo && {
              image: `data:image/png;base64,${logo}`,
              width: 200,
              alignment: 'left',
            }),
          },
        ],
        alignment: 'left',
      },
    ],
    defaultStyle: {
      font: 'Roboto',
      fontSize: 10,
    },
    styles: {
      h1: {
        fontSize: 18,
        bold: true,
      },
      link: {
        color: '#00428A',
        bold: true,
      },
      h2: {
        fontSize: 15,
        bold: true,
      },
      h3: {
        fontSize: 13,
        bold: true,
      },
      h4: {
        fontSize: 10,
      },
      anotherStyle: {
        italics: true,
        alignment: 'right',
      },
      defaultStyle: {
        fontSize: 10,
        lineHeight: 2,
      },
    },
  }
}
