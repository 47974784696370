import React from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'

const { maps: MAPSKEY } = process.env.ENV.google

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`

const Marker = (props) => (
  <Wrapper
    alt={props.text}
    {...(props.onClick ? { onClick: props.onClick } : {})}
  />
)

const MapLocation = (props) => {
  const { latitude, longitude } = props

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '200px', width: '100%' }}>
      {latitude && longitude ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPSKEY }}
          defaultCenter={{ lat: latitude, lng: longitude }}
          defaultZoom={15}
        >
          <Marker lat={latitude} lng={longitude} text="My Marker" />
        </GoogleMapReact>
      ) : null}
    </div>
  )
}

export default MapLocation
