import React, { useState, useEffect } from 'react'
import { DownloadIcon } from '@heroicons/react/solid'
import moment from 'moment'
import { currencyNumberParser } from '../Shared/utils'

// Services
import { ReceiptsAPI } from '../Services/receipts'

// Own components
import OutsideLayout from '../Layouts/OutsideLayout'
import PageHeaderStyled from '../Shared/Common/PageHeader'

const formatDate = 'YYYY-MM-DD'

const Receipts = (props) => {
  const [receiptsData, setReceiptsData] = useState([])

  const getReceipts = async () => {
    try {
      const response = await ReceiptsAPI.getReceipts()
      if (response.data.results) {
        setReceiptsData(response.data.results)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getReceipts()
  }, [])

  return (
    <OutsideLayout paddingX={2} paddingY={2} marginX={2} marginY={2}>
      <PageHeaderStyled title="Recibos" className="flex mt-16 ml-16" />
      <div className="flex ml-24">
        Recuerda que solo tienes hasta el fin del mes en el que realizaste tú
        compra para solicitar la factura correspondiente. De lo contrario solo
        estará disponible el recibo.
      </div>
      <div className=" mx-24 my-4">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        FECHA
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        NÚMERO DE FOLIO
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        TIPO DE PRODUCTO
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        COSTO
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiptsData.map((receipt) => (
                      <tr key={receipt.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {moment(receipt.created).format(formatDate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {receipt.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {receipt.type === 'receipt' ? 'Recibo' : 'Factura'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {currencyNumberParser(receipt.total)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {receipt.receipt_id && (
                            <a
                              href={`https://factura.space/casasyterrenos/${receipt.receipt_id}`}
                              className="hover:text-indigo-900 hover:underline"
                              style={{ textColor: 'rgb(2, 0, 36)' }}
                              data-splitbee-event="receipts-get-invoice"
                            >
                              Solicitar Factura
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideLayout>
  )
}

export default Receipts
