import React from 'react'

// Own components
import CreatePromoter from './Create'
import EditPromoter from './Edit'
import DeletePromoter from './Delete'
import ActiveInactivePromoter from './ActiveInactive'

const ActionsPromoter = (props) => {
  const { action, promoter, onSubmit, onCancel } = props

  const renderAction = (action) => {
    let actionRender
    if (action === 'edit') {
      actionRender = <EditPromoter promoter={promoter} onSubmit={onSubmit} />
    } else if (action === 'create') {
      actionRender = <CreatePromoter onSubmit={onSubmit} />
    } else if (action === 'delete') {
      actionRender = (
        <DeletePromoter
          promoter={promoter}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )
    } else if (action === 'inactive' || action === 'active') {
      actionRender = (
        <ActiveInactivePromoter
          action={action}
          promoter={promoter}
          onSubmit={onSubmit}
        />
      )
    }

    return actionRender
  }

  return renderAction(action)
}

export default ActionsPromoter
