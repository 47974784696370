import axios from 'axios'
const { apiUrl } = process.env.ENV

const axiosInstance = axios.create({
  baseURL: apiUrl,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('acquisitionToken')
      if (token) config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
