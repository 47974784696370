export function normalizeDevelopmentDataResponse(data, prototypes) {
  Object.keys(data).map((key) => {
    const keyValue = data[key]
    if (
      keyValue === 'None' ||
      keyValue === '0' ||
      keyValue === '0.0' ||
      keyValue === null
    ) {
      data[key] = ''
    }
  })

  function getConstructionArray() {
    return prototypes.map((p) => p.sqr_mt_construction)
  }

  function getAreaArray() {
    return prototypes.map((p) => p.sqr_mt_lot)
  }

  const formated = {
    agencyLogo: data.client.logo_client,
    agencyName: data.client.business_name,
    canonical: data.slug,
    perfilamiento: data.profiled,
    contactData: {
      tel: data.executive.phone_number || data.client.phone_number || '',
      agencyLogo: data.client.logo_client || '',
      agencyName: data.client.business_name || '',
      email: data.executive.email || data.client.email_contact || '',
    },
    coords: {
      lat: parseFloat(data.get_latitude),
      lng: parseFloat(data.get_longitude),
    },
    construction: {
      from: data.construction_from,
      to: data.construction_up,
    },
    county: data.state.name,
    coverImage: {},
    description: data.description,
    shortDescription: 'short',
    directions: data.how_to_get,
    features: {
      amenities: data.amenities.map((a) => a.name),
      security: [],
    },
    galleryImages: [],
    id: data.id,
    logo: data.client.logo_client,
    municipality: data.municipality.name,
    name: data.name,
    neighborhood: data.colony.name,
    price: {
      from: data.price_start,
      to: data.price_end,
      currency: data.currency,
    },
    fromRooms: 1,
    toRooms: 5,
    typeOfProperty: prototypes[0]
      ? prototypes[0].property_type.singular_name
      : 'propiedad',
    nbPrototypes: data.prototype_dev.length,
    prototypes: data.prototype_dev,
    security: [],
    slogan: '',
    status: data.status === 1,
    street: data.street,
    terrain: {
      from: Math.min(...getAreaArray()),
      to: Math.max(...getAreaArray()),
    },
    video: data.youtube_url,
    virtualTour: data.virtual_tour_url,
  }

  data.logo_red.forEach((image) => {
    formated.galleryImages.push({
      original: image.url,
      originalAlt: `${data.name} ${image.order}`,
    })
    return true
  })

  formated.coverImage = formated.galleryImages[0] || ''

  return formated
}

export function normalizePropertyDataResponse(data) {
  Object.keys(data).map((key) => {
    const keyValue = data[key]
    if (
      keyValue === 'None' ||
      keyValue === '0' ||
      keyValue === '0.0' ||
      keyValue == null
    ) {
      data[key] = ''
    }
  })

  const formated = {
    canonical: data.slugs.canonical,
    construction: data.sqr_mt_construction,
    coords: {
      lat: parseFloat(data.get_latitude),
      lng: parseFloat(data.get_longitude),
    },
    state: data.state.name,
    description: data.description,
    externalNumber: data.exterior_number,
    features: {
      area: data.sqr_mt_lot,
      construction: data.sqr_mt_construction,
      bathrooms: data.bathrooms + data.half_bathrooms / 2,
      levels: data.levels,
      parking: data.parking,
      rooms: data.rooms,
    },
    amenities: data.amenities,
    images: [],
    id: data.id,
    land: data.sqr_mt_lot,
    municipality: data.municipality ? data.municipality.name : '',
    neighborhood: data.colony ? data.colony.name : '',
    prioritizedPrices: {
      venta: data.price_sale,
      renta: data.price_rent,
      traspaso: data.price_transfer,
    },
    isPublished: data.status === 'published',
    currency: data.currency_sale,
    seller: data.membership,
    street: data.street,
    sellingPrice: data.price_sale,
    typeOfProperty: data.property_type.singular_name,
    typeOfPropertyCode: data.property_type.id,
    video: data.youtube_url
      ? data.youtube_url.replace('watch?v=', 'embed/')
      : '',
    virtualTour: data.virtual_tour_url,
  }

  data.url_property_image.forEach((image, key) => {
    if (
      image.url.toLowerCase().includes('.png') ||
      image.url.toLowerCase().includes('.jpeg') ||
      image.url.toLowerCase().includes('.jpg')
    ) {
      formated.images.push({
        original: image.url,
        originalAlt: `${data.name} ${key}`,
      })
    }
  })

  return formated
}
