import React from 'react'

function Card({ title, subtitle, icon, link }) {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg flex flex-grow items-center p-2">
      <div className="">
        <div className="flex items-center">
          <div className="flex-shrink-0 ">
            <div className="text-2xl font-bold text-gray-900">{subtitle}</div>
          </div>
          <div>
            <dl>
              <dt className="ml-3 text-sm font-medium text-gray-500 ">
                {title}
              </dt>
            </dl>
          </div>
        </div>
      </div>
      {link?.title && (
        <div className="bg-gray-50 px-5 py-3">
          <div className="text-sm">
            <a
              href={link.href}
              className="font-medium text-cyan-700 hover:text-cyan-900"
            >
              {link.title}
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

Card.defaultProps = {
  title: '',
  subtitle: '',
  icon: null,
  link: {
    title: null,
    href: '',
  },
}

export default Card
