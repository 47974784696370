import axios from 'axios'
import { fetchHeaders } from './utils'

export const ContactsAPI = {
  getContacts,
  updateContact,
}

const { api: API_URL } = process.env.ENV

async function getContacts(params) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    params,
    url: `${API_URL}list_contact/`,
  }).then(
    (response) => {

      let { data } = response

      return data
    },
    (error) => {
      console.log(error)
    }
  )
}

async function updateContact(contactID, data) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'PATCH',
    url: `${API_URL}contact/${contactID}`,
    data,
  }).then(
    (response) => {
      let { data } = response
      return data
    },
    (error) => {
      console.log(error)
    }
  )
}
