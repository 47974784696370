import React, { useEffect, useState, forwardRef } from 'react'
import { Select } from 'antd'

//Services
import { GeneralAPI } from '../../Services/general'

const { Option } = Select

const SelectNeighborhood = forwardRef(({ ...props }, ref) => {
  const [dataSource, setDataSource] = useState([])
  const [disableControls, setDisableControls] = useState(true)
  const { disabled, filter, municipality } = props

  useEffect(() => {
    const getData = async () => {
      let response = await GeneralAPI.getNeighborhoods(municipality)
      setDataSource(response)
      setDisableControls(false)
    }
    if (municipality && municipality !== '') {
      getData()
    }
  }, [municipality])

  return (
    <Select
      {...props}
      ref={ref}
      disabled={
        disabled === true || disableControls === true || municipality === ''
      }
    >
      {dataSource.map((item) => (
        <Option key={item.id} value={item.id} filter={filter}>
          {item.name}
        </Option>
      ))}
    </Select>
  )
})

export default SelectNeighborhood
