/*global Stripe*/
import React, { useEffect, useState } from 'react'
import splitbee from '@splitbee/web'
import apiClient from '../../Services/apiClient'
import useScript from '../../Hooks/useScript'
import numberWithCommas from '../../Utils/commaSeparatedNumber'

export default function PlanSelection() {
  const [stripe, setStripe] = useState(null)
  const [products, setProducts] = useState([])
  const onLoadStripe = () => {
    setStripe(Stripe(process.env.ENV.STRIPE_PUBLIC_KEY))
  }
  useScript('https://js.stripe.com/v3/', onLoadStripe)

  const checkout = async (id, name) => {
    let { data } = await apiClient.post('products/checkout/', {
      price_id: id,
    })

    splitbee.track(`account-redirect-checkout-${name}`)

    stripe.redirectToCheckout({ sessionId: data.id })
  }

  const getPackages = async () => {
    try {
      const p = await apiClient.get('products/?external_processor=stripe')
      setProducts(p.data)
    } catch (err) {}
  }

  useEffect(() => {
    getPackages()
  }, [])

  return (
    <div className="m-auto max-w-screen-md">
      {products.map((product) => (
        <div className="grid grid-cols-2 gap-4 bg-white   mb-2 rounded shadow w-full p-3 ">
          <div>
            <p className="text-2xl font-bold">{product.name}</p>
            <p>{product.description}</p>
          </div>

          <div>
            {product?.prices?.map((price) => (
              <div
                onClick={() => checkout(price.external_id, `${product?.name?.toLowerCase()}-${price.name}`)}
                key={price.id}
                data-splitbee-event={`account-renew-${product?.name?.toLowerCase()}-${price.name}`}
                className="mb-2 shadow flex justify-between p-2 border-2 border-blue-900 hover:bg-blue-800 hover:text-white rounded cursor-pointer flex-wrap"
              >
                <div>
                  <p>
                    <span className="font-bold">{price.description}</span>
                  </p>
                </div>
                <div>
                  ${numberWithCommas(parseFloat(price.amount).toFixed(2))} {price.currency} + IVA
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
