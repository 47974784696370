import axios from 'axios'
import { fetchHeaders } from './utils'

export const AmenitiesAPI = {
  getAmenities,
}

const { api: API_URL } = process.env.ENV

async function getAmenities() {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}amenities`,
  }).then(
    (response) => {
      let { data } = response

      return data
    },
    (error) => {
      console.log(error)
    }
  )
}
