import React, { Fragment, useState } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  InputNumber,
  Divider,
} from 'antd'

// Own component
import SelectPropertyType from '../../Shared/Selects/SelectPropertyType'
import SelecPromoter from '../../Shared/Selects/SelectPromoter'
import GetPropertyType from '../TypePropertiesInfo/GetPropertyType'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const GeneralCreateProperty = (props) => {
  const { form } = props
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const { hidden } = props
  const [showPriceRent, setShowPriceRent] = useState(false)
  const [showPriceSale, setShowPriceSale] = useState(false)
  const [showPriceTransfer, setShowPriceTransfer] = useState(false)
  const [propertyTypeSelected, setPropertyTypeSelected] = useState(null)

  const description = getFieldValue('description') || ''
  const propertyType = getFieldValue('property_type')

  const handleChangePropertyType = (value) => {
    const { label } = value

    setPropertyTypeSelected(label.toLowerCase())
  }

  const propertyTypeInfo = {
    casas: 'house',
    departamentos: 'apartment',
    terrenos: 'lot',
    bodegas: 'cellar',
    consultorios: 'consultingRoom',
    edificios: 'building',
    granjas: 'farm',
    'haciendas y quintas': 'estate',
    hoteles: 'hotel',
    locales: 'shop',
    oficinas: 'office',
    'salones de eventos': 'industrialPark',
    'nave-industrial': 'industrialPark',
    'parques industriales': 'industrialPark',
    'plantas industriales': 'industrialPlant',
    'predio rustico': 'rustic',
    ranchos: 'ranch',
  }

  const handleCheckBox = (checkedValue) => {
    setShowPriceRent(false)
    setShowPriceSale(false)
    setShowPriceTransfer(false)
    checkedValue.map((item) => {
      if (item === 1) setShowPriceSale(true)
      if (item === 2) setShowPriceRent(true)
      if (item === 3) setShowPriceTransfer(true)
    })
    setFieldsValue({ operation_type: checkedValue })
  }

  return (
    <Fragment>
      <Row
        type="flex"
        justify="space-between"
        gutter={[48, 0]}
        className={hidden ? 'hidden' : 'flex'}
      >
        <Col xs={24}>
          <Item label="Descripción" className="my-0" colon={false}>
            {getFieldDecorator('description', {
              initialValue: description,
              rules: [
                {
                  required: true,
                  message: 'Escribe la descripción de la propiedad.',
                },
              ],
            })(<TextArea autoSize={{ minRows: 4 }} />)}
          </Item>
        </Col>

        <Col xs={24} md={8}>
          <Item label="Número Predial">
            {getFieldDecorator('cadastre', {})(<Input />)}
          </Item>
        </Col>

        <Col xs={24} md={8}>
          <Item label="Selecciona un promotor">
            {getFieldDecorator('membership', {
              rules: [
                {
                  required: true,
                  message: 'Selecciona un promotor',
                },
              ],
            })(<SelecPromoter placeholder="Selecciona un promotor" />)}
          </Item>
        </Col>

        <Col xs={24} md={8}>
          <Item label="Tipo de inmueble">
            {getFieldDecorator('property_type', {
              rules: [
                {
                  required: true,
                  message: 'Selecciona un tipo de inmueble',
                },
              ],
            })(
              <SelectPropertyType
                onChange={handleChangePropertyType}
                labelInValue
                placeholder="Selecciona un tipo de propiedad"
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" gutter={[48, 0]} className={hidden ? 'hidden' : 'flex'}>
        {propertyType ? (
          <GetPropertyType
            xs={24}
            md={8}
            lg={4}
            type={propertyTypeInfo[propertyTypeSelected]}
            form={form}
          />
        ) : null}
      </Row>
      <div className={hidden && 'hidden'}>
        <Row type="flex" gutter={[48, 0]}>
          <Col xs={24}>
            <Item label="Selecciona los tipos de operación">
              {getFieldDecorator('operation_type', {
                rules: [
                  {
                    required: true,
                    message: 'Selecciona un tipo de transacción',
                  },
                ],
              })(
                <Checkbox.Group
                  className="w-100 text-center"
                  onChange={handleCheckBox}
                >
                  <Row type="flex">
                    <Col xs={12} md={8}>
                      <Checkbox value={1}>Venta</Checkbox>
                    </Col>
                    <Col xs={12} md={8}>
                      <Checkbox value={2}>Renta</Checkbox>
                    </Col>
                    <Col xs={12} md={8}>
                      <Checkbox value={3}>Traspaso</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              )}
            </Item>
          </Col>
        </Row>
      </div>
      {showPriceRent && (
        <Row
          type="flex"
          gutter={[48, 6]}
          className={hidden ? 'hidden' : 'flex'}
        >
          <Col xs={24}>
            <Divider orientation="left">Renta</Divider>
          </Col>
          <Col xs={12}>
            <Item label="Precio">
              {getFieldDecorator('price_rent', {
                rules: [
                  {
                    required: true,
                    message: 'Ingresa un precio.',
                  },
                ],
              })(
                <InputNumber
                  className="w-100"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Item>
          </Col>
          <Col xs={12}>
            <Item label="Moneda">
              {getFieldDecorator('currency_rent', {
                rules: [
                  {
                    required: true,
                    message: 'Selecciona una moneda.',
                  },
                ],
              })(
                <Select placeholder="Selecciona una moneda">
                  <Option value="mxn">MXN</Option>
                  <Option value="usd">USD</Option>
                </Select>
              )}
            </Item>
          </Col>
        </Row>
      )}

      {showPriceSale && (
        <Row
          type="flex"
          gutter={[48, 16]}
          className={hidden ? 'hidden' : 'flex'}
        >
          <Col xs={24}>
            <Divider orientation="left">Venta</Divider>
          </Col>
          <Col xs={12}>
            <Item label="Precio">
              {getFieldDecorator('price_sale', {
                rules: [
                  {
                    required: true,
                    message: 'Ingresa una precio.',
                  },
                ],
              })(
                <InputNumber
                  className="w-100"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Item>
          </Col>
          <Col xs={12}>
            <Item label="Moneda">
              {getFieldDecorator('currency_sale', {
                rules: [
                  {
                    required: true,
                    message: 'Selecciona una moneda.',
                  },
                ],
              })(
                <Select placeholder="Selecciona una moneda">
                  <Option value="mxn">MXN</Option>
                  <Option value="usd">USD</Option>
                </Select>
              )}
            </Item>
          </Col>
        </Row>
      )}

      {showPriceTransfer && (
        <Row
          type="flex"
          gutter={[48, 16]}
          className={hidden ? 'hidden' : 'flex'}
        >
          <Col xs={24}>
            <Divider orientation="left">Traspaso</Divider>
          </Col>
          <Col xs={12}>
            <Item label="Precio">
              {getFieldDecorator('price_transfer', {
                rules: [
                  {
                    required: true,
                    message: 'Ingresa un precio.',
                  },
                ],
              })(
                <InputNumber
                  className="w-100"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Item>
          </Col>
          <Col xs={12}>
            <Item label="Moneda">
              {getFieldDecorator('currency_transfer', {
                rules: [
                  {
                    required: true,
                    message: 'Selecciona una moneda.',
                  },
                ],
              })(
                <Select placeholder="Selecciona una moneda">
                  <Option value="mxn">MXN</Option>
                  <Option value="usd">USD</Option>
                </Select>
              )}
            </Item>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default GeneralCreateProperty
