/* eslint-disable react/jsx-filename-extension */
import React, { Fragment, useState, cloneElement } from 'react'
import { Button, Tag } from 'antd'

// Own components
import FilterIcon from '../Icons/Filter'
import ModalStyled from '../Common/Modal'

const Filters = (props) => {
  const { children, onSubmit } = props

  const [visibleModal, setVisibleModal] = useState(false)
  const [filterTags, setFiltersTags] = useState([])

  const toggleModal = () => setVisibleModal(!visibleModal)

  const handleClickFilter = () => toggleModal()

  const filtersAdded = (values) => {
    toggleModal()
    setFiltersTags(values)
  }

  const resetFilters = () => {
    setFiltersTags([])
  }

  return (
    <Fragment>
      <Button onClick={handleClickFilter} className="mr-1">
        <FilterIcon style={{ width: '14px' }} /> Filtrar
      </Button>
      {filterTags.length > 0
        ? filterTags.map((text, idx) => {
            return (
              <Tag
                key={idx}
                style={{
                  top: '-1px',
                  lineHeight: '30px',
                  position: 'relative',
                }}
              >
                {text}
              </Tag>
            )
          })
        : null}
      <ModalStyled
        centered
        title="Filtros"
        visible={visibleModal}
        onCancel={toggleModal}
        footer={null}
      >
        {cloneElement(children, { onSubmit, filtersAdded, resetFilters })}
      </ModalStyled>
    </Fragment>
  )
}

export default Filters
