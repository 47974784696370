import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import MapInput from '../../Components/Forms/MapInput'
import SelectBox from '../../Components/Forms/SelectBox'
import useGeocode from '../../Hooks/useGeocode'
import SelectMunicipality from '../../Signup/location_price/components/SelectMunicipality'
import { Formik, Field, Form, FieldArray } from 'formik'
import NumberFormat from 'react-number-format'
import { RadioGroup } from '@headlessui/react'
import * as Sentry from '@sentry/react'

import * as Yup from 'yup'

import acquisitionClient from '../../Services/acquisitionApiClient'

const transactionTypesArray = [
  { name: 'Venta', value: 'sale' },
  { name: 'Renta', value: 'rent' },
  { name: 'Traspaso', value: 'transfer' },
]

const propertyTypes = [
  { name: 'casa', value: 'house' },
  { name: 'departamento', value: 'apartment' },
  { name: 'terreno', value: 'lot' },
  { name: 'oficina', value: 'office' },
  { name: 'local', value: 'local' },
  { name: 'bodega', value: 'warehouse' },
  { name: 'nave industrial', value: 'industrial' },
  { name: 'consultorio', value: 'doctors' },
  { name: 'edificio', value: 'building' },
  { name: 'rancho', value: 'farm' },
]

function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v != '')
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}

const UpdateLocationConfig = React.memo(({ values, touched, updateConfig }) => {
  const {
    address,
    exterior_number,
    interior_number,
    neighborhood,
    city,
    state,
  } = values

  useEffect(() => {
    if (address && exterior_number && neighborhood && city && state) {
      if (
        touched.address &&
        touched.exterior_number &&
        touched.neighborhood &&
        touched.state
      ) {
        updateConfig({
          street: `${address}, ${exterior_number}`,
          neighborhood: neighborhood,
          city,
          state,
        })
      }
    }
  }, [values, touched])

  return null
})

const PropertyAndLocation = () => {
  const [locationConfig, setLocationConfig] = useState({
    street: '',
    neighborhood: '',
    city: '',
    state: '',
  })
  const { error, coordinates } = useGeocode(locationConfig)
  let history = useHistory()

  const setTransactionTypes = (
    checked,
    value,
    transactionTypes,
    arrayHelpers
  ) => {
    if (checked) {
      arrayHelpers.push(value)
    } else {
      const i = transactionTypes.indexOf(value)
      arrayHelpers.remove(i)
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions
    try {
      setSubmitting(true)
      const cleanValues = removeEmpty(values)
      const property = await acquisitionClient.post('/customers/properties/', {
        ...cleanValues,
        ...(coordinates?.lat &&
          coordinates?.lng && {
            latitude: coordinates.lat.toFixed(5),
            longitude: coordinates.lng.toFixed(5),
          }),
      })
      const { uuid } = property.data
      localStorage.setItem('CYTpropertyUuid', uuid)
      setSubmitting(false)
      history.push('/unirse/amenidades')
    } catch (err) {
      setSubmitting(false)
      Sentry.captureException(err)
    }
  }
  const SignupSchema = Yup.object().shape({
    address: Yup.string().required('Campo requerido'),
    exterior_number: Yup.string().required('Campo requerido'),
    interior_number: Yup.string(),
    neighborhood: Yup.string().required('Campo requerido'),
    city: Yup.string().required('Campo requerido'),
    property_type: Yup.string().required('Se debe elegir una opción'),
    state: Yup.string().required('Campo requerido'),
    price_sale: Yup.number('Ingresa un precio'),
    price_rent: Yup.number('Ingresa un precio'),
    price_transfer: Yup.number('Ingresa un precio'),
  })
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        address: '',
        exterior_number: '',
        interior_number: '',
        neighborhood: '',
        city: '',
        state: 'Aguascalientes',
        property_type: '',
        currency: 'MXN',
        sale_price: undefined,
        rent_price: undefined,
        transfer_price: undefined,
        transactionTypes: [],
      }}
      validationSchema={SignupSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form
          onSubmit={handleSubmit}
          className="w-full relative space-y-3 md:mb-10"
        >
          <UpdateLocationConfig
            values={values}
            touched={touched}
            updateConfig={setLocationConfig}
          />
          <RadioGroup
            className="md:block hidden"
            value={values.property_type}
            onChange={(val) => setFieldValue('property_type', val)}
          >
            <RadioGroup.Label className="form-title my-10">
              Tipo de Inmueble y Ubicación
            </RadioGroup.Label>
            <div className="grid grid-cols-2 m-auto gap-4">
              {propertyTypes.map((property, i) => (
                <RadioGroup.Option
                  key={i}
                  value={property.value}
                  className="md:h-11 relative rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
                >
                  {({ checked }) => (
                    <>
                      <div className="flex flex-1 items-center">
                        <div className="text-sm flex-1">
                          <RadioGroup.Label
                            as="p"
                            className="text-base leading-6 font-medium text-gray-900 text-center capitalize"
                          >
                            {property.name}
                          </RadioGroup.Label>
                        </div>
                      </div>

                      <div
                        className={classNames(
                          checked ? 'border-yellow-500' : 'border-transparent',
                          'absolute -inset-px rounded-lg border-2 pointer-events-none'
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <div className="block md:hidden">
            <label
              htmlFor="property_type"
              className="block text-xl font-medium mt-8"
            >
              Tipo de Inmueble y Ubicación
            </label>
            <select
              name="property_type"
              value={values.property_type}
              onChange={handleChange}
              onBlur={handleBlur}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md capitalize"
            >
              {propertyTypes.map((property, i) => (
                <option
                  key={i}
                  value={property.value}
                  label={property.name}
                  className="capitalize"
                />
              ))}
            </select>
          </div>
          {errors.property_type && touched.property_type && (
            <div className="text-sm text-red-600">{errors.property_type}</div>
          )}
          <h3 className="text-xl font-medium mt-8">Ubicación</h3>
          <p className="text-sm font-normal mb-8">
            Asegúrate que sea correcta. Una vez pagada, tu publicación no se
            puede editar.
          </p>
          <div className="md:grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="address" className="text-sm font-medium mb-1">
                Calle
              </label>
              <Field
                placeholder="Calle"
                name="address"
                className="input"
                type="text"
              />

              {errors.address && touched.address && (
                <div className="text-sm text-red-600">{errors.address}</div>
              )}
            </div>
            <div>
              <label
                htmlFor="exterior_number"
                className="text-sm font-medium mb-1"
              >
                Número
              </label>
              <Field
                placeholder="Número"
                name="exterior_number"
                className="input"
                type="text"
              />

              {errors.exterior_number && touched.exterior_number && (
                <div className="text-sm text-red-600">
                  {errors.exterior_number}
                </div>
              )}
            </div>
            <div>
              <label
                htmlFor="interior_number"
                className="text-sm font-medium mb-1"
              >
                Interior
              </label>
              <Field
                placeholder="Interior"
                name="interior_number"
                className="input"
                type="text"
              />

              {errors.interior_number && touched.interior_number && (
                <div className="text-sm text-red-600">
                  {errors.interior_number}
                </div>
              )}
            </div>

            <div>
              <label
                htmlFor="neighborhood"
                className="text-sm font-medium mb-1"
              >
                Colonia
              </label>

              <Field
                placeholder="Colonia"
                name="neighborhood"
                type="text"
                className="input"
              />
              {errors.neighborhood && touched.neighborhood && (
                <div className="text-sm text-red-600">
                  {errors.neighborhood}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="city" className="text-sm font-medium mb-1">
                Ciudad / Municipio
              </label>
              <Field
                name="city"
                component={SelectMunicipality}
                placeholder="Municipio"
              />
              {errors.city && touched.city && (
                <div className="text-sm text-red-600">{errors.city}</div>
              )}
            </div>

            <div>
              <label htmlFor="state" className="text-sm font-medium mb-1">
                Estado
              </label>
              <Field as="select" className="select" name="state">
                <option value="Aguascalientes">Aguascalientes</option>
                <option value="Baja California">Baja California</option>
                <option value="Baja California Sur">Baja California Sur</option>
                <option value="Campeche">Campeche</option>
                <option value="Chiapas">Chiapas</option>
                <option value="Chihuahua">Chihuahua</option>
                <option value="DF">Ciudad de México</option>
                <option value="Coahuila">Coahuila</option>
                <option value="Colima">Colima</option>
                <option value="Durango">Durango</option>
                <option value="Estado de México">Estado de México</option>
                <option value="Guanajuato">Guanajuato</option>
                <option value="Guerrero">Guerrero</option>
                <option value="Hidalgo">Hidalgo</option>
                <option value="Jalisco">Jalisco</option>
                <option value="Michoacán">Michoacán</option>
                <option value="Morelos">Morelos</option>
                <option value="Nayarit">Nayarit</option>
                <option value="Nuevo León">Nuevo León</option>
                <option value="Oaxaca">Oaxaca</option>
                <option value="Puebla">Puebla</option>
                <option value="Querétaro">Querétaro</option>
                <option value="Quintana Roo">Quintana Roo</option>
                <option value="San Luis Potosí">San Luis Potosí</option>
                <option value="Sinaloa">Sinaloa</option>
                <option value="Sonora">Sonora</option>
                <option value="Tabasco">Tabasco</option>
                <option value="Tamaulipas">Tamaulipas</option>
                <option value="Tlaxcala">Tlaxcala</option>
                <option value="Veracruz">Veracruz</option>
                <option value="Yucatán">Yucatán</option>
                <option value="Zacatecas">Zacatecas</option>
              </Field>

              {errors.state && touched.state && (
                <div className="text-sm text-red-600">{errors.state}</div>
              )}
            </div>
          </div>
          <div className="w-full md:h-60 h-80">
            <MapInput coordinates={coordinates} />
          </div>
          <h3 className="text-xl font-medium my-8">
            Tipo de operación y precio
          </h3>

          <FieldArray
            name="transactionTypes"
            render={(arrayHelpers) => (
              <div className="w-full gap-2 space-y-3 md:grid md:grid-cols-3 md:space-y-0">
                {transactionTypesArray.map((transaction, index) => (
                  <SelectBox
                    key={index}
                    option={transaction}
                    callBack={(checked, value) =>
                      setTransactionTypes(
                        checked,
                        value,
                        values.transactionTypes,
                        arrayHelpers
                      )
                    }
                  />
                ))}
              </div>
            )}
          />

          <div className="space-y-3">
            {values.transactionTypes.includes('sale') && (
              <div>
                <label
                  htmlFor="sale_price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Precio Venta
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center z-10">
                    <label htmlFor="currency" className="sr-only">
                      Currency
                    </label>
                    <Field
                      as="select"
                      name="currency"
                      className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                    >
                      <option>USD</option>
                      <option>MXN</option>
                    </Field>
                  </div>
                  <NumberFormat
                    prefix={'$'}
                    placeholder="0.00"
                    isNumericString={true}
                    thousandSeparator={true}
                    value={values.sale_price}
                    onValueChange={(val) =>
                      setFieldValue('sale_price', val.floatValue)
                    }
                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full pl-16 pr-12  sm:text-sm md:text-lg  border-gray-300 rounded-md"
                  />
                </div>
                {errors.sale_price && touched.sale_price && (
                  <div className="text-sm text-red-600">
                    {errors.sale_price}
                  </div>
                )}
              </div>
            )}
            {values.transactionTypes.includes('rent') && (
              <div>
                <label
                  htmlFor="rent_price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Precio Renta
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center z-10">
                    <label htmlFor="currency" className="sr-only">
                      Currency
                    </label>
                    <Field
                      as="select"
                      name="currency"
                      className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                    >
                      <option>USD</option>
                      <option>MXN</option>
                    </Field>
                  </div>
                  <NumberFormat
                    prefix={'$'}
                    placeholder="0.00"
                    isNumericString={true}
                    thousandSeparator={true}
                    value={values.rent_price}
                    onValueChange={(val) =>
                      setFieldValue('rent_price', val.floatValue)
                    }
                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full pl-16 pr-12  sm:text-sm md:text-lg  border-gray-300 rounded-md"
                  />
                </div>
                {errors.rent_price && touched.rent_price && (
                  <div className="text-sm text-red-600">
                    {errors.rent_price}
                  </div>
                )}
              </div>
            )}
            {values.transactionTypes.includes('transfer') && (
              <div>
                <label
                  htmlFor="transfer_price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Precio Traspaso
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center z-10">
                    <label htmlFor="currency" className="sr-only">
                      Currency
                    </label>
                    <Field
                      as="select"
                      name="currency"
                      className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                    >
                      <option>USD</option>
                      <option>MXN</option>
                    </Field>
                  </div>
                  <NumberFormat
                    prefix={'$'}
                    placeholder="0.00"
                    isNumericString={true}
                    thousandSeparator={true}
                    value={values.transfer_price}
                    onValueChange={(val) =>
                      setFieldValue('transfer_price', val.floatValue)
                    }
                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full pl-16 pr-12  sm:text-sm md:text-lg  border-gray-300 rounded-md"
                  />
                </div>
                {errors.transfer_price && touched.transfer_price && (
                  <div className="text-sm text-red-600">
                    {errors.transfer_price}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 sticky md:relative  bottom-0 w-full md:bg-transparent bg-white z-50 p-3 md:p-5">
            <a href="/unirse" className="self-center underline">
              Regresar
            </a>
            <div className="sticky bottom-0 p-2 bg-white md:block md:p-0">
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-blue-900 group-hover:text-blue-800"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Continuar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PropertyAndLocation
