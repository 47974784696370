import React from 'react'
import styled from 'styled-components'
import { Icon, Dropdown } from 'antd'

const IconStyled = styled(Icon)`
  cursor: pointer;

  &:hover {
    color: #0d47a1;
  }
`

const Actions = (props) => {
  const { menu } = props
  return (
    <Dropdown overlayClassName="actions" overlay={menu} {...props}>
      <IconStyled type="more" />
    </Dropdown>
  )
}

export default Actions
