import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import {
  Formik,
  Field,
  Form,
  FieldArray,
  ErrorMessage,
  useFormikContext,
} from 'formik'
import { message } from 'antd'
import useStates from '../../Hooks/useStates'
import * as Yup from 'yup'
import log from '../../Utils/logger'

import apiClient from '../../Services/apiClient'

import SelectMunicipality from '../../Components/Forms/SelectMunicipality'
import SelectNeighborhood from '../../Components/Forms/SelectNeighborhood'

import MapLocation from '../../Shared/Common/MapLocation'

const LocationSchema = Yup.object().shape({
  state: Yup.number().required('Selecciona un estado'),
  municipality: Yup.object()
    .shape({
      name: Yup.string().required('El municipio es requerido'),
      id: Yup.number().required(),
    })
    .required('Ingresa una ciudad o municipio'),
  colony: Yup.object()
    .shape({
      name: Yup.string().required('La colonia es requerida'),
      id: Yup.number().required(),
    })
    .required('Ingresa una colonia'),
  interior_number: Yup.string(),
  street: Yup.string().required('Agrega una calle'),
  exterior_number: Yup.string().required(
    'Es necesario agregar un número exterior'
  ),
})

function useTraceUpdate(props) {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    prev.current = props
  })
}

export default function index(props) {
  const { propertyId, callBack, goBack, action } = props
  useTraceUpdate(props)
  const [propertyData, setPropertyData] = useState(null)
  const { data: states, error } = useStates()

  const memoizedCallback = useMemo(() => callBack, [callBack])

  const onSubmit = async (values) => {
    try {
      const cleanValues = {
        state: values.state,
        latitude: values.latLng[0],
        longitude: values.latLng[1],
        colony: values.colony.id,
        municipality: values.municipality.id,
        exterior_number: values.exterior_number,
        interior_number: values.interior_number,
        street: values.street,
      }
      const response = await apiClient.patch(
        `/property/${propertyId}`,
        cleanValues
      )

      memoizedCallback(response)
    } catch (err) {
      log.error(err)
      message.error('Ocurrió un error al crear la propiedad')
    }
  }

  const cleanProperty = (data) => {
    const newObject = {
      ...data,
      operation_type: data.operation_type.map((o) => o.id),
      property_type: data.property_type.id,
      state: data?.state?.id || 1,
      municipality: data.municipality || '',
      colony: data.colony || '',
      exterior_number: data.exterior_number || '',
      interior_number: data.interior_number || '',
    }
    return newObject
  }

  const getProperty = async (propertyId) => {
    try {
      const { data } = await apiClient.get(`property/${propertyId}`)
      setPropertyData(cleanProperty(data))
    } catch (err) {
      log.error(err)
    }
  }

  useEffect(() => {
    if (propertyId) {
      getProperty(propertyId)
    }
  }, [])

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={LocationSchema}
      initialValues={
        propertyData
          ? { ...propertyData }
          : {
              state: 1,
              street: '',
              exterior_number: '',
              interior_number: '',
              municipality: '',
              colony: '',
            }
      }
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        validationSchema,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form data-splitbee-event={`properties-${action}-location`}>
          <div className="w-full space-y-6 grid grid-cols-2 gap-4">
            <div>
              <div className="">
                <label htmlFor="state" className="form-label">
                  Estado
                </label>
                <Field
                  as="select"
                  name="state"
                  className="select"
                  disabled={!states}
                >
                  {states &&
                    states.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage
                  name="state"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg}</div>
                  )}
                />
              </div>

              <div>
                <label htmlFor="municipality" className="form-label">
                  Ciudad / Municipio
                </label>
                <Field name="municipality" placeholder="Municipio">
                  {({ field, form, meta }) => (
                    <SelectMunicipality
                      field={field}
                      form={form}
                      selectedItem={field.value}
                      handleSelectedItemChange={({ selectedItem }) => {
                        form.setFieldValue('municipality', selectedItem)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="municipality"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg.name}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="colony" className="form-label">
                  Colonia
                </label>
                <Field name="colony" placeholder="Colonia">
                  {({ field, form, meta }) => (
                    <SelectNeighborhood
                      field={field}
                      form={form}
                      selectedItem={field.value}
                      handleSelectedItemChange={({ selectedItem }) => {
                        form.setFieldValue('colony', selectedItem)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="colony"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg.name}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="street" className="form-label">
                  Calle
                </label>
                <Field
                  as="input"
                  type="text"
                  name="street"
                  className="input"
                  placeholder="Calle"
                ></Field>
                <ErrorMessage
                  name="street"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="exterior_number" className="form-label">
                  Número exterior
                </label>
                <Field
                  as="input"
                  type="text"
                  name="exterior_number"
                  className="input"
                ></Field>
                <ErrorMessage
                  name="exterior_number"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="exterior_number" className="form-label">
                  Número interior
                </label>
                <Field
                  as="input"
                  type="text"
                  name="interior_number"
                  className="input"
                ></Field>
                <ErrorMessage
                  name="interior_number"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg}</div>
                  )}
                />
              </div>
            </div>
            <div>
              <MapLocation
                state={states && states.find((s) => s.id == values?.state).name}
                municipality={values?.municipality?.name}
                colony={values?.colony?.name}
                street={values?.street}
                exteriorNumber={values?.exterior_number}
                onChange={(e) => setFieldValue('latLng', e)}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3 bg-gray-50 p-3 rounded">
            <div>
              <div
                onClick={goBack}
                data-splitbee-event={`properties-${action}-location-back`}
                className="cursor-pointer group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md  bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              >
                Regresar
              </div>
            </div>
            <div className="text-right">
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
