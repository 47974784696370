import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'antd'

// Utils
import { capitalizeFirstLetter } from './../../Shared/utils'

const InactivePromoter = (props) => {
  const { getFieldDecorator, validateFields } = props.form
  const { id, first_name, last_name } = props.promoter
  const { onSubmit, action } = props

  const [typeButton, setTypeButton] = useState('primary')
  const [textAction, setTextAction] = useState('activar')

  useEffect(() => {
    if (action === 'inactive') {
      setTypeButton('danger')
      setTextAction('inactivar')
    }
  }, [action])

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  getFieldDecorator('id', { initialValue: id })
  getFieldDecorator('status', { initialValue: action === 'active' ? 1 : 2 })

  return (
    <Form
      onSubmit={submitForm}
      className="login-form"
      colon={false}
      hideRequiredMark
      data-splitbee-event={`promoters-${action}-promoter-save`}
    >
      <Row type="flex" gutter={48}>
        <Col xs={24}>
          <p className="text-center pb-5 pt-4 mb-0">
            ¿Estás seguro de{' '}
            <span className="font-weight-bolder">{textAction}</span> al promotor{' '}
            {first_name} {last_name} ?
          </p>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button type={typeButton} htmlType="submit">
            {capitalizeFirstLetter(textAction)}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const InactivePromoterWrapper = Form.create({})(InactivePromoter)
export default InactivePromoterWrapper
