import React, { useState, useEffect, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Button,
  Popover,
  Icon,
  Menu,
  Typography,
  Tooltip,
  message,
  Tag,
} from 'antd'
import moment from 'moment'
import { sub, isBefore, getISODay, formatISO } from 'date-fns'

// Own components
import ContainerLayout from './../Layouts/ContainerLayout'
import PageHeaderStyled from './../Shared/Common/PageHeader'
import Actions from './../Shared/Common/Actions'
import HomeIcon from './../Shared/Icons/Home'
import ModalStyled from './../Shared/Common/Modal'
import ActionsProperties from './Actions/'
import Filters from '../Shared/Filters/'
import FiltersForm from './FiltersForm'
import NoPhoto from '../Shared/Images/no_foto.png'
import { formatNumberPrice } from '../Shared/utils'

// Services
import { PropertyAPI } from './../Services/property'
import { GeneralAPI } from '../Services/general'
import downloadPropertyPdf from '../Utils/downloadPdf'
import AccountPermissions from '../Services/auth'

//Hooks
import { useWindowWidthSize } from '../Shared/customHooks'
import useAccount from '../Hooks/useAccount'

const adviceUser = JSON.parse(localStorage.getItem('adviceUser')) || {}
const { website: websiteAdvice } = adviceUser
const { portal: PORTALURL } = process.env.ENV

const { Paragraph } = Typography

const propertiesStatus = {
  published: 'Publicado',
  pending: 'Pendiente',
  inactive: 'Inactivo',
}

const loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

const PropertiesList = (props) => {
  const [keepValues, setKeepValues] = useState({ status: 'published' })
  const [dataSource, setDataSource] = useState([])
  const [willExpire, setWillExpire] = useState([])
  const [action, setAction] = useState(null)
  const [dataRow, setDataRow] = useState(null)
  const [titleModal, setTitleModal] = useState('')
  const [bodyStyleModal, setBodyStyleModal] = useState({})
  const [visibleModal, setVisibleModal] = useState(false)
  const [showDefaultTag, setShowDefaultTag] = useState(true)
  const [responsiveDesign, setResponsiveDesign] = useState(false)
  const [mobileDevice, setMobileDevice] = useState(false)
  const [showWebsitePopover, setShowWebsitePopover] = useState(true)
  const [loadingData, setLoadingData] = useState({
    indicator: loadingIcon,
  })
  const widthDevice = useWindowWidthSize()
  const editAccess = AccountPermissions.accessGranted('properties', 'update')
  const detailAccess = AccountPermissions.accessGranted('properties', 'detail')
  const [accountType, accountRole] = useAccount()

  const onErrorImage = (e) => {
    e.target.src =
      'https://cyt-media.s3-us-west-2.amazonaws.com/img_casas/NoFoto.png'
  }

  useEffect(() => {
    if (widthDevice <= 1024) setResponsiveDesign(true)
    else setResponsiveDesign(false)
    if (widthDevice <= 768) setMobileDevice(true)
    else setMobileDevice(false)
  }, [widthDevice])

  const handleClickWebsite = (e) => {
    if (!websiteAdvice) {
      let advice = { website: true }
      localStorage.setItem('adviceUser', JSON.stringify(advice))
      setShowWebsitePopover(false)
    }
  }

  const columns = [
    {
      width: 2,
      dataIndex: 'image_cover',
      key: 'image_cover',
      render: (text, record) => {
        if (text && record.status) {
          return (
            <Popover
              placement="rightTop"
              arrowPointAtCenter
              content={
                <img
                  className="eye-image-fluid"
                  src={text}
                  alt={record.title}
                  onError={onErrorImage}
                  referrerPolicy="no-referrer-when-downgrade"
                />
              }
            >
              <img
                className="image-table-fluid"
                src={text}
                alt={record.title}
                onError={onErrorImage}
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Popover>
          )
        }
      },
    },
    {
      width: 2,
      key: 'xml_loaded',
      render: (text, record) => {
        if (record.origin !== 'Casas y Terrenos') {
          return (
            <Tooltip
              placement="right"
              title="La propiedad se ha cargado mediante XML"
            >
              <Icon type="api" theme="twoTone" twoToneColor="#52c41a" />
            </Tooltip>
          )
        }
        return null
      },
    },
    {
      key: 'featured',
      width: 5,
      render: (text, record) => {
        if (record.is_featured) {
          return (
            <Tooltip placement="right" title="Destacado">
              <Icon type="star" theme="twoTone" twoToneColor="#D4AF37" />
            </Tooltip>
          )
        }
        return null
      },
    },
    {
      key: 'website',
      width: 15,
      render: (text, record, index) => {
        const { id } = record
        if (id) {
          let URL = `${PORTALURL}/propiedad/${id}`

          return (
            <Tooltip placement="right" title="Ver propiedad en CYT">
              {URL && (
                <a
                  href={URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-1"
                  data-splitbee-event="properties-see-property"
                >
                  <Icon type="search" style={{ color: 'black' }} />
                </a>
              )}
            </Tooltip>
          )
        }
        return null
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => {
        const { id } = record
        if (id) {
          let URL = `${PORTALURL}/propiedad/${id}`

          return (
            <Paragraph className="mb-0" copyable={{ text: URL }}>
              {URL ? (
                <a
                  href={URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-1"
                >
                  {id}
                </a>
              ) : (
                id
              )}
            </Paragraph>
          )
        }
      },
    },
    {
      title: 'Tipo de propiedad',
      dataIndex: 'property_type',
      render: (text) => {
        if (text) {
          const { name } = text
          return name
        }
      },
    },
    {
      title: 'Dirección',
      render: (text, record) => {
        const { colony, municipality, state } = record
        let renderAddress = []

        if (colony) renderAddress.push(<div key="1">{record.colony.name}</div>)
        if (municipality)
          renderAddress.push(<div key="2">{record.municipality.name}</div>)
        if (state) renderAddress.push(<div key="3">{record.state.name}</div>)
        return renderAddress
      },
    },
    {
      title: 'Promotor',
      dataIndex: 'membership_email',
      render: (text) => {
        if (text)
          return (
            <Paragraph className="mb-0" copyable>
              {text}
            </Paragraph>
          )
      },
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'date_created',
      sorter: (a, b) => (moment(a).isBefore(moment(b)) ? -1 : 1),
    },
    {
      title: 'Precio',
      render: (record) => {
        const { price_rent, price_sale, price_transfer } = record
        let renderPrice = []

        if (price_rent) {
          renderPrice.push(
            <div key="1">Renta: ${formatNumberPrice(price_rent)}</div>
          )
        }
        if (price_sale) {
          renderPrice.push(
            <div key="2">Venta: ${formatNumberPrice(price_sale)}</div>
          )
        }
        if (price_transfer) {
          renderPrice.push(
            <div key="3">Traspaso: ${formatNumberPrice(price_transfer)}</div>
          )
        }
        return renderPrice
      },
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      render: (text) => propertiesStatus[text],
    },
    {
      key: 'action',
      width: 20,
      render: (text, record) => {
        return <Actions menu={menuActions(record)} placement="bottomRight" />
      },
    },
  ]

  const history = useHistory()

  const toggleModal = () => setVisibleModal(!visibleModal)

  const fetchData = async (values = { status: 'published' }) => {
    const response = await PropertyAPI.getMyProperties(values)
    setDataSource(response)
    setLoadingData(false)
  }

  const fetchExpiringData = async () => {
    const ninetyDaysAgoFromNextMonday = sub(new Date(), {
      days: 90 - (7 - (getISODay(new Date()) % 7) + 1),
    })
    const values = {
      status: 'published',
      feed__isnull: true,
      modified__lte: formatISO(ninetyDaysAgoFromNextMonday, {
        representation: 'date',
      }),
    }
    const data = await PropertyAPI.getMyProperties(values)
    setWillExpire(data.count)
  }

  useEffect(() => {
    fetchExpiringData()
  }, [dataSource])

  useEffect(() => {
    fetchData()
  }, [])

  const handleActions = async (e) => {
    const { key: action } = e
    const { propertyid } = e.item.props
    let dataProperty
    if (action !== 'statistic')
      dataProperty = await PropertyAPI.getProperty(propertyid)

    dataProperty.id = propertyid
    setBodyStyleModal({})
    if (action === 'edit') {
      history.push(`/propiedades/editar/${propertyid}`)
    } else if (action === 'inactive' || action === 'published') {
      let title = 'Publicar'
      if (action === 'inactive') title = 'Inactivar'
      setTitleModal(`${title} propiedad | ID ${propertyid}`)
      setDataRow(dataProperty)
      setAction(action)
      setVisibleModal(true)
    } else if (action === 'details') {
      setBodyStyleModal({ padding: 0 })
      setTitleModal(`Propiedad | ID ${propertyid}`)
      setAction(action)
      setDataRow(dataProperty)
      setVisibleModal(true)
    } else if (action === 'statistic') {
      setTitleModal(`Estadística propiedad | ID ${propertyid}`)
      setDataRow(dataProperty)
      setAction(action)
      setVisibleModal(true)
    } else if (action === 'download') {
      message.loading('Descargando archivo. Puede tardar varios segundos.', 0)
      try {
        const pdfData = await downloadPropertyPdf(propertyid)
        message.destroy()
        message.success('El PDF se descargó éxitosamente.')
      } catch {
        message.destroy()
        message.error(
          'Ocurrió un error descargando el PDF. Intentalo de nuevo más tarde.'
        )
      }
    }
  }

  const menuItemStatus = (status, propertyid) => {
    let menuItem = null
    if (status === 'published') {
      menuItem = (
        <Menu.Item
          key="inactive"
          propertyid={propertyid}
          data-splitbee-event="properties-inactive-property"
        >
          <Icon type="eye-invisible" style={{ color: '#6C6C6C' }} /> Inactivar
        </Menu.Item>
      )
    } else if (status === 'inactive') {
      menuItem = (
        <Menu.Item
          key="published"
          propertyid={propertyid}
          data-splitbee-event="properties-published-property"
        >
          <Icon type="eye" style={{ color: '#6C6C6C' }} /> Publicar
        </Menu.Item>
      )
    }
    return menuItem
  }

  const menuActions = (record) => {
    const {
      id: propertyid,
      origin,
      is_my_property: myProperty,
      status,
    } = record
    return (
      <Menu onClick={handleActions}>
        {(editAccess || myProperty) && origin === 'Casas y Terrenos' ? (
          <Menu.Item
            key="edit"
            propertyid={propertyid}
            data-splitbee-event="properties-edit-property"
          >
            <Icon type="edit" style={{ color: '#332AAD' }} /> Editar
          </Menu.Item>
        ) : null}

        {(editAccess || myProperty) && menuItemStatus(status, propertyid)}

        {detailAccess || myProperty ? (
          <Menu.Item
            key="details"
            propertyid={propertyid}
            data-splitbee-event="properties-details-property"
          >
            <Icon type="read" style={{ color: '#AD2AA5' }} /> Más detalles
          </Menu.Item>
        ) : null}
        {/*<Menu.Item key="statistic" propertyid={propertyid}>
          <Icon type="fund" style={{color: "#332AAD"}} /> Estadísticas
          </Menu.Item>*/}

        {detailAccess || myProperty ? (
          <Menu.Item
            key="download"
            propertyid={propertyid}
            data-splitbee-event="properties-download-property"
          >
            <Icon type="file-pdf" style={{ color: '#F00' }} /> Descargar
          </Menu.Item>
        ) : null}
      </Menu>
    )
  }

  const handleChangeTable = (pagination) => {
    const page = pagination.current
    const params = Object.assign(keepValues, { page })
    fetchData(params)
  }

  const handleSubmitFilters = (values) => {
    let msg = ''
    if (values) {
      fetchData(values)
      setKeepValues(values)
      setShowDefaultTag(false)
      msg = 'Se han aplicado los filtros éxitosamente.'
    } else {
      setKeepValues({})
      fetchData()
      setShowDefaultTag(true)
      msg = 'Los filtros se han limpiado éxitosamente.'
    }
    message.success(msg)
  }

  const handleSubmitOnActions = async (dataForm) => {
    if (action === 'inactive' || action === 'published')
      handleSubmitStatusProperty(dataForm)
  }

  const handleSubmitStatusProperty = async (dataForm) => {
    const { id: propertyID } = dataForm
    let messageTXT
    let response = await PropertyAPI.editProperty(propertyID, dataForm)

    if (Object.prototype.hasOwnProperty.call(response, 'state')) {
      if (action === 'inactive') {
        messageTXT = 'Se ha inactivado la propiedad.'
        let totalFiltered = 0
        let filterProperties = dataSource['results'].filter((item) => {
          if (item.id !== propertyID) {
            totalFiltered = totalFiltered + 1
            return true
          }
        })
        let newTotalCount = dataSource.count - totalFiltered
        setDataSource({
          ...dataSource,
          count: newTotalCount,
          results: filterProperties,
        })
      }
      if (action === 'published') messageTXT = 'Se ha publicado la propiedad.'
    }
    toggleModal()
    fetchData(keepValues)
    message.success(messageTXT)
  }

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      {willExpire > 0 && (
        <div className="rounded-lg bg-yellow-cyt shadow-lg p-3 mb-6 w-full flex justify-between flex-wrap space-y-3 md:space-y-0">
          <div className="flex items-center">
            <span className="flex p-2 rounded-lg bg-yellow-cyt-alt">
              <Icon type="warning" style={{ color: 'white' }} />
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:hidden">
                {willExpire}{' '}
                {willExpire > 1
                  ? 'propiedades expirarán'
                  : 'propiedad expirará'}{' '}
                pronto
              </span>
              <span className="hidden md:inline text-lg">
                {willExpire}{' '}
                {willExpire > 1
                  ? 'propiedades expirarán'
                  : 'propiedad expirará'}{' '}
                muy pronto
              </span>
            </p>
          </div>
          <Link to={`/properties/expiring`} className="w-full md:w-auto">
            <button className="text-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-yellow-cyt bg-white hover:bg-yellow-50 md:w-auto w-full">
              Verlas ahora
            </button>
          </Link>
        </div>
      )}

      <PageHeaderStyled title="Propiedades" className="px-0 pt-0" />

      <Row type="flex" className="mb-2">
        <Col xs={24} lg={24}>
          {responsiveDesign ? (
            <Filters onSubmit={handleSubmitFilters}>
              <FiltersForm />
            </Filters>
          ) : (
            <FiltersForm hideTags onSubmit={handleSubmitFilters} />
          )}
          {showDefaultTag && responsiveDesign ? (
            <Tag
              style={{
                top: '-1px',
                lineHeight: '30px',
                position: 'relative',
              }}
            >
              Estatus: Publicado
            </Tag>
          ) : null}
        </Col>
        {!(accountType === 'advertiser' && dataSource?.count == 1) && (
          <Col xs={24} lg={24}>
            <Link
              to={`/propiedades/crear`}
              data-splitbee-event="properties-create"
            >
              <Button
                type="primary"
                block={mobileDevice}
                className={`float-right my-2`}
              >
                <HomeIcon style={{ width: '14px' }} /> Crear propiedad
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <Row type="flex">
        <Col xs={24}>
          <Table
            rowKey="id"
            loading={loadingData}
            dataSource={dataSource.results}
            columns={columns}
            size="middle"
            onChange={handleChangeTable}
            pagination={{
              defaultCurrent: 1,
              total: dataSource.count,
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <ModalStyled
        visible={visibleModal}
        onCancel={toggleModal}
        title={titleModal}
        centered
        destroyOnClose
        bodyStyle={bodyStyleModal}
        footer={null}
      >
        {action ? (
          <ActionsProperties
            action={action}
            property={dataRow}
            onSubmit={handleSubmitOnActions}
          />
        ) : null}
      </ModalStyled>
    </ContainerLayout>
  )
}

export default PropertiesList
