import React from 'react'
import { Icon } from 'antd'

const FilterSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g fill="#04458F">
        <path d="M479.18 91.897H32.821C14.69 91.897 0 77.207 0 59.077s14.69-32.821 32.821-32.821H479.18c18.13 0 32.82 14.69 32.82 32.821s-14.69 32.82-32.82 32.82zM295.385 288.821H32.821C14.69 288.821 0 274.13 0 256s14.69-32.821 32.821-32.821h262.564c18.13 0 32.821 14.69 32.821 32.821s-14.691 32.821-32.821 32.821z" />
      </g>
      <path
        fill="#FF485A"
        d="M479.18 288.821h-52.513c-18.13 0-32.821-14.69-32.821-32.821s14.69-32.821 32.821-32.821h52.513c18.13 0 32.82 14.69 32.82 32.821s-14.69 32.821-32.82 32.821z"
      />
      <path
        fill="#04458F"
        d="M479.18 485.744H32.821C14.69 485.744 0 471.053 0 452.923c0-18.13 14.69-32.821 32.821-32.821H479.18c18.13 0 32.82 14.69 32.82 32.821 0 18.13-14.69 32.821-32.82 32.821z"
      />
    </svg>
  )
}

const FilterIcon = (props) => <Icon component={FilterSVG} {...props} />

export default FilterIcon
