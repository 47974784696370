import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Form, Button, Avatar, Input, message } from 'antd'

// Own components
import ContainerLayout from '../../Layouts/ContainerLayout'
import PageHeaderStyled from '../../Shared/Common/PageHeader'
import SelectStates from '../../Shared/Selects/SelectStates'
import SelectMunicipality from '../../Shared/Selects/SelectMunicipality'
import SelectNeighborhood from '../../Shared/Selects/SelectNeighborhood'
import UploadLogo from './UploadLogo'

// Services
import { AccountAPI } from '../../Services/account'
// Hooks
import { useWindowWidthSize } from '../../Shared/customHooks'

const { Item: FormItem } = Form

const EditRealEstate = (props) => {
  const history = useHistory()
  const { location } = history
  const { state } = location
  const [loading, setLoading] = useState(false)
  const [clientId, setClientId] = useState(null)
  const [responsiveDesign, setResponsiveDesign] = useState(false)
  const [imgRealEstate, setImgRealEstate] = useState(null)
  const widthDevice = useWindowWidthSize()
  const {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    setFieldsValue,
  } = props.form
  let modDate = new Date().getTime()

  const handleSubmitForm = (e) => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        values.phone_number = `+52${values.phone_number}`
        if (values.img_real_estate) {
          const { img_real_estate } = values
          if (img_real_estate.hasOwnProperty('base64')) {
            const strImg = values.img_real_estate.base64.split(',')
            strImg.shift()
            strImg.join()
            values.img_real_estate.base64 = strImg[0]
          } else delete values.img_real_estate
        }

        const address = [
          {
            state: values.state.key,
            municipality: values.municipality.key,
            neighborhood: values.colony.key,
            num_exterior: values.num_exterior,
            street: values.street,
            type_address: 2,
          },
        ]

        if (values.num_interior) address[0].num_interior = values.num_interior
        if (values.IDAddress) address[0].id = values.IDAddress

        values.address = address
        delete values.state
        delete values.municipality
        delete values.colony
        delete values.num_exterior
        delete values.street
        delete values.num_exterior
        delete values.IDAddress

        let response = await AccountAPI.updateOwnRealEstate(clientId, values)

        if (response.success) message.success(response.message)
        else message.error(response.message)
      }
    })
  }

  const fetchUserData = async () => {
    const user = await AccountAPI.getAccount()
    setClientId(user[0].client)
    let response = await AccountAPI.getInformationOwnRealEstate(user[0].client)
    if (response) {
      const { business_name, phone_number, address, img_real_estate } = response

      setFieldsValue({
        business_name,
        phone_number:
          phone_number !== null
            ? String(phone_number).replace(/\s|(\+[0-9][0-9])/g, '')
            : '',
      })
      modDate = new Date().getTime()
      setImgRealEstate(`${img_real_estate}?lastmod=${modDate}`)

      if (address.length > 0) {
        const filterAddress = address.filter(
          (item) => Number(item.type_address) === 2
        )

        const {
          id: IDAddress,
          street,
          num_exterior,
          num_interior,
          state,
          municipality,
          neighborhood: colony,
        } = filterAddress[0]

        setFieldsValue({
          street,
          num_exterior,
          num_interior,
        })
        if (state) {
          setFieldsValue({
            state: {
              key: state.id,
              label: state.name,
            },
          })
        }
        if (municipality) {
          setFieldsValue({
            municipality: {
              key: municipality.id,
              label: municipality.name,
            },
          })
        }
        if (colony) {
          setFieldsValue({
            colony: {
              key: colony.id,
              label: colony.name,
            },
          })
        }

        setFieldsValue({
          IDAddress,
        })
      }
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  useEffect(() => {
    if (widthDevice <= 1024) setResponsiveDesign(true)
    else setResponsiveDesign(false)
  }, [widthDevice])

  const getFieldState = getFieldValue('state') || ''
  const getFieldMunicipality = getFieldValue('municipality') || ''
  const getFieldImgRealEstate = getFieldValue('img_real_estate')
  const resetSelects = { key: '', label: '' }

  useEffect(() => {
    if (
      getFieldImgRealEstate &&
      getFieldImgRealEstate.hasOwnProperty('base64')
    ) {
      const { base64 } = getFieldImgRealEstate
      setImgRealEstate(base64)
    }
  }, [getFieldImgRealEstate])

  const handleChangeState = (value) => {
    setFieldsValue({
      municipality: resetSelects,
      colony: resetSelects,
    })
  }

  const handleChangeMunicipality = (value) => {
    setFieldsValue({
      colony: resetSelects,
    })
  }

  const filterFn = (input, option) => {
    const term = String(input).toLowerCase()
    const optLabel = String(option.props.children).toLowerCase()
    return String(optLabel).includes(term)
  }

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <PageHeaderStyled
        title="Inmobiliaria"
        subTitle="Actualizar inmobiliaria"
        className="px-0 pt-0"
      />

      <Form onSubmit={handleSubmitForm} colon={false} data-splitbee-event="account-edit-real-estate-save">
        <Row type="flex" gutter={48}>
          <Col xs={24}>
            <Avatar
              icon="user"
              src={imgRealEstate}
              size={100}
              className="block mx-auto mb-3"
            />

            {getFieldDecorator('img_real_estate', {})(<UploadLogo />)}
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem
              label="Nombre inmobiliaria"
              colon={false}
              className="font-weight-bold"
            >
              {getFieldDecorator('business_name', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Ingresa el nombre de la cuenta',
                  },
                ],
              })(<Input type="text" placeholder="Casas y Terrenos" />)}
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem
              label="Teléfono"
              colon={false}
              className="font-weight-bold"
            >
              {getFieldDecorator('phone_number', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Por favor escribe el teléfono de contacto',
                  },
                  {
                    required: true,
                    pattern: /^([0-9])(\w{9,9}\b)+$/,
                    message: 'El teléfono no es válido',
                  },
                ],
              })(<Input type="text" placeholder="312654789" />)}
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem label="Calle" colon={false} className="font-weight-bold">
              <span className="font-weight-light">
                {getFieldDecorator('street', {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Escribe la calle',
                    },
                  ],
                })(<Input type="text" />)}
              </span>
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem
              label="Número Exterior"
              colon={false}
              className="font-weight-bold"
            >
              {getFieldDecorator('num_exterior', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Escribe el número exterior. ',
                  },
                  {
                    required: true,
                    pattern: /^([0-9])/,
                    message: 'El número no es válido.',
                  },
                ],
              })(<Input type="text" />)}
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem
              label="Número Interior"
              colon={false}
              className="font-weight-bold"
            >
              {getFieldDecorator('num_interior', {
                rules: [
                  {
                    pattern: /^([0-9])/,
                    message: 'El número no es válido.',
                  },
                ],
              })(<Input type="text" />)}
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem label="Estado" colon={false} className="font-weight-bold">
              {getFieldDecorator('state', {
                rules: [
                  {
                    required: true,
                    message: 'Selecciona un estado',
                  },
                ],
              })(
                <SelectStates
                  noDefault
                  labelInValue
                  showSearch
                  placeholder="Selecciona un estado"
                  filterOption={filterFn}
                  onChange={handleChangeState}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem
              label="Municipio"
              colon={false}
              className="font-weight-bold"
            >
              {getFieldDecorator('municipality', {
                rules: [
                  {
                    required: true,
                    message: 'Selecciona un municipio',
                  },
                ],
              })(
                <SelectMunicipality
                  labelInValue
                  showSearch
                  placeholder="Selecciona un municipio"
                  filterOption={filterFn}
                  state={getFieldState.key}
                  onChange={handleChangeMunicipality}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem
              label="Colonia"
              colon={false}
              className="font-weight-bold"
            >
              {getFieldDecorator('colony', {
                rules: [
                  {
                    required: true,
                    message: 'Selecciona una colonia',
                  },
                ],
              })(
                <SelectNeighborhood
                  labelInValue
                  showSearch
                  filterOption={filterFn}
                  placeholder="Selecciona una colonia"
                  municipality={getFieldMunicipality.key}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} md={4} className="d-none">
            <FormItem label="ID" colon={false}>
              {getFieldDecorator('IDAddress', {})(<Input disabled />)}
            </FormItem>
          </Col>
        </Row>

        <Row type="flex" justify="end" gutter={[48, 16]}>
          <Col xs={24}>
            <Button
              type="primary"
              htmlType="submit"
              block={responsiveDesign ? true : false}
              className="float-right"
              loading={loading}
            >
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </ContainerLayout>
  )
}

const EditRealEstateWrapper = Form.create({})(EditRealEstate)
export default EditRealEstateWrapper
