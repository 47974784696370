import axios from 'axios'
import { fetchHeaders } from './utils'

export const ReportAPI = {
  basicReports,
}

const { api: API_URL } = process.env.ENV

async function basicReports(typeReport, params) {
  const HEADERS = await fetchHeaders()
  return axios({
    headers: HEADERS,
    method: 'GET',
    url: `${API_URL}reports/${typeReport}`,
    params,
  }).then(
    (response) => {
      const { data } = response

      return data
    },
    (error) => {
      console.log(error)
    }
  )
}
