import React, { useCallback } from 'react'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import Spanish from '@uppy/locales/lib/es_ES'
import ms from 'ms'
import getPresignedUrl from './getPresignedUrl'
import acquisitionApiClient from '../../../Services/acquisitionApiClient'
import * as Sentry from '@sentry/react'

const uppyConfig = ({ callBack }) => {
  const uppyInstance = new Uppy({
    meta: { type: 'avatar' },
    locale: Spanish,
    restrictions: {
      maxNumberOfFiles: 50,
      allowedFileTypes: ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG'],
    },
    autoProceed: true,
  })

  uppyInstance.use(AwsS3, {
    limit: 2,
    timeout: ms('1 minute'),
    getUploadParameters: (file) =>
      getPresignedUrl(file.extension, localStorage.getItem('CYTpropertyUuid')),
  })

  uppyInstance.on('complete', ({ successful }) => {
    try {
      if (callBack) {
        successful.forEach((response) => {
          callBack(response.xhrUpload.headers.uuid)
        })
      }
      const requestUrls = successful.map((response) => {
        return acquisitionApiClient.patch(
          `/customers/images/${response.xhrUpload.headers.uuid}/`,
          {
            status: 'finished',
          }
        )
      })
      Promise.all(requestUrls)
    } catch (err) {
      Sentry.captureException(err)
    }
  })

  return React.useMemo(() => {
    return uppyInstance
  }, [])
}

export default uppyConfig
