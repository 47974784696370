import useSWR from 'swr'
import log from '../Utils/logger'
import apiClient from '../Services/apiClient'

export const IntegrationsAPI = {
  getCRM,
  updateCRM,
  deleteCRM,
}

function getCRM() {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data)
  const { data, error } = useSWR(`clients/pro/link/crm/`, fetcher)
  return { data, error }
}

async function updateCRM(data) {
  return apiClient.patch('clients/pro/link/crm/', data)
}

async function deleteCRM() {
  try {
    await apiClient.delete('/clients/pro/link/crm/')
  } catch (err) {
    log.error(err)
  }
}
