import React, { useState, useEffect, useRef } from 'react'
import { useCombobox } from 'downshift'
import useNeighborhoods from '../../Hooks/useNeighborhoods'

export default function SelectNeighborhood({
  selectedItem,
  handleSelectedItemChange,
  form,
  field,
}) {
  const [inputItems, setInputItems] = useState([])

  const { data: neighborhoods, error } = useNeighborhoods(
    form?.values?.municipality?.id
  )

  useEffect(() => {
    if (neighborhoods?.length) {
      setInputItems(neighborhoods)
    }
  }, [neighborhoods])

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    items: inputItems,
    selectedItem: selectedItem,
    itemToString: (item) => (item ? item.name : ''),
    onSelectedItemChange: handleSelectedItemChange,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur: {
          const element = inputItems.find(
            (i) => i.name.toLowerCase() == changes.inputValue.toLowerCase()
          )
          if (element) {
            return {
              ...changes,
              selectedItem: element,
            }
          } else if (inputItems[0]) {
            return {
              ...changes,
              selectedItem: inputItems[0],
            }
          } else {
            return {
              ...changes,
              inputValue: '',
            }
          }
        }
        default: {
          return changes
        }
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (neighborhoods && inputValue.length > 4) {
        setInputItems(
          neighborhoods.filter((o) =>
            o.name.toLowerCase().includes(inputValue.toLowerCase())
          )
        )
      }
    },
  })

  return (
    <div className="relative capitalize">
      <div {...getComboboxProps()}>
        <input
          disabled={!form?.values?.municipality?.id && !inputItems?.length}
          className="capitalize shadow-sm focus:ring-blue-800 focus:border-blue-800 block w-full sm:text-sm md:text-lg border border-gray-300 rounded-md px-3 py-2"
          {...getInputProps()}
        />
      </div>

      <ul
        {...getMenuProps()}
        className="absolute w-full inset-y-full z-50 bg-white"
      >
        {isOpen &&
          inputItems.length > 0 &&
          inputItems.map((item, index) => (
            <li
              className={`
                ${
                  highlightedIndex === index
                    ? 'bg-blue-800 text-white'
                    : 'bg-white'
                } p-2`}
              key={`${item.name}-${index}`}
              {...getItemProps({ item, index })}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  )
}

const Input = ({ inputRef }) => {
  const r = useRef(inputRef)
  return <input className="input" ref={r} />
}
