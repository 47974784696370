export const requiredFields = {
  firstForm: [
    'description',
    'operation_type',
    'price_rent',
    'currency_rent',
    'price_sale',
    'currency_sale',
    'price_transfer',
    'currency_transfer',
    'membership',
  ],
  secondForm: ['colony', 'exterior_number', 'municipality', 'state', 'street'],
  thirdForm: ['images'],
}
