import React from 'react'
import { Row, Col, Button, Form, Input, InputNumber } from 'antd'

// Own components
import SelectStates from '../Shared/Selects/SelectStates'
import SelectMunicipality from '../Shared/Selects/SelectMunicipality'
import SelectNeighborhood from '../Shared/Selects/SelectNeighborhood'
import SelectPropertyType from '../Shared/Selects/SelectPropertyType'
import SelectOperationType from '../Shared/Selects/SelectOperationType'
import SelectPropertyStatus from '../Shared/Selects/SelectPropertyStatus'
import SelectOrigin from '../Shared/Selects/SelectOrigin'

import {
  formatNumberPrice,
  formatPriceInputNumber,
  parserPriceInputNumber,
} from '../Shared/utils'

const { Item: FormItem } = Form

const FiltersForm = (props) => {
  const {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    resetFields,
  } = props.form
  const { onSubmit, filtersAdded, hideFields, resetFilters, hideTags } = props

  const state = getFieldValue('state') || ''
  const municipality = getFieldValue('municipality') || ''
  const colony = getFieldValue('colony') || ''
  const propertType = getFieldValue('property_type') || ''
  const operationType = getFieldValue('operation_type') || ''
  const status = getFieldValue('status') || ''
  const origin = getFieldValue('origin') || ''
  const price_range = getFieldValue('price_range') || ''
  const price_min = getFieldValue('price_min')
  const price_max = getFieldValue('price_max')

  const tagsAdded = (values) => {
    let tagsNames = []
    Object.keys(values).forEach((key) => {
      let value = values[key]
      let informationTag

      if (key === 'email_membership') informationTag = `Buscar: ${value}`
      if (key === 'search') informationTag = `ID Propiedad: ${value}`
      if (key === 'property_type') {
        let typeProperty = propertType.map((item) => item.label).join(', ')
        informationTag = `Tipo de propiedad: ${typeProperty}`
      }
      if (key === 'operation_type') {
        let typeOperation = operationType.map((item) => item.label).join(', ')
        informationTag = `Tipo de operación: ${typeOperation}`
      }
      if (key === 'origin') {
        let originTag = origin.map((item) => item.label).join(', ')
        informationTag = `Origen: ${originTag}`
      }
      if (key === 'price_range') {
        let priceRangeTag = price_range
          .map((item) => `$${formatNumberPrice(item)}`)
          .join(' - ')
        informationTag = `Rango de precios: ${priceRangeTag}`
      }
      if (key === 'status') informationTag = `Estatus: ${status.label}`
      if (key === 'full')
        informationTag = `Mostrar el inventario de Casas y Terrenos`
      if (key === 'state') informationTag = `Estado: ${state.label}`
      if (key === 'municipality')
        informationTag = `Municipio: ${municipality.label}`
      if (key === 'colony') informationTag = `Colonia: ${colony.label}`

      if (informationTag) {
        tagsNames.push(informationTag)
      }
    })

    filtersAdded(tagsNames)
  }

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        Object.keys(values).forEach((key) => {
          ;(values[key] === undefined && delete values[key]) ||
            (values[key] === '' && delete values[key]) ||
            (values[key].key === '' && delete values[key]) ||
            (values[key].length === 0 && delete values[key])
        })
        const {
          state,
          property_type,
          operation_type,
          status,
          origin,
          price_min,
          price_max,
        } = values

        if (property_type) {
          values.property_type = property_type.map((item) => item.key).join(',')
        }
        if (operation_type) {
          values.operation_type = operation_type
            .map((item) => item.key)
            .join(',')
        }

        let price_range = ''
        if (price_min) price_range = price_min
        if (price_max) price_range += `,${price_max}`
        if (price_range) values.price_range = price_range

        delete values.price_min
        delete values.price_max

        if (origin) {
          values.origin = origin.map((item) => item.key).join(',')
        }
        if (status) values.status = status.key
        if (state) values.state = state.key
        if (municipality) values.municipality = municipality.key
        if (colony) values.colony = colony.key

        onSubmit(values)
        if (!hideTags) tagsAdded(values)
      }
    })
  }

  const cleanFilters = () => {
    onSubmit()
    resetFields()
    if (!hideTags) resetFilters()
  }

  const filterFn = (input, option) => {
    const term = String(input).toLowerCase()
    const optLabel = String(option.props.children).toLowerCase()
    return String(optLabel).includes(term)
  }

  return (
    <Form
      onSubmit={submitForm}
      className="login-form"
      colon={false}
      hideRequiredMark
      className={!hideTags ? '' : 'bg-light p-3'}
      data-splitbee-event="inventory-cyt-apply-filters"
    >
      <Row type="flex" gutter={16}>
        {!hideFields ? (
          <Col xs={24} md={6}>
            <FormItem label="Buscar por correo de promotor">
              {getFieldDecorator('email_membership', {})(<Input />)}
            </FormItem>
          </Col>
        ) : null}
        <Col xs={6} md={4}>
          <FormItem label="ID Propiedad">
            {getFieldDecorator('search', {})(<Input />)}
          </FormItem>
        </Col>
        <Col xs={18} md={7}>
          <FormItem label="Tipo de propiedad">
            {getFieldDecorator(
              'property_type',
              {}
            )(<SelectPropertyType mode="multiple" labelInValue />)}
          </FormItem>
        </Col>

        <Col xs={24} md={7}>
          <FormItem label="Tipo de operación">
            {getFieldDecorator(
              'operation_type',
              {}
            )(
              <SelectOperationType
                placeholder="Selecciona un tipo de operación"
                mode="multiple"
                labelInValue
              />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem label="Estado">
            {getFieldDecorator(
              'state',
              {}
            )(
              <SelectStates
                labelInValue
                placeholder="Selecciona un estado"
                filterOption={filterFn}
                showSearch
              />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem label="Municipio">
            {getFieldDecorator(
              'municipality',
              {}
            )(
              <SelectMunicipality
                labelInValue
                placeholder="Selecciona un municipio"
                state={state.key}
                filterOption={filterFn}
                showSearch
              />
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem label="Colonia">
            {getFieldDecorator(
              'colony',
              {}
            )(
              <SelectNeighborhood
                labelInValue
                placeholder="Selecciona una colonia"
                municipality={municipality.key}
                filterOption={filterFn}
                showSearch
              />
            )}
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label="Rango de precios">
            <Row type="flex" gutter={16}>
              <Col xs={12}>
                <FormItem label="Mínimo" className="mb-0">
                  {getFieldDecorator('price_min', {
                    rules: [
                      {
                        required: price_max ? true : false,
                        message: 'Debes ingresar un precio mínimo.',
                      },
                    ],
                  })(
                    <InputNumber
                      formatter={(value) => formatPriceInputNumber(value)}
                      parser={(value) => parserPriceInputNumber(value)}
                      className="d-block w-100"
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label="Máximo" className="mb-0">
                  {getFieldDecorator('price_max', {
                    rules: [
                      {
                        required: price_min ? true : false,
                        message: 'Debes ingresar un precio máximo.',
                      },
                    ],
                  })(
                    <InputNumber
                      formatter={(value) => formatPriceInputNumber(value)}
                      parser={(value) => parserPriceInputNumber(value)}
                      className="d-block w-100"
                      disabled={price_min ? false : true}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </FormItem>
        </Col>

        <Col xs={24} md={24} className="d-none">
          <FormItem label="Origen">
            {getFieldDecorator(
              'origin',
              {}
            )(
              <SelectOrigin
                labelInValue
                mode="multiple"
                placeholder="Selecciona el origen"
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col>
          <Button onClick={cleanFilters} className="mr-2" data-splitbee-event="inventory-cyt-clean-filters">
            Limpiar filtros
          </Button>
          <Button type="primary" htmlType="submit">
            Aplicar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const FiltersFormWrapper = Form.create({})(FiltersForm)
export default FiltersFormWrapper
