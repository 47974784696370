import React, { useEffect, useState, forwardRef } from 'react'
import { Select } from 'antd'
import log from '../../Utils/logger'
import apiClient from '../../Services/apiClient'

const { Option } = Select

const SelectOrigin = ({ ...props }, ref) => {
  const [dataSource, setDataSource] = useState(null)

  const fetchData = async () => {
    try {
      const { data } = await apiClient.get('/property/feeds/')
      // remove feeds with numeric name
      const cleanData = data.filter(
        (d) => !isFinite(d.portal) && d.portal !== 'undefined'
      )
      setDataSource(cleanData)
    } catch (err) {
      log.error(err)
    }
  }

  useEffect(() => {
    fetchData()
    return () => {
      setDataSource([])
    }
  }, [])

  return (
    <Select {...props} ref={ref}>
      {dataSource &&
        dataSource.length &&
        dataSource.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.portal}
          </Option>
        ))}
    </Select>
  )
}

export default forwardRef(SelectOrigin)
