import useSWR from 'swr'
import apiClient from '../Services/apiClient'

function useMunicipalities(stateID) {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data?.results)

  const { data, error } = useSWR(
    stateID ? `/locations/municipality/?state_id=${stateID}` : null,
    fetcher
  )

  const loading = !data && !error

  return { data, error, loading }
}

export default useMunicipalities
