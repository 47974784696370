import acquisitionApiClient from "../../../Services/acquisitionApiClient";
import * as Sentry from '@sentry/react'

export default async function(extension, uuid) {
  return new Promise((resolve, reject) => {
    acquisitionApiClient.post(`/customers/properties/${uuid}/signature_urls/`, {
      image_type: extension,
    })
    .then(res => {
      resolve({
        method: 'POST',
        url: res.data.url ,
        fields: res.data.fields,
        headers: {uuid: res.data.uuid}
      })
    })
    .catch(err => {
      Sentry.captureException(err)
      reject(err)
    })
  })
}
