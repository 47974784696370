import useSWR from 'swr'
import apiClient from '../Services/apiClient'
import queryString from 'query-string'

function usePromoters(params) {
  const fetcher = (url) => apiClient.get(url).then((res) => res?.data?.results)

  const { data, error } = useSWR(
    `/membership?${queryString.stringify(params)}`,
    fetcher
  )

  return { data, error }
}

export default usePromoters
