import axios from 'axios'

const proxyImg =
  process.env.REACT_APP_APP_ENV === 'development'
    ? 'https://imagery.staging.casasyterrenos.com'
    : 'https://imagery.casasyterrenos.com'

export default function getBase64(url) {
  return new Promise((resolve, reject) => {
    let requestUrl
    if (url.includes('casasyterrenos.com')) {
      requestUrl = `${url}?date=${new Date().getTime()}`
    } else {
      requestUrl = url.includes('?')
        ? `${proxyImg}/${url}`
        : `${proxyImg}/${url}?date=${new Date().getTime()}`
    }

    axios
      .get(requestUrl, {
        responseType: 'arraybuffer',
      })
      .then((imageResponse) =>
        resolve(Buffer.from(imageResponse.data, 'binary').toString('base64'))
      )
      .catch((err) => {
        reject(err)
      })
  })
}
