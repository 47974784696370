import React from 'react'
import { Row, Col, Button, Form, Radio } from 'antd'

const { Item: FormItem } = Form
const { Group: RadioGroup, Button: RadioButton } = Radio

const ContactedContacts = (props) => {
  const {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    resetFields,
  } = props.form
  const { onSubmit } = props
  const { id } = props.contact

  const submitForm = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        values.status = 2
        onSubmit(values)
        resetFields()
      }
    })
  }

  getFieldDecorator('id', { initialValue: id })

  return (
    <Form onSubmit={submitForm} className="login-form" colon={false}>
      <Row type="flex" gutter={[48, 32]}>
        <Col xs={24}>
          <FormItem label="Medio de contacto">
            {getFieldDecorator('status_description', {
              rules: [
                { required: true, message: 'Ingresa la manera de contacto' },
              ],
            })(
              <RadioGroup className="w-100 text-center" buttonStyle="solid">
                <RadioButton
                  className="w-50"
                  value="Correo"
                  data-splitbee-event="contacts-contacted-lead-email"
                >
                  Correo
                </RadioButton>
                <RadioButton
                  className="w-50"
                  value="Teléfono"
                  data-splitbee-event="contacts-contacted-lead-phone"
                >
                  Teléfono
                </RadioButton>
              </RadioGroup>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            data-splitbee-event="contacts-contacted-lead-save"
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const ContactedContactsWrapper = Form.create({})(ContactedContacts)
export default ContactedContactsWrapper
