import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Divider, Tooltip, Icon, Typography } from 'antd'

const { Item: FormItem } = Form
const { Paragraph } = Typography

const DetailsContacts = (props) => {
  const {
    property_title,
    property_classification,
    property_id,
    message,
    name,
    phone,
    email,
    created,
    shared_by_email,
    friend_name,
    friend_email,
    carrier,
    valid_phone,
    phone_type,
  } = props.contact

  return (
    <Fragment>
      <Row type="flex" justify="start" gutter={48}>
        <Col xs={12}>
          <FormItem
            label="ID Propiedad"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light">{property_id}</span>
          </FormItem>
        </Col>
        {name && (
          <Col xs={12}>
            <FormItem
              label="Nombre del contacto"
              className="font-weight-bold mb-0"
              colon={false}
            >
              <span className="font-weight-light">{name}</span>
            </FormItem>
          </Col>
        )}
        {phone && (
          <Col xs={12}>
            <FormItem
              label="Teléfono"
              className="font-weight-bold mb-0"
              colon={false}
            >
              <span className="font-weight-light">
                <Paragraph className="mb-0" copyable={{ text: phone }}>
                  <a
                    href={`https://api.whatsapp.com/send?phone=+52${phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-1"
                  >
                    {phone}
                  </a>
                  {!valid_phone && (
                    <Tooltip title="El teléfono está validado.">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#52c41a"
                      />
                    </Tooltip>
                  )}
                </Paragraph>
              </span>
            </FormItem>
          </Col>
        )}
        {carrier && (
          <Col xs={12}>
            <FormItem
              label="Clasificación propiedad"
              className="font-weight-bold mb-0"
              colon={false}
            >
              <span className="font-weight-light">{carrier}</span>
            </FormItem>
          </Col>
        )}
        {phone_type && (
          <Col xs={12}>
            <FormItem
              label="Clasificación propiedad"
              className="font-weight-bold mb-0"
              colon={false}
            >
              <span className="font-weight-light">{phone_type}</span>
            </FormItem>
          </Col>
        )}
        {email && (
          <Col xs={12}>
            <FormItem
              label="Correo"
              className="font-weight-bold mb-0"
              colon={false}
            >
              <span className="font-weight-light">
                <Paragraph className="mb-0" copyable={{ text: email }}>
                  <a href={`mailto:${email}`}>{email}</a>
                </Paragraph>
              </span>
            </FormItem>
          </Col>
        )}
        <Col xs={12}>
          <FormItem
            label="Fecha y hora de contacto"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light">{created}</span>
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem
            label="Propiedad de interes"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light">{property_title}</span>
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            label="Clasificación propiedad"
            className="font-weight-bold mb-0"
            colon={false}
          >
            <span className="font-weight-light">{property_classification}</span>
          </FormItem>
        </Col>
        {shared_by_email ? (
          <Fragment>
            <Col xs={12}>
              <FormItem
                label="Nombre de quien compartio"
                className="font-weight-bold mb-0"
                colon={false}
              >
                <span className="font-weight-light">{friend_name}</span>
              </FormItem>
            </Col>
            <Col xs={12}>
              <FormItem
                label="Correo de quien compartio"
                className="font-weight-bold mb-0"
                colon={false}
              >
                <span className="font-weight-light">{friend_email}</span>
              </FormItem>
            </Col>
          </Fragment>
        ) : null}
        <Col xs={24}>
          <Divider>
            <Link to={`/properties/${property_id}`} target="_blank">
              <span className="font-weight-light block text-center">
                Más información de la propiedad.
              </span>
            </Link>
          </Divider>
        </Col>
        {message && (
          <Col xs={24}>
            <FormItem
              label="Mensaje"
              className="font-weight-bold mb-0"
              colon={false}
            >
              <span className="font-weight-light lh-6 text-justify block">
                {message}
              </span>
            </FormItem>
          </Col>
        )}
      </Row>
    </Fragment>
  )
}

export default DetailsContacts
