import styled from 'styled-components'
import { Modal } from 'antd'

const ModalStyled = styled(Modal)`
  .ant-modal-header {
    border-radius: 5px 5px 0 0;
    background: rgba(23, 60, 117, 1);
    border-bottom: 1px solid transparent;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.35);
  }
  .ant-modal-header > .ant-modal-title {
    font-size: 24px;
  }

  .ant-modal-content {
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.45);
  }

  .ant-modal-content > .ant-modal-close,
  .ant-modal-header > .ant-modal-title {
    color: #fff;
  }

  .ant-modal-content > .ant-modal-close:hover {
    color: #bbb;
  }

  .ant-modal-content > .ant-modal-footer {
    border-top: 1px solid transparent;
  }
`

ModalStyled.defaultProps = {
  backgroundColor: 'transparent',
}

export default ModalStyled
