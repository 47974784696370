import react, { useEffect, useState } from 'react'

const useAccount = () => {
  const [accountType, setAccountType] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('AccountPermissions')).state
        .permissions.client_permissions?.client_account_type
    } catch (error) {
      return ''
    }
  })
  const [accountRole, setAccountRole] = useState(() => {
    try {
      return setAccountRole(
        JSON.parse(localStorage.getItem('AccountPermissions')).state.permissions
          .client_permissions?.role
      )
    } catch (error) {
      return ''
    }
  })

  return [accountType, accountRole]
}

export default useAccount
