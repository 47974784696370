import React, { forwardRef } from 'react'
import { Select } from 'antd'

const { Option } = Select

const SelectPropertyStatus = forwardRef(({ ...props }, ref) => {
  return (
    <Select {...props} ref={ref}>
      <Option value={['published,pending,inactive']}>Todos</Option>
      <Option value="published">Publicado</Option>
      <Option value="pending">Pendiente</Option>
      <Option value="inactive">Inactivo</Option>
    </Select>
  )
})

export default SelectPropertyStatus
