import React, { useState, useEffect } from 'react'

const SelectBox = (props) => {
  const { option, callBack, fieldValue } = props
  const [checked, setChecked] = useState(null)

  useEffect(() => {
    if (fieldValue && fieldValue.includes(option.value)) {
      setChecked(true)
    }
  }, [fieldValue])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function toggle() {
    let x = !checked
    setChecked(!checked)
    callBack(x, option.value)
  }

  return (
    <div
      value={option.value}
      onClick={toggle}
      className="md:h-20 w-full  relative block  rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
    >
      <>
        <div className="flex flex-1 items-center">
          <div className="text-sm  flex-1">
            <label
              as="p"
              className="text-base leading-6 font-medium text-gray-900 text-center"
            >
              {option.name}
            </label>
          </div>
        </div>

        <div
          className={classNames(
            checked ? 'border-yellow-500' : 'border-transparent',
            'absolute -inset-px rounded-lg border-2 pointer-events-none'
          )}
          aria-hidden="true"
        />
      </>
    </div>
  )
}

SelectBox.defaultProps = {
  fieldValue: [],
}

export default SelectBox
