import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Menu, Table, Icon, Input, message } from 'antd'

//Own components
import ContainerLayout from '../Layouts/ContainerLayout'
import PageHeaderStyled from '../Shared/Common/PageHeader'
import PeopleIcon from '../Shared/Icons/People'
import Actions from '../Shared/Common/Actions'
import ModalStyled from '../Shared/Common/Modal'
import ActionsPromoter from './Actions/'

// Utils
import { executivesRoles } from '../Shared/utils'
import log from '../Utils/logger'

// Services
import { PromoterAPI } from '../Services/promoter'
//Hooks
import { useWindowWidthSize } from '../Shared/customHooks'

const executivesStatus = ['Activo', 'Inactivo', 'Desactivado']
const userData = JSON.parse(localStorage.getItem('userData')) || {}
const { email: ACTUALEMAILUSER } = userData

const PromoterList = (props) => {
  const [keepValues, setKeepValues] = useState({})
  const [dataSource, setDataSource] = useState([])
  const [dataRow, setDataRow] = useState(null)
  const [action, setAction] = useState(null)
  const [titleModal, setTitleModal] = useState('')
  const [dataFiltered, setDataFiltered] = useState(null)
  const [visibleModal, setVisibleModal] = useState(false)
  const [mobileDevice, setMobileDevice] = useState(false)
  const widthDevice = useWindowWidthSize()

  const fetchData = async (values = {}) => {
    let data = await PromoterAPI.getPromoters(values)
    setDataSource(data)
  }

  const handleChangeTable = (pagination, filters, sorter) => {
    const page = pagination.current
    const params = Object.assign(keepValues, { page })
    fetchData(params)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (widthDevice <= 768) setMobileDevice(true)
    else setMobileDevice(false)
  }, [widthDevice])

  const toggleModal = () => setVisibleModal(!visibleModal)

  const menuActions = (record) => {
    const { id: userid, status, email } = record

    return (
      <Menu onClick={handleActions}>
        <Menu.Item
          key="edit"
          userid={userid}
          data-splitbee-event="promoters-edit-promoter"
        >
          <Icon type="edit" style={{ color: '#332AAD' }} /> Editar
        </Menu.Item>

        {status === 1 && ACTUALEMAILUSER !== email ? (
          <Menu.Item
            key="inactive"
            userid={userid}
            data-splitbee-event="promoters-inactive-promoter"
          >
            <Icon type="eye-invisible" style={{ color: '#6C6C6C' }} /> Inactivar
          </Menu.Item>
        ) : null}
        {status === 2 && ACTUALEMAILUSER !== email ? (
          <Menu.Item
            key="active"
            userid={userid}
            data-splitbee-event="promoters-active-promoter"
          >
            <Icon type="eye" style={{ color: '#6C6C6C' }} /> Activar
          </Menu.Item>
        ) : null}
        <Menu.Item
          key="delete"
          userid={userid}
          data-splitbee-event="promoters-delete-promoter"
        >
          <Icon type="delete" style={{ color: '#AD322A' }} /> Eliminar
        </Menu.Item>
        {/*<Menu.Item key="details" userid={userid}>
            <Icon type="read" style={{color: "#AD2AA5"}} /> Más detalles
          </Menu.Item>*/}
      </Menu>
    )
  }

  const handleActions = (e) => {
    const { key: action } = e
    const { userid } = e.item.props

    const dataUser = dataTruth.find((usr) => Number(usr.id) === Number(userid))

    if (action === 'edit') {
      setVisibleModal(true)
      setAction(action)
      setTitleModal(`Promotor | ${dataUser.first_name} ${dataUser.last_name}`)
      setDataRow(dataUser)
    } else if (action === 'delete') {
      setVisibleModal(true)
      setAction(action)
      setTitleModal(`Promotor | ${dataUser.first_name} ${dataUser.last_name}`)
      setDataRow(dataUser)
    } else if (action === 'details') {
      alert('detailsss')
    } else if (action === 'inactive' || action === 'active') {
      let title = 'Inactivar'
      if (action !== 'inactive') title = 'Activar'
      setVisibleModal(true)
      setTitleModal(
        `${title} promotor | ${dataUser.first_name} ${dataUser.last_name}`
      )
      setDataRow(dataUser)
      setAction(action)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'Apellido Paterno',
      dataIndex: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: 'Apellido Materno',
      dataIndex: 'second_last_name',
      sorter: (a, b) => {
        const fp = a.second_last_name || ''
        const sp = b.second_last_name || ''
        return fp.localeCompare(sp)
      },
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Whatsapp',
      dataIndex: 'whatsapp',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone_number',
      render: (text) => {
        if (text) {
          const format = String(String(text).replace('+52', '')).match(
            /.{1,5}/g
          )
          return format.join('-')
        }
      },
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      render: (text) => executivesRoles[text],
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      render: (text) => executivesStatus[text - 1],
      sorter: (a, b) => true,
    },
    {
      key: 'action',
      width: 1,
      render: (text, record) => (
        <Actions menu={menuActions(record)} placement="bottomRight" />
      ),
    },
  ]

  const handleSubmitEditPromoter = async (dataForm) => {
    try {
      const { id: membershipID } = dataForm
      let messageTXT
      let response = await PromoterAPI.editPromoter(membershipID, dataForm)
      const { first_name, last_name } = response

      let getIndexPromoter = dataTruth.findIndex(
        (usr) => Number(usr.id) === Number(response.id)
      )

      if (action === 'edit') {
        dataTruth[getIndexPromoter] = response
        messageTXT = `Se ha actualizado el promotor ${first_name} ${last_name}`
      }
      if (action === 'delete') {
        dataTruth.splice(getIndexPromoter, 1)
        messageTXT = `Se ha eliminado el promotor ${first_name} ${last_name}`
      }
      toggleModal()
      message.success(messageTXT)
      setDataRow(null)
    } catch (err) {
      setDataRow(null)
      console.log(err)
    }
  }

  const handleSubmitCreatePromoter = async (dataForm, callBack) => {
    try {
      let response = await PromoterAPI.signUpPromoter(dataForm)
      let { data } = response

      if (data.hasOwnProperty('Error')) {
        throw new Error(data.Error)
      } else {
        message.success('Se creó el promotor correctamente')
        if (callBack) {
          callBack()
        }
        toggleModal()
        fetchData()
      }
    } catch (err) {
      log.error(err)
      log.message(dataForm)
    }
  }

  const handleOnChangeSearchFilter = async (e) => {
    let { value: search } = e.target
    search = search.toLowerCase()

    if (search.length > 0) {
      const params = Object.assign(keepValues, { search })
      let data = await PromoterAPI.getPromoters(params)
      setDataSource(data)
    } else {
      fetchData()
      setDataFiltered(null)
    }
  }

  const handleSubmitOnActions = async (dataForm, cb) => {
    if (action === 'edit' || action === 'delete')
      handleSubmitEditPromoter(dataForm)
    if (action === 'create') handleSubmitCreatePromoter(dataForm, cb)
    if (action === 'inactive' || action === 'active')
      handleSubmitStatusPromoter(dataForm)
  }

  const handleSubmitStatusPromoter = async (dataForm) => {
    const { id: promoterID } = dataForm
    let messageTXT
    let response = await PromoterAPI.editPromoter(promoterID, dataForm)

    if (Object.prototype.hasOwnProperty.call(response, 'state')) {
      if (action === 'inactive') messageTXT = 'Se ha inactivado el promotor.'
      if (action === 'active') messageTXT = 'Se ha activado el promotor.'
    }
    toggleModal()
    message.success(messageTXT)
  }

  const handleOnClickCreatePromoter = (e) => {
    toggleModal()
    setTitleModal('Crear promotor')
    setAction('create')
  }

  const dataTruth = dataFiltered ? dataFiltered : dataSource.results

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <PageHeaderStyled title="Promotores" className="px-0 pt-0" />
      <Row type="flex" className="mb-2">
        <Col xs={24} lg={14}>
          <Input
            placeholder="Buscar por nombre / teléfono"
            className="float-left"
            onChange={handleOnChangeSearchFilter}
          />
        </Col>
        <Col xs={24} lg={10}>
          <Button
            type="primary"
            block={mobileDevice}
            className={`float-right ${mobileDevice && 'my-2'}`}
            onClick={handleOnClickCreatePromoter}
            data-splitbee-event="promotors-create"
          >
            <PeopleIcon style={{ width: '14px' }} /> Crear promotor
          </Button>
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24}>
          <Table
            dataSource={dataTruth}
            rowKey="id"
            onChange={handleChangeTable}
            columns={columns}
            size="middle"
            pagination={{
              defaultCurrent: 1,
              total: dataSource.count,
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <ModalStyled
        visible={visibleModal}
        centered
        destroyOnClose={true}
        title={titleModal}
        onCancel={toggleModal}
        footer={null}
      >
        {action ? (
          <ActionsPromoter
            action={action}
            promoter={dataRow}
            onSubmit={handleSubmitOnActions}
            onCancel={toggleModal}
          />
        ) : null}
      </ModalStyled>
    </ContainerLayout>
  )
}

export default PromoterList
