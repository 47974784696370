import React, { useEffect, useState } from 'react'
import LeadDetail from './components/LeadDetail'
import client from '../Services/apiClient'
import { message } from 'antd'
import ModalStyled from '../Shared/Common/Modal'
import ActionsContacts from './Actions'
import { ContactsAPI } from '../Services/contacts'

export default function Contact(props) {
  const [action, setAction] = useState(null)
  const [dataRow, setDataRow] = useState(null)
  const [titleModal, setTitleModal] = useState('')
  const [visibleModal, setVisibleModal] = useState(false)
  const [detailLead, setDetailLead] = useState(null)

  const { params } = props.match
  const { contactId } = params

  const getLeads = async () => {
    try {
      const response = await client.get('list_contact/', {
        params: {
          id: contactId,
        },
      })

      setDetailLead(response.data.results[0])
    } catch (err) {
      console.log(err)
    }
  }

  const showActionModal = ({ action, contactId }) => {
    if (!action || !contactId) {
      throw new Error('Expected arguments { action, contactId }')
    }

    switch (action) {
      case 'contacted':
        setTitleModal('Marcar como contactado')
        setAction(action)
        setDataRow(detailLead)
        setVisibleModal(true)
        break
      case 'discarded':
        setTitleModal('Marcar como descartado')
        setAction(action)
        setDataRow(detailLead)
        setVisibleModal(true)
        break
      default:
        break
    }
  }

  const handleSubmitOnActions = async (dataForm) => {
    if (action === 'contacted' || action === 'discarded')
      handleSubmitContactedDiscardedContact(dataForm)
  }

  const handleSubmitContactedDiscardedContact = async (dataForm) => {
    const { id: contactID, ...restData } = dataForm
    await ContactsAPI.updateContact(contactID, restData)
    setVisibleModal(!visibleModal)
    message.success('Se ha actualizado la información de contacto')
  }

  useEffect(() => {
    getLeads()
  }, [])

  return (
    <div className="p-3">
      {detailLead && (
        <LeadDetail
          callBack={showActionModal}
          name={detailLead.name}
          phone={detailLead.phone}
          email={detailLead.email}
          image={detailLead.image_cover}
          message={detailLead.message}
          title={detailLead.property_title}
          contactId={detailLead.id}
          propertyId={detailLead.property_id}
        ></LeadDetail>
      )}

      <ModalStyled
        visible={visibleModal}
        onCancel={() => setVisibleModal(!visibleModal)}
        title={titleModal}
        centered
        footer={null}
      >
        {action ? (
          <ActionsContacts
            action={action}
            contact={dataRow}
            onSubmit={handleSubmitOnActions}
          />
        ) : null}
      </ModalStyled>
    </div>
  )
}
