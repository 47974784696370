import React, { useState, useEffect, Fragment } from 'react'
import '@stripe/stripe-js'
import * as Sentry from '@sentry/react'
import { SWRConfig } from 'swr'
import Amplify, { Auth, Hub } from 'aws-amplify'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Button, notification } from 'antd'
import apiClient from './Services/apiClient'
import splitbee from '@splitbee/web'
import useLocalStorage from './Hooks/useLocalStorage'

//Logic
import AccountPermissions from './Services/auth'

// Own Components
import Login from './Login'
import MainLayout from './Layouts/MainLayout'
import PersonalInformation from './Account/PersonalInformation'
import EditRealEstate from './Account/RealEstate/Edit'
import PromoterList from './Promoter'
import Resumen from './Resumen'
// import DevelopmentsList from './Developments'
// import DevelopmentsCreate from './Developments/Create'
import PropertiesList from './Properties'
import PropertiesCreate from './Properties/Create'
import PropertiesEdit from './Properties/Edit'
import CreateProperty from './Propiedades/Create'
import EditProperty from './Propiedades/Edit'
import ExpiringProperties from './Properties/Expiring'
import PropertiesFullViewDetails from './Properties/FullViewDetails'
import ContactsList from './Contacts'
import Contact from './Contacts/Contact'
import ReportsList from './Reports'
import AccountMain from './Account'
import Signup from './Signup'
import Packages from './Packages'
import ResetPassword from './ResetPassword'
import InventoryCYTList from './InventoryCYT'
import IntegrationSection from './Integrations'
import GetInTouch from './Shared/Pages/Contact'
import Renovacion from './Renovacion'
import Receipts from './Receipts'
import DeactivateCampaignAccount from './Links/DeactivateCampaignAccount'
import JoinForm from './JoinForm'
import SelectPlan from './Signup/select_plan'
import ConfirmData from './Signup/confirmation'
import LocationPrice from './Signup/location_price'
import Amenities from './Signup/amenities'
import Description from './Signup/description'
import Properties from './Signup/propiedades'
import Facturacion from './Signup/facturacion'
import RegisterResume from './Signup/resumen'
import log from './Utils/logger'

import withAcquisitionLayout from './Layouts/AcquisitionLayout'

import useContract from './Hooks/useContract'
import useUser from './Hooks/useUser'
import usePermissions from './Hooks/usePermissions'
import useSession from './Hooks/useSession'

import FAQ from './Shared/Pages/FAQ'
import './tailwind.css'
import './App.css'

const { aws } = process.env.ENV

Amplify.configure(JSON.parse(aws))

const App = () => {
  const [useBeta, setUseBeta] = useLocalStorage('useBeta', true)
  const [accountType, setAccountType] = useState(null)
  const [messageLogin, setMessageLogin] = useState(null)
  const { contract, expired, differenceDays, error } = useContract(userSession)
  const setUser = useUser((state) => state.setUser)
  const setPermissions = usePermissions((state) => state.setPermissions)
  const permissions = usePermissions((state) => state.permissions)
  const setSession = useSession((state) => state.setSession)
  const userSession = useSession((state) => state.session)

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    if (params.useBeta == 'false') {
      setUseBeta(params.useBeta)
    } else if (useBeta == 'false') {
      return
    } else {
      window.location.href = 'https://pro-beta.casasyterrenos.com/'
    }
  }, [])

  function setUserReport(user) {
    try {
      if (Sentry && user) {
        Sentry.setUser({
          email: user.email,
          id: user.id,
          username: `${user.first_name} ${user.last_name} `,
        })
      }
      if (Userback?.setData) {
        Userback.email = user.email
        Userback.name = `${user.first_name} ${user.last_name} `
        Userback.setData({
          user_id: user.id,
          contract_start_date: user.contract_dates.start_date,
          contract_end_date: user.contract_dates.end_date,
          business_name: user.business_name,
          client_id: user.client,
          role: user.role,
          phone: user.phone_number,
        })
      }
      if (window?.ga) {
        ga('set', 'userId', user.id)
      }
    } catch (err) {
      log.error(err)
    }
  }

  const getUser = async () => {
    try {
      const response = await apiClient.get('/accounts/me/')

      const user = response.data[0]
      setUser(user)
      setUserReport(user)
      splitbee.user.set(user)
    } catch (err) {
      setSession(null)
      setUser(null)
      log.error(err)
    }
  }

  const getSetPermissions = async () => {
    try {
      const response = await apiClient.get('/auth/permissions_user/')
      setPermissions(response.data)
    } catch (err) {
      setPermissions(null)
      log.error(err)
    }
  }

  Hub.listen('auth', async (data) => {
    const { payload } = data
    switch (payload.event) {
      case 'signIn':
        getSetPermissions()
        getUser()
        setSession(payload.data.signInUserSession)
        break
      case 'signOut':
        setSession(null)
        localStorage.removeItem('userData')
        break
      case 'tokenRefresh_failure':
        setSession(null)
        localStorage.removeItem('userData')
        location.href = '/'
        break
      // no default
    }
  })

  useEffect(() => {
    getUser()
    getSetPermissions()
  }, [])

  const renewButton = (
    <Button
      type="primary"
      onClick={() => (window.location.href = '/renovacion')}
    >
      Renovar
    </Button>
  )

  useEffect(() => {
    if (
      differenceDays &&
      differenceDays <= 15 &&
      window.location.pathname !== '/renovacion'
    ) {
      const expiringDate = new Date(contract.end_date)
      notification.open({
        message: `Tu paquete está por expirar el ${expiringDate.toLocaleDateString()}`,
        description: 'Renueva ahora y no pierdas acceso.',
        btn: renewButton,
      })
    }
  }, [differenceDays])

  useEffect(() => {
    if (permissions) {
      setAccountType(permissions)
    }
    return () => setAccountType(null)
  }, [permissions])

  const AdvertiserRoutes = () => {
    return (
      <MainLayout>
        <Switch>
          <Route
            path="/account/real-estate"
            render={(props) => <EditRealEstate {...props} />}
          />
          <Route
            path="/account/personal-info"
            render={(props) => <PersonalInformation {...props} />}
          />
          <Route
            path="/account/"
            render={(props) => <AccountMain {...props} />}
          />
          <Route
            path="/properties/create"
            render={(props) => <PropertiesCreate {...props} />}
          />
          <Route
            path="/properties/:propertyID/edit"
            render={(props) => <PropertiesEdit {...props} />}
          />
          <Route
            path="/properties/:propertyID/"
            render={(props) => <PropertiesFullViewDetails {...props} />}
          />
          <Route
            path="/properties/expiring"
            render={(props) => <ExpiringProperties {...props} />}
          />
          <Route
            path="/properties"
            render={(props) => <PropertiesList {...props} />}
          />
          <Route
            path="/contacts/:contactId/"
            render={(props) => <Contact {...props} />}
          />
          <Route
            path="/contacts"
            render={(props) => <ContactsList {...props} />}
          />

          <Route
            path="/renovacion"
            render={(props) => <Renovacion {...props} />}
          />

          <Route
            path="/contact-us"
            render={(props) => <GetInTouch {...props} />}
          />

          <Route path="/" render={(props) => <ContactsList {...props} />} />
        </Switch>
      </MainLayout>
    )
  }

  const MainRoutes = () => {
    return (
      <MainLayout>
        <Switch>
          <Route
            path="/account/real-estate"
            render={(props) => <EditRealEstate {...props} />}
          />
          <Route path="/resumen" render={(props) => <Resumen {...props} />} />
          <Route
            path="/account/personal-info"
            render={(props) => <PersonalInformation {...props} />}
          />
          <Route
            path="/account/"
            render={(props) => <AccountMain {...props} />}
          />
          <Route path="/propiedades/crear">
            <CreateProperty />
          </Route>
          <Route
            path="/propiedades/editar/:propertyId"
            render={(props) => <EditProperty {...props} />}
          />
          <Route
            path="/promoter"
            render={(props) => <PromoterList {...props} />}
          />
          <Route
            path="/properties/create"
            render={(props) => <PropertiesCreate {...props} />}
          />
          <Route
            path="/properties/expiring"
            render={(props) => <ExpiringProperties {...props} />}
          />
          <Route
            path="/properties/:propertyID/edit"
            render={(props) => <PropertiesEdit {...props} />}
          />
          <Route
            path="/properties/:propertyID/"
            render={(props) => <PropertiesFullViewDetails {...props} />}
          />
          <Route
            path="/properties"
            render={(props) => <PropertiesList {...props} />}
          />
          <Route
            path="/contacts/:contactId/"
            render={(props) => <Contact {...props} />}
          />
          <Route
            path="/contacts"
            render={(props) => <ContactsList {...props} />}
          />

          <Route
            path="/reports"
            render={(props) => <ReportsList {...props} />}
          />
          <Route
            path="/contact-us"
            render={(props) => <GetInTouch {...props} />}
          />
          <Route
            path="/inventory-cyt"
            render={(props) => <InventoryCYTList {...props} />}
          />
          <Route
            path="/integrations"
            render={(props) => <IntegrationSection {...props} />}
          />
          <Route
            path="/renovacion"
            render={(props) => <Renovacion {...props} />}
          />
          <Route path="/recibos" render={(props) => <Receipts {...props} />} />
          <Route path="/faq" render={(props) => <FAQ {...props} />} />
          <Route path="/" render={(props) => <ContactsList {...props} />} />
        </Switch>
      </MainLayout>
    )
  }

  const PublicRoutes = () => (
    <Switch>
      <Route
        path="/deactivate-campaign-account/:id"
        exact
        render={(props) => (
          <DeactivateCampaignAccount {...props} outside={true} />
        )}
      />
      <Route
        path="/contact-us"
        exact
        render={(props) => <GetInTouch {...props} outside={true} />}
      />
      <Route
        path="/reset-password"
        exact
        render={(props) => <ResetPassword {...props} outside={true} />}
      />
      <Route
        path="/maintenance"
        render={(props) => (
          <Login {...props} notification={messageLogin} maintenance={true} />
        )}
      />
      <Route
        path="/unirse"
        exact
        render={(props) => withAcquisitionLayout(Signup)}
      />
      <Route
        path="/unirse/seleccionar-plan"
        exact
        render={(props) => withAcquisitionLayout(SelectPlan)}
      />
      <Route
        path="/unirse/confirmar-datos"
        exact
        render={(props) => <ConfirmData />}
      />
      <Route
        path="/unirse/ubicacion-precio"
        exact
        render={(props) => withAcquisitionLayout(LocationPrice)}
      />
      <Route
        path="/unirse/amenidades"
        exact
        render={(props) => withAcquisitionLayout(Amenities)}
      />
      <Route
        path="/unirse/descripcion"
        exact
        render={(props) => withAcquisitionLayout(Description)}
      />
      <Route
        path="/unirse/resumen"
        exact
        render={(props) => <RegisterResume />}
      />
      <Route
        path="/unirse/propiedades"
        exact
        render={(props) => withAcquisitionLayout(Properties)}
      />
      <Route
        path="/unirse/facturacion"
        exact
        render={(props) => <Facturacion />}
      />

      <Route
        path="/"
        render={(props) => <Login {...props} notification={messageLogin} />}
      />
    </Switch>
  )

  const ActivateRoute = (props) => {
    return (
      <Switch>
        <Route path="/" render={() => <Packages />} />
        <Route path="/paquetes" render={() => <Packages />} />
      </Switch>
    )
  }

  return (
    <SWRConfig>
      <Router>
        {userSession && accountType?.client_permissions?.client_account_type && (
          <>
            {!expired &&
              (accountType?.client_permissions?.client_account_type ===
                'real_estate' ||
                accountType?.client_permissions?.client_account_type ===
                  'developer') && <MainRoutes />}
            {!expired &&
              accountType?.client_permissions?.client_account_type ===
                'advertiser' && <AdvertiserRoutes />}
            {expired && (
              <ActivateRoute userSession={userSession} contract={contract} />
            )}
          </>
        )}

        {!userSession && <PublicRoutes />}
      </Router>
    </SWRConfig>
  )
}

export default App
