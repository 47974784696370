import React from 'react'

const Loading = ({ text = 'cargando' }) => (
  <div className="relative">
    <p className="text-center mt-8 text-2xl text-gray-500 animate-pulse">
      { text }
    </p>
  </div>
)

export default Loading
