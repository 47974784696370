import React from 'react'
import { useHistory } from 'react-router-dom'
import LogoCYT from '../../Shared/Images/logo-cyt-pro-white.png'
import DataConfirmation from './form'
import Loading from '../../Components/Loading'
import getAcquisitionToken from '../../Utils/getAcquisitionToken'
import useAcquisitionUser from '../../Hooks/useAcquisitionUser'

export default function Index() {
  const history = useHistory()

  const token = getAcquisitionToken()
  const { data: user, error } = useAcquisitionUser(token?.uuid)

  if (!token) {
    history.push('/unirse')
    return (<Loading />)
  }

  return (
    <div className="relative">
      <div className="md:hidden bg-blue-800 text-white p-3 space-y-1 pb-10">
        <img
          src={LogoCYT}
          alt="Logo Casas y Terrenos"
          className="max-w-xs m-auto mb-10"
        />
        <p className="text-4xl leading-10 font-extrabold uppercase">
          Te damos la bienvenida,{' '}
          <span className="capitalize">
            {' '}
            {user?.name && `${user.name} ${user.last_name}`}
          </span>
        </p>
      </div>
      <div className="md:grid grid-cols-3  ">
        <div className="min-h-screen hidden md:block col-span-1 bg-blue-800 pt-16  ">
          <div className="block w-3/4 m-auto space-y-3">
            <img
              src={LogoCYT}
              alt="Logo Casas y Terrenos"
              className="max-w-xs  mb-10"
            />
            <p className="text-5xl text-white leading-none font-extrabold">
              Te damos la bienvenida,{' '}
              <span className="capitalize">
                {user?.name && `${user.name} ${user.last_name}`}
              </span>
            </p>
          </div>
        </div>
        <div className="col-span-2 block ">
          <div className="flex md:min-h-screen w-full md:w-3/4 lg:w-2/3 m-auto pt-16 px-5 ">
            <DataConfirmation data={user} />
          </div>
        </div>
      </div>
    </div>
  )
}
