import React, { useState, useEffect } from 'react'
import Geocode from 'react-geocode'

const { maps: GOOGLE_MAPS_KEY } = process.env.ENV.google

Geocode.setApiKey(GOOGLE_MAPS_KEY)
Geocode.setLanguage('es')
Geocode.setRegion('mx')

export default function useGeocode(props) {
  const [error, setError] = useState(false)
  const [coordinates, setCoordinates] = useState(null)
  const { street, state, city, neighborhood } = props

  useEffect(() => {
    if (street && state && city && neighborhood) {
      Geocode.fromAddress(`${street}, ${neighborhood}, ${city}, ${state}`).then(
        (response) => {
          setCoordinates(response.results[0].geometry.location)
        },
        (error) => {
          setError(error)
        }
      )
    }
  }, [street, state, city, neighborhood])

  return { error, coordinates }
}
