import React, { Fragment } from 'react'
import { Row, Col, Form, Divider, Icon } from 'antd'

const { Item: FormItem } = Form

const GeneralDetailsProperty = (props) => {
  const {
    number_views,
    number_contacts,
    ctr,
    description,
    property_type,
    operation_type,
    images,
  } = props.data || ''
  let urlMainImage

  if (images.length > 0) urlMainImage = images[0].url

  return (
    <Form className="login-form" colon={false} hideRequiredMark>
      <Row type="flex" justify="center" className="mb-3">
        {urlMainImage ? (
          <Col xs={24}>
            <div
              style={{
                height: '150px',
                width: '100%',
                backgroundImage: `url(${urlMainImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            ></div>
          </Col>
        ) : null}
      </Row>

      <div className="mx-4 mb-4">
        <Row type="flex" justify="start" gutter={48}>
          <Col xs={24}>
            <FormItem label="Descripción" className="font-weight-bold mb-0">
              <span className="font-weight-light d-block lh-6 mb-2">
                {description}
              </span>
            </FormItem>
          </Col>

          <Col xs={12}>
            <FormItem label="Tipo" className="font-weight-bold mb-0">
              <span className="font-weight-light">{property_type.name}</span>
            </FormItem>
          </Col>

          <Col xs={12}>
            <FormItem label="Operación" className="font-weight-bold mb-0">
              <span className="font-weight-light">
                {operation_type.map((type) => {
                  return type.name
                })}
              </span>
            </FormItem>
          </Col>
          {/* "Número de visitas" */}
          <Divider orientation="left">Estadísticas</Divider>
          <Col xs={12}>
            <FormItem
              label={
                <Fragment>
                  <Icon
                    type="fund"
                    className="position-relative mr-1"
                    style={{ top: '3px' }}
                  />
                  Numero de visitas
                </Fragment>
              }
              className="font-weight-bold mb-0"
            >
              <span className="font-weight-light">{number_views}</span>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem
              label={
                <Fragment>
                  <Icon
                    type="contacts"
                    className="position-relative mr-1"
                    style={{ top: '3px' }}
                  />
                  Numero de contactos
                </Fragment>
              }
              className="font-weight-bold mb-0"
            >
              <span className="font-weight-light">{number_contacts}</span>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem
              label={
                <Fragment>
                  <Icon
                    type="radar-chart"
                    className="position-relative mr-1"
                    style={{ top: '3px' }}
                  />
                  CTR
                </Fragment>
              }
              className="font-weight-bold mb-0"
            >
              <span className="font-weight-light">{ctr}</span>
            </FormItem>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export default GeneralDetailsProperty
