import React, { Fragment, useEffect } from 'react'
import { Row, Col, Form, Input, InputNumber } from 'antd'

// Own component
import SelectStates from '../../Shared/Selects/SelectStates'
import SelectMunicipality from '../../Shared/Selects/SelectMunicipality'
import SelectNeighborhood from '../../Shared/Selects/SelectNeighborhood'
import MapLocation from './MapLocation'
import { useState } from 'react'

const { Item } = Form

const LocationCreateForm = (props) => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form
  const { hidden, initialValues } = props
  const [state, setState] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [colony, setColony] = useState('')
  const [street, setStreet] = useState('')
  const [exteriorNumber, setExteriorNumber] = useState('')

  useEffect(() => {
    if (initialValues) {
      let {
        street,
        state,
        colony,
        municipality,
        latitude,
        longitude,
        zip_code,
        exterior_number,
      } = initialValues

      if (state) {
        setFieldsValue({ state: { key: state.id, label: state.name } })
        setState({ key: state.id, label: state.name })
      }
      if (municipality) {
        setFieldsValue({
          municipality: { key: municipality.id, label: municipality.name },
        })
        setMunicipality({ key: municipality.id, label: municipality.name })
      }
      if (colony) {
        setFieldsValue({ colony: { key: colony.id, label: colony.name } })
        setColony({ key: colony.id, label: colony.name })
      }
      setStreet(street)
      setExteriorNumber(exterior_number)
      setFieldsValue({
        street,
        latLng: [latitude, longitude],
        exterior_number,
        zip_code,
      })
    }
  }, [initialValues])

  const getFieldState = getFieldValue('state') || ''
  const getFieldMunicipality = getFieldValue('municipality') || ''
  const getFieldColony = getFieldValue('colony') || ''
  const getFieldStreet = getFieldValue('street') || ''
  const getExteriorNumber = getFieldValue('exterior_number') || ''

  useEffect(() => {
    setState(getFieldState)
    setMunicipality(getFieldMunicipality)
    setColony(getFieldColony)
    setStreet(getFieldStreet)
    setExteriorNumber(getExteriorNumber)
  }, [
    getFieldState,
    getFieldMunicipality,
    getFieldColony,
    getFieldStreet,
    getExteriorNumber,
  ])

  const filterFn = (input, option) => {
    const term = String(input).toLowerCase()
    const optLabel = String(option.props.children).toLowerCase()
    return String(optLabel).includes(term)
  }

  const handleChangeState = (value) => {
    const resetSelects = { key: '', label: '' }
    setState(value)
    setMunicipality(resetSelects)
    setColony(resetSelects)
    setStreet('')
    setExteriorNumber('')
  }

  const handleChangeCounty = (value) => {
    const resetSelects = { key: '', label: '' }
    setMunicipality(value)
    setColony(resetSelects)
  }

  const handleChangeNeighborhood = (value) => {
    setColony(value)
  }

  return (
    <Fragment>
      <div className={hidden && 'hidden'}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          gutter={[48, 8]}
        >
          <Col xs={24} md={12}>
            <Row type="flex" justify="space-between" gutter={[48, 0]}>
              <Col xs={24} md={12}>
                <Item label="Estado" className="my-0" colon={false}>
                  {getFieldDecorator('state', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecciona un estado',
                      },
                    ],
                  })(
                    <SelectStates
                      noDefault
                      labelInValue
                      showSearch
                      state={state.key}
                      placeholder="Selecciona un estado"
                      filterOption={filterFn}
                      onChange={handleChangeState}
                    />
                  )}
                </Item>
              </Col>
              <Col xs={24} md={12}>
                <Item label="Municipio" className="my-0" colon={false}>
                  {getFieldDecorator('municipality', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecciona un municipio',
                      },
                    ],
                  })(
                    <SelectMunicipality
                      labelInValue
                      showSearch
                      municipality={municipality.key}
                      placeholder="Selecciona un municipio"
                      filterOption={filterFn}
                      onChange={handleChangeCounty}
                    />
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" gutter={[48, 0]}>
              <Col xs={24} md={12}>
                <Item label="Colonia" className="my-0" colon={false}>
                  {getFieldDecorator('colony', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecciona una colonia',
                      },
                    ],
                  })(
                    <SelectNeighborhood
                      labelInValue
                      showSearch
                      filterOption={filterFn}
                      placeholder="Selecciona una colonia"
                      onChange={handleChangeNeighborhood}
                    />
                  )}
                </Item>
              </Col>
              <Col xs={24} md={12}>
                <Item label="Calle" className="my-0" colon={false}>
                  {getFieldDecorator('street', {
                    rules: [
                      {
                        required: true,
                        message: 'Indica una calle',
                      },
                    ],
                  })(<Input />)}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" gutter={[48, 0]}>
              <Col xs={24} md={12}>
                <Item label="Número" className="my-0" colon={false}>
                  {getFieldDecorator('exterior_number', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Indica el número exterior',
                      },
                    ],
                  })(<Input />)}
                </Item>
              </Col>
              <Col xs={24} md={12}>
                <Item label="Código Postal" className="my-0" colon={false}>
                  {getFieldDecorator('zip_code', {})(<InputNumber min={4} />)}
                </Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row type="flex" justify="space-between">
              <Col xs={24}>
                <Item>
                  {getFieldDecorator('latLng', {
                    initialValue: ['', ''],
                    rules: [
                      {
                        required: true,
                        message:
                          'Indica el punto donde se encuentra la propiedad',
                      },
                    ],
                  })(
                    <MapLocation
                      state={state.label}
                      municipality={municipality.label}
                      colony={colony.label}
                      street={street}
                      exteriorNumber={exteriorNumber}
                    />
                  )}
                </Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default LocationCreateForm
