import React, { forwardRef } from 'react'
import { Select } from 'antd'

const { Option } = Select

const SelectOperationType = forwardRef(({ ...props }, ref) => {
  return (
    <Select {...props} ref={ref}>
      <Option value={1}>Venta</Option>
      <Option value={2}>Renta</Option>
      <Option value={3}>Traspaso</Option>
    </Select>
  )
})

export default SelectOperationType
