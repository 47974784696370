import React, { useEffect, useState } from 'react'
import { Tabs, Icon } from 'antd'

// Own components
import GeneralDetailsProperty from './Details/General'
import MultimediaDetailsProperty from './Details/Multimedia'
import FeaturesDetailsForm from './Details/Features'
import LocationDetailsForm from '../Shared/Common/LocationDetailsForm'
import ContainerLayout from '../Layouts/ContainerLayout'
import PageHeaderStyled from '../Shared/Common/PageHeader'

// Services
import { PropertyAPI } from '../Services/property'

const { TabPane } = Tabs

const PropertiesFullViewDetails = (props) => {
  const { params } = props.match
  const [dataSource, setDataSource] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const { propertyID } = params
      let data = await PropertyAPI.getProperty(propertyID)
      data.id = propertyID
      setDataSource(data)
    }

    fetchData()
  }, [params])

  return (
    <ContainerLayout
      backgroundColor="#ffffff"
      paddingX={2}
      paddingY={2}
      marginX={2}
      marginY={2}
    >
      <PageHeaderStyled title="Propiedades" className="px-0 pt-0" />
      {dataSource ? (
        <Tabs
          tabBarStyle={{
            textAlign: 'center',
            marginTop: '4px',
            marginBottom: 0,
          }}
          defaultActiveKey="1"
        >
          <TabPane
            tab={
              <span data-splitbee-event="properties-details-property-general">
                <Icon type="profile" />
                General
              </span>
            }
            key="1"
          >
            <GeneralDetailsProperty data={dataSource} />
          </TabPane>
          <TabPane
            tab={
              <span data-splitbee-event="properties-details-property-location">
                <Icon type="environment" />
                Ubicación
              </span>
            }
            key="2"
          >
            <LocationDetailsForm data={dataSource} />
          </TabPane>
          <TabPane
            tab={
              <span data-splitbee-event="properties-details-property-features">
                <Icon type="reconciliation" />
                Características
              </span>
            }
            key="3"
          >
            <FeaturesDetailsForm data={dataSource} />
          </TabPane>
          {dataSource.images.length > 0 ? (
            <TabPane
              tab={
                <span data-splitbee-event="properties-details-property-multimedia">
                  <Icon type="picture" />
                  Multimedia
                </span>
              }
              key="4"
            >
              <MultimediaDetailsProperty data={dataSource} />
            </TabPane>
          ) : null}
        </Tabs>
      ) : (
        'Loading...'
      )}
    </ContainerLayout>
  )
}

export default PropertiesFullViewDetails
